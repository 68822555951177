(function () {
    'use strict';
    angular
    .module('app')
    .component('applicationNotesAgent', {
      templateUrl: 'app/components/modals/application_notes_agent.html',
      controller: ApplicationNotesAgentsController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });
  
    ApplicationNotesAgentsController.$inject = ['$rootScope','globalService'];
  
    function ApplicationNotesAgentsController($rootScope, $globalService) {
      var vm = this;
  
      vm.$onInit = function() {
        vm.currentApplication = vm.resolve.currentApplication;
      }
  
      vm.cancel = function(){
        vm.dismiss({$value: ''})
      }

      vm.resolveNote = function(note) {
        swal({
          title: 'Do you want to resolve note?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          showLoaderOnConfirm: true,
          preConfirm: function() {
            return new Promise(function(resolve, reject) {
              $globalService.resolveNoteAgents({application_note_agent_id: note.id})
              .then(function(res){
                Swal('Success',res.message,'success');
                note.resolve = res.note.resolve;
                note.resolved_date = moment(res.note.updated_at).format('MMM/DD/YYYY HH:mm')
                resolve();
              }, function(err) {
                Swal('Stop',err.data.message,'warning');
                reject(err.data.message);
              });
            });
          },
          allowOutsideClick: false,
        }).then(function(data) {});
      }
  
      $rootScope.$on('setCurrentApplicant', function(event, data){
        vm.currentApplication = data.current;
      })
  
    }
  
  })();
  