(function(){
  'use strict';
  angular
  .module('app')
  .component('profitsAgents', {
    templateUrl: 'app/components/profits/profits-report-agent.html',
    controller: ProfitsAgentsController
  });

  ProfitsAgentsController.$inject = ['user', '$rootScope', '$state', '_', 'globalService','$uibModal','url'];

  function ProfitsAgentsController($user, $rootScope, $state, _,$globalService, $uibModal, $url) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.showCurrentApplication = false;
    vm.loadingData = false;
    var date = new Date();
    vm.date_from = new Date(date.getFullYear(), date.getMonth(), 1);
    vm.date_to = new Date();
    vm.params = {
      close: false,
      date_from: moment().startOf('month').format('MM/DD/YYYY'),
      date_to: moment().endOf('month').format('MM/DD/YYYY'),
    }
    vm.paidsOptions = [{value: 'true', name: 'Paid'}, {value: 'false', name: 'Unpaid'}, {value: 'true,false', name: 'Todas'}]

    vm.$onInit = function(){
      vm.loadingData = true;
      vm.params.date_from = moment(vm.params.date_from).format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.params.date_to).format('YYYY-MM-DD');
      vm.params.paid = 'false';
      vm.paidOptionSelected = vm.paidsOptions[1];
      $rootScope.$emit('uploadBreadCrum', {current: 'Cuadre General', before: 'Home'});
      $globalService.getAllAgents.query(function(res){
        vm.agents = res;
      })
      $globalService.reportProfitsAgents(vm.params)
      .then(function(res){
        vm.reports = res;
        vm.calculate_total(res)
        vm.loadingData = false;
        var params = vm.params;
        params.token = vm.currentUser.token;
        vm.urlReport = $globalService.reportPdfWithAction('profits','profits_agents', params);
        params.report_global = true
        vm.urlReportBiweekly = $globalService.reportPdfWithAction('profits','report_biweekly',params);
        vm.urlReportBiweeklyExcel = $globalService.getUrlExcel('profits/report_biweekly', params);
      });
      
    }


    vm.consultApplications = function(){
      vm.loadingData = true;
      vm.params.report_complete = false;
      vm.params.date_from = moment(vm.date_from).format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.date_to).format('YYYY-MM-DD');
      $globalService.reportProfitsAgents(vm.params)
      .then(function(res){
        vm.loadingData = false;
        vm.reports = res;
        vm.calculate_total(res);
        var params = vm.params;
        params.token = vm.currentUser.token;
        vm.urlReport = $globalService.reportPdfWithAction('profits','profits_agents', params);
        params.report_global = true
        vm.urlReportBiweekly = $globalService.reportPdfWithAction('profits','report_biweekly',params);
        vm.urlReportBiweeklyExcel = $globalService.getUrlExcel('profits/report_biweekly', params);
      });
    }

    vm.calculate_total = function(res){
      vm.total = _.reduce(res, function(memo, data){
        
        memo.total_insureds += data.insureds;
        memo.total_rate_amount += data.amount;
        memo.total_rate_amount_company += data.amount_rate;
        memo.total += data.total;
        memo.overide += data.overide;
        return memo;
      },{total_insureds: 0, total_rate_amount: 0, total_rate_amount_company: 0, total: 0, overide: 0});
      //console.log(vm.total)
    }

    vm.showDetail = function(report){
      var paid = vm.params.paid ? 'true' : 'false'
      $state.go('profitsAgentDetail',{
        agent_id: report.user_id,
        date_from: moment(vm.date_from).format('YYYY-MM-DD'),
        date_to: moment(vm.date_to).format('YYYY-MM-DD'),
        close: vm.params.close,
        paid: paid
      })
    }

    vm.paidPayroll = function(){
      vm.loadingData = true;
      swal({
        title: 'You are sure to paid this payroll?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Accept',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.paidProfitsAgents(vm.params)
            .then(function (res) {
              vm.send = false;
              vm.loadingData = false;
              vm.$onInit();
              Swal('Exito', res.message, 'success');
            }, function (err) {
              vm.loadingData = false;
              Swal('Error', err.data.message, 'warning');
            });

          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    }

    vm.selectPaidOption = function(){
      vm.params.paid = vm.paidOptionSelected.value;
      $stateParams.paid = vm.paidOptionSelected.value;
      $location.search('paid', vm.paidOptionSelected.value.toString());
    }

    // column to sort
    vm.column = 'sno';

    // sort ordering (Ascending or Descending). Set true for desending
    vm.reverse = false;

    // called on header click
    vm.sortColumn = function (col) {
      vm.column = col;
      if (vm.reverse) {
        vm.reverse = false;
        vm.reverseclass = 'arrow-up';
      } else {
        vm.reverse = true;
        vm.reverseclass = 'arrow-down';
      }
    };

    // remove and change class
    vm.sortClass = function (col) {
      if (vm.column == col) {
        if (vm.reverse) {
          return 'fa-sort-amount-asc';
        } else {
          return 'fa-sort-amount-desc';
        }
      } else {
        return 'fa-sort';
      }
    }

    vm.download = function(data){
      var new_data = _.reduce(data, function(memo, data) {
        memo.push({
          "Agent": data.name,
          "insureds": data.insureds,
          "Rate Amount": data.amount,
          "Rate Amount Company": data.amount_rate,
          "Overide": data.overide,
          "Utility": data.total
        })
        return memo
      },[]);
      new_data.push({
        "Agent": "Total",
        "insureds": vm.total.total_insureds,
        "Rate Amount": vm.total.total_rate_amount,
        "Rate Amount Company": vm.total.total_rate_amount_company,
        "Overide": vm.total.overide,
        "Utility": vm.total.total
      });
      var close = vm.params.close ? 'Facturadas' : 'no_facturadas'
      var name = moment(vm.date_from).format('MM-DD')+'_'+moment(vm.date_to).format('MM-DD')+'_'+close;
      var a = document.createElement("a");
      var json_pre = angular.toJson(new_data);
      var fileName = name+".csv";
      
      var csv = Papa.unparse(json_pre);
 
      if (window.navigator.msSaveOrOpenBlob) {
        var blob = new Blob([decodeURIComponent(encodeURI(csv))], {
          type: "text/csv;charset=utf-8;"
        });
        navigator.msSaveBlob(blob, fileName);
      } else {
 
        a.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(csv);
        a.target = '_blank';
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      }
    }
  }


})();
