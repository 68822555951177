(function(){
  'use strict';
  angular
  .module('app')
  .component('profits', {
    templateUrl: 'app/components/profits/profits-report.html',
    controller: ProfitsController
  });

  ProfitsController.$inject = ['user', '$rootScope', '$state', '_', 'globalService','$uibModal','url'];

  function ProfitsController($user, $rootScope, $state, _,$globalService, $uibModal, $url) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.showCurrentApplication = false;
    vm.loadingData = true;
    var date = new Date();
    vm.date_from = new Date(date.getFullYear(), date.getMonth(), 1);
    vm.date_to = new Date();
    vm.paidsOptions = [{value: 'true', name: 'Paid'}, {value: 'false', name: 'Unpaid'}, {value: 'true,false', name: 'Todas'}]
    vm.params = {
      close: false,
      date_from: moment().startOf('month').format('MM/DD/YYYY'),
      date_to: moment().endOf('month').format('MM/DD/YYYY'),
    }

    vm.$onInit = function(){
      vm.loadingData = true;
      vm.params.date_from = moment(vm.params.date_from).format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.params.date_to).format('YYYY-MM-DD');
      vm.params.paid = false
      $rootScope.$emit('uploadBreadCrum', {current: 'Cuadre General', before: 'Home'});
      $globalService.reportProfits(vm.params)
      .then(function(res){
        vm.reports = res;
        vm.calculate_total(res)
        vm.loadingData = false;
      });
      $globalService.companies.query(function(res){
        vm.companies = res;
      })
    }


    vm.consultApplications = function(){
      vm.loadingData = true;
      vm.params.report_complete = false;
      vm.params.date_from = moment(vm.date_from).format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.date_to).format('YYYY-MM-DD');
      $globalService.reportProfits(vm.params)
      .then(function(res){
        vm.loadingData = false;
        vm.reports = res;
        vm.calculate_total(res);
        vm.urlReport = $globalService.reportPdf('report_agents_applications',vm.params);
      });
    }

    vm.calculate_total = function(res){
      vm.total = _.reduce(res, function(memo, data){
        memo.total_insureds += data.insureds;
        memo.total_rate_amount += data.amount;
        memo.total_rate_amount_company += data.amount_rate;
        memo.total += data.total;
        memo.overide += data.overide;
        return memo;
      },{total_insureds: 0, total_rate_amount: 0, total_rate_amount_company: 0, total: 0, overide: 0});
    }

    vm.showDetail = function(report){
      $state.go('profitsCompanyDetail',{
        company_id: report.company_id,
        date_from: moment(vm.date_from).format('YYYY-MM-DD'),
        date_to: moment(vm.date_to).format('YYYY-MM-DD'),
        close: vm.params.close,
        paid: vm.params.paid
      })
    }

    vm.selectPaidOption = function(){
      vm.params.paid = vm.paidOptionSelected.value;
      $stateParams.paid = vm.paidOptionSelected.value;
      $location.search('paid', vm.paidOptionSelected.value.toString());
    }


    // column to sort
    vm.column = 'sno';

    // sort ordering (Ascending or Descending). Set true for desending
    vm.reverse = false;

    // called on header click
    vm.sortColumn = function (col) {
      vm.column = col;
      if (vm.reverse) {
        vm.reverse = false;
        vm.reverseclass = 'arrow-up';
      } else {
        vm.reverse = true;
        vm.reverseclass = 'arrow-down';
      }
    };

    // remove and change class
    vm.sortClass = function (col) {
      if (vm.column == col) {
        if (vm.reverse) {
          return 'fa-sort-amount-asc';
        } else {
          return 'fa-sort-amount-desc';
        }
      } else {
        return 'fa-sort';
      }
    }

    var clearAlerts = function() {
      vm.error = {}, vm.warning = null
    };
       
    vm.download = function(data){
      var new_data = _.reduce(data, function(memo, data) {
        memo.push({
          "company": data.name,
          "insureds": data.insureds,
          "Rate Amount": data.amount,
          "Rate Amount Company": data.amount_rate,
          "Overide": data.overide,
          "Utility": data.total
        });
        return memo
      },[])
      new_data.push({
        "company": "Total",
        "insureds": vm.total.total_insureds,
        "Rate Amount": vm.total.total_rate_amount,
        "Rate Amount Company": vm.total.total_rate_amount_company,
        "Overide": vm.total.overide,
        "Utility": vm.total.total
      });
      var close = vm.params.close ? 'Facturadas' : 'no_facturadas'
      var name = moment(vm.date_from).format('MM-DD')+'_'+moment(vm.date_to).format('MM-DD')+'_'+close;
      var a = document.createElement("a");
      var json_pre = angular.toJson(new_data);
      var fileName = name+".csv";
      
      var csv = Papa.unparse(json_pre);
 
      if (window.navigator.msSaveOrOpenBlob) {
        var blob = new Blob([decodeURIComponent(encodeURI(csv))], {
          type: "text/csv;charset=utf-8;"
        });
        navigator.msSaveBlob(blob, fileName);
      } else {
 
        a.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(csv);
        a.target = '_blank';
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      }
    }
  }


})();
