(function () {
  'use strict';
  angular
    .module('app')
    .component('dependentsApplicantForm', {
      templateUrl: 'app/components/manage-applications/dependents_applicant_form.html',
      controller: DependentsApplicantFormController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

  DependentsApplicantFormController.$inject = ['$rootScope', 'globalService', 'user'];

  function DependentsApplicantFormController($rootScope, $globalService, $user) {
    var vm = this;
    vm.save = save;
    vm.addDependents = addDependents;

    vm.$onInit = function () {
      vm.currentUser = $user.getCurrentUser();
    }


    function save() {
      _.map(vm.currentApplication.dependents, function(data){
        data.birthday = moment(data.birthday).format('MM/DD/YYYY');
      })
      swal({
        title: 'Are you sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Acept',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.updateDependentApplicant(vm.currentApplication)
              .then(function (res) {
                $rootScope.$emit('initAllAplications');
                swal('Exito', res.message, 'success');
              }, function () {
                reject('Ha ocurrido un error');
              });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) {
        vm.$onInit();
      });
    }

    function addDependents() {
      if (vm.currentApplication.dependents == null) {
        vm.currentApplication.dependents = [];
      }
      vm.currentApplication.dependents.push({
        name: "",
        last_name: "",
        birthday: "",
        social_security_number: "",
        status_migratory: "",
        immigration_number: "",
        card_number: "",
        card_expiration_date: "",
        category: "",
      })
    }

    $rootScope.$on('setCurrentApplicant', function (event, data) {
      vm.currentApplication = data.current;
      if(vm.currentApplication.dependents != null){
        _.map(vm.currentApplication.dependents, function(data){
          data.age = moment(data.birthday, 'MM/DD/YYYY').month(0).from(moment().month(0)).split(' ')[0];
        })
      }
    })
    $rootScope.$on('updateDependentApplicants', function (event, data) {
      save();
    })

  }

})();
