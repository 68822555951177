(function(){
  'use strict';
  angular
  .module('app')
  .component('blockScreen', {
    templateUrl: 'app/components/block-screen/block-screen.html',
    controller: AuthController
  });

  AuthController.$inject = ['$window', '$state','$stateParams','user', 'authService','toastr'];

  function AuthController($window, $state, $stateParams, $user, $authService, toastr) {
    var vm = this;
    vm.send = false;
    vm.doUnlock = doUnlock;

    vm.$onInit = function(){
      if($user.getCurrentUser()){
        vm.currentUser = $user.getCurrentUser();
      }else{
        toastr.error('Debe Iniciar session');
        $state.go('login')
      }
    }


    function doUnlock() {
      vm.send = true;
      $authService.unlockScreen({password: vm.password, id: vm.currentUser.id})
      .then(function(res){
        switch (vm.currentUser.pretty_type) {
          case "Manager":
          $state.go('dashboardManager');
            break;
          case "Administrator":
            $state.go('home');
            break;
          case "Agente":
            $state.go('dashboardAgent');
            break
          default:
            $state.go('home');
        }
      }, function(err){
        //console.log(err)
        Swal('Error',err.data.message,'error');
      })
    }
  }
})();
