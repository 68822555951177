(function(){
    'use strict';
    angular
    .module('app')
    .component('reportUnpaidApplications', {
      templateUrl: 'app/components/report-unpaid-applications/report-unpaid-applications.html',
      controller: ReportUnpaidApplicationsController
    });
  
    ReportUnpaidApplicationsController.$inject = ['user', '$rootScope', '$state', '_', 'globalService','$uibModal','url'];
  
    function ReportUnpaidApplicationsController($user, $rootScope, $state, _,$globalService, $uibModal, $url) {
      var vm = this;
      vm.loadingRequests = false;
      vm.currentUser = $user.getCurrentUser();
      vm.showCurrentApplication = false;
      vm.loadingData = false;
      var date = new Date();
      vm.date_from = new Date(date.getFullYear(), date.getMonth(), 1);
      vm.date_to = new Date();
      vm.params = {
        close: false,
        date_from: moment().startOf('month').format('MM/DD/YYYY'),
        date_to: moment().endOf('month').format('MM/DD/YYYY'),
      }
  
      vm.$onInit = function(){
        vm.loadingData = false;
        vm.params.date_from = moment(vm.params.date_from).format('YYYY-MM-DD');
        vm.params.date_to = moment(vm.params.date_to).format('YYYY-MM-DD');
        $rootScope.$emit('uploadBreadCrum', {current: 'Cuadre General', before: 'Home'});
        $globalService.getAllAgents.query(function(res){
          vm.agents = res;
        })        
      }
  
  
      vm.consultApplications = function(){
        vm.loadingData = true;
        vm.params.date_from = moment(vm.date_from).format('YYYY-MM-DD');
        vm.params.date_to = moment(vm.date_to).format('YYYY-MM-DD');

        $globalService.reportUnpaidApplications(vm.params)
        .then(function(res){
          vm.loadingData = false;
          vm.reports = res;
          var params = vm.params;
          params.token = vm.currentUser.token;
          vm.urlReport = $globalService.reportPdfWithAction('reports','report_diff_payments', params);
          vm.urlReportExcel = $globalService.getUrlExcel('reports/report_diff_payments', params);
        });
      }


      vm.download = function(data){
        console.log(data)
        var new_data = _.reduce(data, function(memo, data, index) {
          memo.push({
            "N":index + 1,
            "ID":data.id,
            "Agent": data.agent,
            "Status": data.pretty_status,
            "Type": data.type,
            "Customer": data.customer,
            "Company": data.company,
            "SSN": data.social,
            "Phone": data.phone,
            "DOB": data.dob,
            "Zip Code": data.zip_code,
            "Email": data.email,
            "Address": data.address,
            "Clients": data.clients_and_insureds.clients,
            "Insureds": data.clients_and_insureds.insureds,
            "Created": data.date

          })
          return memo
        },[]);
        var close = vm.params.close ? 'Facturadas' : 'no_facturadas'
        var name = vm.agentSelected.full_name.split(' ').join('_')+ '_' + moment(vm.date_from).format('MM-DD')+'_'+moment(vm.date_to).format('MM-DD')+'_'+close;
        var a = document.createElement("a");
        var json_pre = angular.toJson(new_data);
        var fileName = name+".csv";
        
        var csv = Papa.unparse(json_pre);
   
        if (window.navigator.msSaveOrOpenBlob) {
          var blob = new Blob([decodeURIComponent(encodeURI(csv))], {
            type: "text/csv;charset=utf-8;"
          });
          navigator.msSaveBlob(blob, fileName);
        } else {
   
          a.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(csv);
          a.target = '_blank';
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
        }
      }

      // vm.download = function(data){
      //   var new_data = _.reduce(data, function(memo, data, index) {
      //     memo.push({
      //       "#": index,
      //       "ID": data.id,
      //       "Agent": data.agent,
      //       "Status": data.pretty_status,
      //       "Type": data.type,
      //       "Customer": data.customer,
      //       "Company": data.company,
      //       "N° Clientes": data.clients_and_insureds.clients,
      //       "N° Insureds": data.clients_and_insureds.insureds,
      //       "Created": data.date
      //     })
      //     return memo
      //   },[]);
      //   var close = vm.params.close ? 'Facturadas' : 'no_facturadas'
      //   var name = moment(vm.date_from).format('MM-DD')+'_'+moment(vm.date_to).format('MM-DD')+'_'+close;
      //   var a = document.createElement("a");
      //   var json_pre = angular.toJson(new_data);
      //   var fileName = name+".csv";
        
      //   var csv = Papa.unparse(json_pre);
   
      //   if (window.navigator.msSaveOrOpenBlob) {
      //     console.log('blob')
      //     var blob = new Blob([decodeURIComponent(encodeURI(csv))], {
      //       type: "text/csv;charset=utf-8;"
      //     });
      //     navigator.msSaveBlob(blob, fileName);
      //   } else {
      //     console.log(encodeURI(csv))
      //     a.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(csv);
      //     a.target = '_blank';
      //     a.download = fileName;
      //     document.body.appendChild(a);
      //     a.click();
      //   }
      // }
  
      // column to sort
      vm.column = 'sno';
  
      // sort ordering (Ascending or Descending). Set true for desending
      vm.reverse = false;
  
      // called on header click
      vm.sortColumn = function (col) {
        vm.column = col;
        if (vm.reverse) {
          vm.reverse = false;
          vm.reverseclass = 'arrow-up';
        } else {
          vm.reverse = true;
          vm.reverseclass = 'arrow-down';
        }
      };
  
      // remove and change class
      vm.sortClass = function (col) {
        if (vm.column == col) {
          if (vm.reverse) {
            return 'fa-sort-amount-asc';
          } else {
            return 'fa-sort-amount-desc';
          }
        } else {
          return 'fa-sort';
        }
      }
  
    }
  
  
  })();
  