(function () {
  'use strict';
  angular
    .module('app')
    .component('customerSms', {
      templateUrl: 'app/components/customerSms/customerSms.html',
      controller: CustomerSmsController,
    });

  CustomerSmsController.$inject = ['$stateParams', 'user', '_', 'globalService', '$rootScope','$uibModal'];

  function CustomerSmsController($stateParams, $user, _, $globalService, $rootScope, $uibModal) {
    var vm = this;
    vm.currentUser = $user.getCurrentUser();
    vm.requireDocuments = [];
    vm.loadingData = true;
    vm.currentConversation = undefined;

    vm.$onInit = function () {
      $rootScope.$emit('uploadBreadCrum', { current: 'Customer Sms', before: 'Home' });
      vm.consultCustomerSms($stateParams.crmid)
      $globalService.resApplications.get({id: $stateParams.crmid}).$promise
      .then(function(res){
        vm.currentApplication = res;
        vm.loadingData = false;
      })
    }

    vm.consultCustomerSms = function(customer_application_id){
      $globalService.smsNotifications.query({
        crmid: customer_application_id
      }).$promise
      .then(function (res) {
        vm.conversations = res.data;
        if(vm.conversations.length > 0){
          vm.currentConversation = vm.conversations[0];
          vm.showSms(vm.conversations[0].id);
        }
        vm.loadingData = false;
      })
    }

    vm.showSms = function(conversationId){
      vm.loadingData = true;
      $globalService.smsNotifications.get({id: conversationId}).$promise
      .then(function(res){
        vm.messages = res.data.messages;
        vm.loadingData = false;
        document.getElementById(vm.currentConversation.crmid).classList.remove('new-sms');
        setTimeout(function(){
          var conversationBody = document.getElementsByClassName('conversation__body')[0]; 
          conversationBody.scrollTop = conversationBody.scrollHeight;
        },1)
      })
    }

    vm.sendMessage = function(){
      vm.sendingSms = true;
      if(!vm.currentUser.permits_granted.send_sms.active){
        swal('Error','You do not have permission to send SMS','info');
        return
      }

      if(vm.currentConversation == undefined){
        var params = {
          phone_number: vm.currentApplication.main_applicant.phone,
          message: vm.message,
          user_id: vm.currentUser.id,
          conversation_id: null ,
          customer_application_id: vm.currentApplication.id
        }
      }else {
        var params = {
          phone_number: vm.currentConversation.phone_number,
          message: vm.message,
          customer_application_id: vm.currentConversation.crmid,
          conversation_id: vm.currentConversation.id,
          user_id: vm.currentConversation.user_id
        }
      }

      $globalService.sendSms(params)
      .then(function(res){
        vm.$onInit();
      }, function(err){
        swal('Error','error sending sms','error');
        vm.$onInit();
      })
      
    }

    // function getAllCompanies() {
    //   vm.allCompanies = _.reduce(vm.typeInsuranceCompanies, function (memo, data) {
    //     _.forEach(data.companies, function (company) {
    //       memo.push(company)
    //     })
    //     return memo;
    //   }, [])

    //   return vm.allCompanies;
    // }
    // vm.setActiveTab = function (tab) {
    //   _.forEach(Object.keys(vm.tabPanel), function (key) {
    //     if (vm.tabPanel[key]) {
    //       vm.tabPanel[key] = false;
    //     }
    //   })
    //   vm.tabPanel[tab] = true;
    // }

    // vm.addRequireDocuments = function () {

    //   if (vm.dateTo == undefined || moment(vm.dateTo) < moment() || vm.requireDocuments == undefined || vm.whoRequires == undefined) {
    //     Swal('Stop', 'Invalid date or incomplete data', 'info');
    //     return;
    //   } else {
    //     vm.currentApplication.required_documents = vm.currentApplication.required_documents || [];
    //     vm.currentApplication.required_documents.push({
    //       required_document: vm.requiredDocument == 'Others' ? vm.otherRequiredDocument : vm.requiredDocument,
    //       who_require_it: vm.whoRequires,
    //       date_to: moment(vm.dateTo).format('MM/DD/YYYY'),
    //       status: 0,
    //       pretty_status: 'Pendiente',
    //     })
    //     vm.requiredDocument = undefined;
    //     vm.whoRequires = undefined;
    //     vm.dateTo = undefined;
    //   }
    // }

    // vm.sendDocuments = function () {
    //   vm.loadingData = true;
    //   var data = {
    //     customer_application_id: vm.currentApplication.id,
    //     documents: _.reduce(vm.currentApplication.required_documents, function (memo, data) {
    //       if (data.id == undefined) {
    //         memo.push(data);
    //       }
    //       return memo;
    //     }, [])
    //   }

    //   swal({
    //     title: '¿Esta seguro?',
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Aceptar',
    //     showLoaderOnConfirm: true,
    //     preConfirm: function () {
    //       return new Promise(function (resolve, reject) {
    //         $globalService.requiredDocuments(data)
    //           .then(function (res) {
    //             swal('Exito', res.message, 'success');
    //             vm.loadingData = false;
    //           }, function (err) {
    //             swal('Error', res.data.message, 'danger');
    //             vm.loadingData = false;
    //           })
    //       });
    //     },
    //     allowOutsideClick: false,
    //   }).then(function (data) { });
    // }

    // vm.updateRequiredDoc = function (doc) {
    //   swal({
    //     title: '¿Esta seguro?',
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Aceptar',
    //     showLoaderOnConfirm: true,
    //     preConfirm: function () {
    //       return new Promise(function (resolve, reject) {
    //         $globalService.updateDocument(doc)
    //           .then(function (res) {
    //             doc.status = 1;
    //             doc.pretty_status = 'Enviado';
    //             swal('Exito', res.message, 'success');
    //           }, function () {
    //             reject('Ha ocurrido un error');
    //           });
    //       });
    //     },
    //     allowOutsideClick: false,
    //   }).then(function (data) { });
    // }

    // vm.deleteRequiredDoc = function(doc, index){
    //   swal({
    //     title: '¿Esta seguro?',
    //     type: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: 'Aceptar',
    //     showLoaderOnConfirm: true,
    //     preConfirm: function() {
    //       return new Promise(function(resolve, reject) {
    //         $globalService.deleteDocument(doc)
    //         .then(function(res) {
    //           vm.currentApplication.required_documents.splice(index, 1)
    //           swal('Exito',res.message,'success');
    //         }, function() {
    //           reject('Ha ocurrido un error');
    //         });
    //       });
    //     },
    //     allowOutsideClick: false,
    //   }).then(function(data) {});
    // }

    // vm.processApplication = function(){
    //   if(vm.currentApplication.status == 2){
    //     Swal('Stop','Aplicacion ya procesada','info');
    //     return;
    //   }
    //   var modal = $uibModal.open({
    //     animation: true,
    //     component: 'processApplication',
    //     windowClass: 'show',
    //     backdrop: 'static',
    //     size: 'md',
    //     resolve: {
    //       currentApplication: function(){
    //         return vm.currentApplication;
    //       }
    //     }
    //   });

    //   modal.result.then(function(res){
    //     vm.$onInit();
    //     vm.showCurrentApplication = false;
    //     vm.currentApplication = undefined;
    //   }, function(err){
    //     swal('Error',err.data.message,'error');
    //   });
    // }
  }
})();
