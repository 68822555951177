(function () {
  'use strict';
  angular
    .module('app')
    .component('membersMigration', {
      templateUrl: 'app/components/modals/members-migration.html',
      controller: MembersMigration,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

  MembersMigration.$inject = ['$rootScope', 'globalService'];

  function MembersMigration($rootScope, $globalService) {
    var vm = this;
    vm.save = save;

    vm.$onInit = function () {
      vm.currentApplication = vm.resolve.currentApplication;
      
    }


    function save() {
      vm.send = true;
      if(!vm.main && !vm.secondary && !vm.history){
        swal('Error', 'Please select at least one option', 'warning');
        return
      }
      var data = {
        to_id: vm.currentApplication.id,
        from_id: vm.applicationFrom.id,
        secondary: vm.secondary,
        history: vm.history,
        main: vm.main
      }
      swal({
        title: 'Are you sure',
        text: 'to migrate members ids, from:'+ data.from_id + ' to: ' + data.to_id,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.migrateMembersId(data)
              .then(function (res) {
                vm.send = false;
                Swal('Exito', res.message, 'success');
              }, function (err) {
                Swal('Error', err.data.message, 'warning');
                vm.cancel({ $value: '' });
              })
          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    }

    vm.searchCustomerApp = function (app) {
      if (!_.isNull(vm.app.id)) {
        vm.app.loading = 'loading';
        $globalService.resApplications.get({ id: vm.app.id }).$promise
          .then(function (res) {
            vm.app.loading = 'loaded';
            vm.applicationFrom = res;
            if(vm.applicationFrom.memberid_histories){
              console.log(vm.applicationFrom.memberid_histories.split(','))
              vm.applicationFrom.memberid_histories = vm.applicationFrom.memberid_histories.split(',')
            }
            if(vm.applicationFrom.secondary){
              console.log(vm.applicationFrom.secondary)
              vm.applicationFrom.secondary = vm.applicationFrom.secondary.split(',')
            }
          }, function (err) {
            vm.app.loading = 'error';
          })
      }
    }

    vm.cancel = function () {
      vm.dismiss({ $value: '' })
    }
  }

})();
