(function () {
  'use strict';
  angular
    .module('app')
    .component('profile', {
      templateUrl: 'app/components/profile/profile.html',
      controller: ProfileController
    });

  ProfileController.$inject = ['user', 'toastr', 'url', 'Upload', '_', 'globalService', '$rootScope', 'DOMAIN_URL'];

  function ProfileController($user, toastr, $url, Upload, _, $globalService, $rootScope, DOMAIN_URL) {
    var vm = this;

    vm.$onInit = function () {
      $rootScope.$emit('uploadBreadCrum', { current: 'Profile', before: 'Home' });
      vm.currentUser = $user.getCurrentUser();
      vm.currentUser.avatar = undefined;
      console.log(vm.currentUser)
      vm.currentUser.user_credential_attributes = vm.currentUser.user_credential;
    }

    vm.save = function () {
      var url_users = 'users/'+ vm.currentUser.id;
      if (Upload.isFile(vm.currentUser.avatar)) {
        vm.currentUser.avatar.upload = Upload.upload({
          method: 'PUT',
          url: $url.getApiUrl(url_users),
          data: vm.currentUser,
        })
        vm.currentUser.avatar.upload.then(function (res) {
          vm.currentUser.url_avatar = res.data.data.url_avatar;
          $user.setCurrentUser(angular.fromJson(angular.toJson(vm.currentUser)));
          $rootScope.$broadcast('updateProfile');
          toastr.info(res.data.message);
          vm.send = false;
          vm.edit = false;
        }, function (err) {
          vm.send = false;
          toastr.error(err.data.message);
        }, function (evt) {
          vm.currentUser.avatar.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
        });

      } else {
        $globalService.users.update({id: vm.currentUser.id}, vm.currentUser, function(res){
          vm.send = false;
          $user.setCurrentUser(res.data);
          $rootScope.$broadcast('updateProfile');
          toastr.success(res.message);
          vm.edit = false;
        }, function(err){
          vm.send = false;
          toastr.error(err.data.message)
        })
        
          
      }
    }

  }


})();
