(function(){
  'use strict';

  angular
  .module('app')
  .filter('humanizeStatus', humanizeStatus)

  function humanizeStatus(){
    return function(string){
      var newString = string.split('_').join(' ')
      return newString.charAt(0).toUpperCase() + newString.slice(1);
    }
  }

})();