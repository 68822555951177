(function () {
  'use strict';
  angular
  .module('app')
  .component('companiesPlanModal', {
    templateUrl: 'app/components/companies/CompanyPlanModal.html',
    controller: CompanyPlanModalController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  CompanyPlanModalController.$inject = ['$sce','globalService'];

  function CompanyPlanModalController($sce, $globalService) {
    var vm = this;
    vm.save = save;

    vm.$onInit = function() {
      vm.productTypeSelected = vm.resolve.productTypeSelected;
      vm.plan = vm.resolve.plan;
      //console.log(vm.plan)
      if(vm.plan == undefined){
        vm.plan = {company_product_type_id: vm.productTypeSelected.id}
      }
    }


    function save(){
      vm.send = true;
      if(vm.plan.id){
        $globalService.updatePlan(vm.plan)
        .then(function(res){
          vm.send = false;
          Swal('Exito',res.message,'success');
          vm.close({$value: res.data});
        }, function(err){
          Swal('Error',err.data.message,'warning');
          vm.cancel({$value: ''});
        })
      }else{
        $globalService.addPlan(vm.plan)
        .then(function(res){
          vm.send = false;
          Swal('Exito',res.message,'success');
          //console.log(res)
          vm.close({$value: res.data})
        }, function(err){
          Swal('Error',err.data.message,'warning');
          vm.cancel({$value: ''});
        })
      }
    }

  }

})();
