(function() {
  'use strict';
  angular
    .module('app')
    .factory('url', url);

  url.$inject = ['BASE_URL','DOMAIN_URL','PDFGENERATOR'];

  function url(BASE_URL,DOMAIN_URL, PDFGENERATOR) {
    return {
      getApiUrl: getApiUrl,
      getBaseUrl: getBaseUrl,
      getAuthUrl: getAuthUrl,
      getUnlockUrl: getUnlockUrl,
      geturlReportPdf: geturlReportPdf,
      geturlReportPdfWithoutParams: geturlReportPdfWithoutParams,
      getUrlReportCsv: getUrlReportCsv,
      getUrlReportPdfController: getUrlReportPdfController,
      geturlReportExcel: geturlReportExcel,
      geturlReportExcelWithoutParams: geturlReportExcelWithoutParams,
      urlExcel: urlExcel,
      urlPdfGenerator: urlPdfGenerator
    };

    function getApiUrl(resource) {
      return BASE_URL + '/api/v1/' + resource;
    }

    function getAuthUrl(){
      return BASE_URL + '/api/v1/login';
    }
    function getUnlockUrl(){
      return BASE_URL + '/api/v1/login/unlock_screen';
    }

    function getBaseUrl() {
      return BASE_URL;
    }

    function geturlReportPdf(resource, data){
      return BASE_URL + '/api/v1/reports/'+resource+'.pdf'+data;
    }
    function getUrlReportCsv(resource, data){
      return BASE_URL + '/api/v1/reports/'+resource+'.csv'+data;
    }
    function getUrlReportPdfController(action,resource, data){
      if(resource){
        return BASE_URL + '/api/v1/'+action+'/'+resource+'.pdf'+data;
      }else{
        return BASE_URL + '/api/v1/'+action+'.pdf'+data;
      }
    }
    function geturlReportPdfWithoutParams(resource){
      return BASE_URL + '/api/v1/reports/'+resource+'.pdf';
    }
    function geturlReportExcel(resource, data){
      return BASE_URL + '/api/v1/reports/'+resource+'.xlsx'+data;
    }
    function geturlReportExcelWithoutParams(resource){
      return BASE_URL + '/api/v1/reports/'+resource+'.xlsx';
    }
    function urlExcel(resource){
      if(resource.indexOf('?') > 1){
        var url = resource.split('?')[0];
        var data = resource.split('?')[1];
        return BASE_URL + '/api/v1/'+url+'.xlsx?'+data;
      }else{
        return BASE_URL + '/api/v1/'+resource+'.xlsx';

      }
    }
    function urlPdfGenerator(crmid, token){
      return PDFGENERATOR + '/api/v1/customer_application/'+crmid+'.pdf?token='+token;
    }
  }
})();
