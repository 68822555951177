
(function(){
  'use strict';
  angular
    .module('app')
    .component('dashboardOpen', {
      templateUrl: 'app/components/dashboard_open/dashboard_open.html',
      controller: DashboardOpenController
    });

    DashboardOpenController.$inject = ['user', '$rootScope','globalService','BASE_URL','ActionCableChannel','ActionCableConfig','$timeout']

  function DashboardOpenController($user, $rootScope, $globalService, BASE_URL, ActionCableChannel, ActionCableConfig, $timeout) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();

    vm.$onInit = function(){
      $rootScope.$emit('uploadBreadCrum', {current: '', before: 'Home'});
      calculateCompanyCounts()
      var initOpen = moment(moment().format('YYYY') + '-10-25','yyyy-MM-DD');
      var endOpen = moment(moment().format('YYYY') + '-12-31','yyyy-MM-DD');
      if(moment().isBetween(initOpen, endOpen)){
        vm.openEnrollment = true;
        vm.applicationsPeriod = moment().add(1, 'year').format('YYYY');
      }else{
        vm.openEnrollment = false;
        vm.applicationsPeriod = moment().format('YYYY')+', '+ moment().add(1, 'year').format('YYYY');
      }
      if (BASE_URL.split('/')[2].split(':')[0] == 'localhost') {
        ActionCableConfig.wsUri = "ws://" + BASE_URL.split('/')[2] + "/cable?token=" + $user.getCurrentUser().token;
      } else {
        ActionCableConfig.wsUri = "wss://" + BASE_URL.split('/')[2] + "/cable?token=" + $user.getCurrentUser().token;
      }
  
      if (vm.currentUser.permits_granted.send_sms.active) {
        var channel = new ActionCableChannel("DashboardOpenChannel", { token: vm.currentUser.token });
        var callback = function (message) {
          if(message.type == 0){
            calculateCompanyCounts()
          }else {
            console.log(message)
          }
        };
        channel.subscribe(callback)
        .then(function () {
          vm.sendToMyChannel = function (message) {
            channel.send(message);
          };   
        });
      }
    }

    
    function calculate_total(rows){
      var total = initArray(rows[0].length - 1, 0);
      _.forEach(rows, function(arr){
        _.forEach(arr, function(item, index){
          
          if(index > 0 && Number.isInteger(item)){
            total[index] += parseInt(item);
          }
        })
      })

      vm.total = total;
      vm.total_global = _.reduce(total, function(memo, data, index) {
        if(index > 0){
          memo += data;
        }
        return memo;
      },0);
    }

    function calculateCompanyCounts(){
      $globalService.getCompanyCounds({date: moment().format('YYYY-MM-DD')})
      .then(function(res){
        vm.counters = res;
        console.log(res);
        calculate_total(res.rows)
        vm.params = {};
        vm.urlReportExcel = $globalService.getUrlExcel('home/company_counts',vm.params);
      })
    }


    function initArray(n, val) {
      var arr = ['Total'];
      for (var i = 0; i < n; i++) {
        arr.push(val);
      }
      return arr;
    }
  }


})();
