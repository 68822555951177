(function () {
  'use strict';
  angular
    .module('app')
    .component('billInformation', {
      templateUrl: 'app/components/manage-applications/bill_information.html',
      controller: BillInformationController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

  BillInformationController.$inject = ['$rootScope', 'globalService', 'user'];

  function BillInformationController($rootScope, $globalService, $user) {
    var vm = this;
    vm.save = save;
    vm.setTypeOfCard = setTypeOfCard;
    vm.card_type_icon = 'fa-credit-card-alt';
    vm.validateDate = validateDate;

    vm.$onInit = function () {
      vm.currentUser = $user.getCurrentUser();
    }


    function save() {
      swal({
        title: 'Are you sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Acept',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.updateBillInformation(vm.curreApplication)
              .then(function (res) {
                $rootScope.$emit('initAllAplications');
                swal('Exito', res.message, 'success');
              }, function () {
                reject('Ha ocurrido un error');
              });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) {
        vm.$onInit();
      });
    }

    function setTypeOfCard(data){
      var card_icon = validatioRejec(data);
      if(card_icon){
        vm.card_type_icon = card_icon;
      }
    }

    function validatioRejec(number){
      var re = {
        'electron': /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
        'maestro': /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
        'dankort': /^(5019)\d+$/,
        'interpayment': /^(636)\d+$/,
        'unionpay': /^(62|88)\d+$/,
        'fa-cc-visa': /^4[0-9]{12}(?:[0-9]{3})?$/,
        'fa-cc-mastercard': /^5[1-5][0-9]{14}$/,
        'fa-cc-amex': /^3[47][0-9]{13}$/,
        'fa-cc-diners-club': /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        'fa-cc-discover': /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        'fa-cc-jcb': /^(?:2131|1800|35\d{3})\d{11}$/
      }

      for(var key in re) {
        if(re[key].test(number)) {
          return key
        }
      }
    }
    function validateDate(){
      if(moment(vm.curreApplication.billing_information.expiration_card) < moment()){
        Swal('Warning','Invalid Date: Expired Card','warning');
      }
    }

    $rootScope.$on('setCurrentApplicant', function (event, data) {
      vm.curreApplication = data.current;
      console.log(vm.curreApplication.hasOwnProperty('billing_information'))
      if(vm.curreApplication.hasOwnProperty('billing_information')){
        if(isDate(vm.curreApplication.billing_information.expiration_card)){
          vm.curreApplication.billing_information.expiration_card = new Date(vm.curreApplication.billing_information.expiration_card)
        }
      }
    })

    function isDate(date){
      return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    }

    $rootScope.$on('updateBillInformation', function (event, data) {
      save();
    })

  }

})();
