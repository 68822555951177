(function () {
    'use strict';
    angular
    .module('app')
    .component('applicationNotes', {
      templateUrl: 'app/components/modals/application_notes.html',
      controller: ApplicationNotesController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });
  
    ApplicationNotesController.$inject = ['$rootScope','globalService'];
  
    function ApplicationNotesController($rootScope, $globalService) {
      var vm = this;
  
      vm.$onInit = function() {
        vm.currentApplication = vm.resolve.currentApplication;
      }
  
      vm.cancel = function(){
        vm.dismiss({$value: ''})
      }
  
      $rootScope.$on('setCurrentApplicant', function(event, data){
        vm.currentApplication = data.current;
      })
  
    }
  
  })();
  