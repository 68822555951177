(function(){
  'use strict';
  angular
    .module('app')
    .component('agency', {
      templateUrl: 'app/components/agency/agency.html',
      controller: AgencyController
    });

    AgencyController.$inject = ['user', '$stateParams', '_','$rootScope','globalService']

  function AgencyController($user, $stateParams, _, $rootScope,$globalService) {
    var vm = this;
    vm.loadingRequests = false;
    vm.disableAdd = false;
    vm.currentUser = $user.getCurrentUser();
    vm.agenciesTypes = [{id: 0, text: 'Main Agency'},{id: 1, text: 'Sub Agency'}]

    vm.$onInit = function(){
      vm.loading = true;
      vm.user = { id: $stateParams.id, name: $stateParams.full_name.split('-').join(' ') }
      $rootScope.$emit('uploadBreadCrum', {current: 'Agency ' + vm.user.name, before: 'Company'});

      $globalService.users.query({lite: true}).$promise
      .then(function(res){
        vm.agents = res;
        vm.loading = false;
        loading_agency_data();
      })
      $globalService.companies.query().$promise
      .then(function(res){
        vm.companies = res;
      })
    }

    function loading_agency_data(){
      $globalService.agencies.query({ user_id: vm.user.id }).$promise
      .then(function(res){
        if(res.hasOwnProperty('message')){
          vm.agency = {
            user_id: vm.user.id,
            collect_overide: true,
            agency_overides_attributes: []
          };
        }else{
          vm.agency = res;
          vm.agency_type_selected = _.where(vm.agenciesTypes, {id: vm.agency.agency_type})[0];
          vm.myAgentsSelected = []
          _.forEach(vm.agency.my_agents, function(id){
            var agent = _.where(vm.agents, {id: id});
            vm.myAgentsSelected.push(agent[0])
          })
          
          if(vm.agency.parent_id != null){
            vm.parentSelected = _.findWhere(vm.agents, {id: vm.agency.parent_id});
            vm.checkParentAmount();
          }
        }
      })
    }

    vm.save = function(){
      console.log(vm.agency, 'save')
      vm.agency.my_agents = _.pluck(vm.myAgentsSelected, 'id');

      if(vm.agency.id){
        $globalService.agencies.update({id: vm.agency.id}, vm.agency).$promise
        .then(function(res){
          swal('Success', res.message, 'success');
        }, function(err){
          swal('Error', err.data.errors, 'error');
        })
      }else{
        $globalService.agencies.save({ user_id: vm.user.id }, vm.agency).$promise
        .then(function(res){
          swal('Success', res.message, 'success');
        }, function(err){
          swal('Error', err.data.errors, 'error');
        })
      }
    }

    vm.addOveride = function(){
      var company_id = vm.companySelected ? vm.companySelected.id : vm.companyAgencySelected.company_id;
      var company_name = vm.companySelected ? vm.companySelected.name : vm.companyAgencySelected.company_name;
      var parent = vm.companySelected ? null : vm.companyAgencySelected.id;
      var attribute = {
        parent_id: parent,
        company_id: company_id,
        company_name: company_name,
        amount: vm.overideAmount,
        parent_amount: vm.overideParent,
        user_id: vm.user.id,
        amount_deal: vm.overideDeal,
        user_name: vm.user.name
      }

      if(vm.agency_type_selected.id == 1){
        if (vm.companySelected) {
          var company = _.where(vm.parentAgency.agency_overides_attributes, { company_id: vm.companySelected.id })[0];
        }else {
          var company = vm.companyAgencySelected;
        }

        if(company){
          if(vm.overideAmount > company.amount_deal){
            swal('Error', 'The overide amount is bigger than the parent agency amount', 'error');
            return;
          }
        }
      }
      vm.agency.agency_overides_attributes.push(attribute)
      vm.companySelected = undefined;
      vm.companyAgencySelected = undefined;
      vm.overideAmount = undefined;
      vm.overideDeal = undefined;
      attribute = undefined;
    }

    vm.checkParentAmount = function(){
      vm.agency.agency_type = vm.agency_type_selected.id;
      if(vm.agency_type_selected.id == 1){
        $globalService.agencies.query({ user_id: vm.parentSelected.id }).$promise
        .then(function(res){
          vm.parentAgency = res;
          if(vm.parentAgency.length > 0 && vm.agency_type_selected.id == 1) {
            console.log(vm.parentAgency, 'vm.parentAgency')
          }
        });
      }
    }

    vm.checkAmountDeal = function(){
      if(vm.agency_type_selected.id == 1){
        vm.overideParent = vm.companyAgencySelected.amount_deal;
      }
    }

  }

})();
