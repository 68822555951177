angular
.module('app')
.component('loginLayout', {
  templateUrl: 'app/components/layouts/login-layout.html',
  controller: loginLayoutController
});

loginLayoutController.$inject = ['user']

function loginLayoutController(user) {
  var vm = this;

  vm.$onInit = function(){
    vm.currentUser = user.getCurrentUser();
  }

}
