(function(){
  'use strict';
  angular
  .module('app')
  .component('reportsCustomerBirthday', {
    templateUrl: 'app/components/report-birthday/report-birthday.html',
    controller: CustomerBirthdayController
  });

  CustomerBirthdayController.$inject = ['user', '$rootScope', '$interval', '_', 'globalService','$uibModal','url'];

  function CustomerBirthdayController($user, $rootScope, $interval, _,$globalService, $uibModal, $url) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.loadingData = false;
    vm.day = undefined;
    vm.month = undefined;

    vm.$onInit = function(){
      $rootScope.$emit('uploadBreadCrum', {current: 'Reports Applications', before: 'Home'});
      $globalService.getAllAgents.query(function(res){
        vm.agents = res;
      })
    }


    vm.consultCustomers = function(){
      vm.loadingData = true;
      if(vm.day == undefined){
        var title_pdf = 'month:'+moment(vm.month).format('MMM');
        var data = {
          month: moment(vm.month).format('YYYY-MM-DD')
        }
      }else{
        var title_pdf = 'date:'+moment(vm.day).format('DDD-MMM');
        var data = {
          day: moment(vm.day).format('YYYY-MM-DD')
        }
      }
      if(vm.sixty){
        data.next_to_meet_sixty_five_years = true
      }
      if(vm.agentSelected){
        data.user_id = vm.agentSelected.id;
      }
      //console.log(data)
      $globalService.reportCustomersBirthdays(data)
      .then(function(res){
        vm.loadingData = false;
        vm.customers = res;
        vm.urlReport = $globalService.reportPdf('report_customer_birthdays',data);
      });
    }
  }


})();
