(function () {
  'use strict';
  angular
    .module('app')
    .component('home', {
      templateUrl: 'app/components/home/home.html',
      controller: HomeController
    });

  HomeController.$inject = ['user', '$rootScope', 'globalService', '$uibModal']

  function HomeController($user, $rootScope, $globalService, $uibModal) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();

    vm.$onInit = function () {
      $rootScope.$emit('uploadBreadCrum', { current: '', before: 'Home' });
      $globalService.getHomeCounters()
        .then(function (res) {
          vm.counters = res;
        })

      $globalService.reports_npns_useds({ limit: true })
        .then(function (res) {
          var npnUseds = res;
          vm.npnCharData = _.pluck(npnUseds, 'count');
          vm.npnCharLabel = _.pluck(npnUseds, 'user');
        });

      $globalService.getCompanyRecords()
        .then(function (res) {
          prepareGrahp(res)
        })
    }

    vm.launchModal = function (counter) {
      if (counter.status == 6) {
        vm.showPendingLinkLog(counter)
      } else {
        vm.showCustomerApps(counter)
      }
    }
    vm.showPendingLinkLog = function (counter) {
      var modalInstance = $uibModal.open({
        animation: true,
        component: 'modalPendingLinkReport',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg'
      })
    }

    vm.showCustomerApps = function (counter) {
      vm.loading = true;
      $globalService.applicationsDashboard({ status: counter.status })
        .then(function (res) {
          vm.loading = false;
          var modalInstance = $uibModal.open({
            animation: true,
            component: 'sendCustomerApps',
            windowClass: 'show',
            backdrop: 'static',
            size: 'lg',
            resolve: {
              data: function () {
                return res;
              },
              status: function () {
                return counter.status;
              }
            }
          });
        })
    }

    function prepareGrahp(res) {
      console.log(res)
      vm.labels = Object.keys(res);
      vm.data = [_.map(Object.values(res), function (row) {
        return row[0];
      })]

      console.log(vm.data)
    }

    vm.onClick = function (points, evt) {
      console.log(points, evt);
    };

    vm.datasetOverride = [{ yAxisID: 'y-axis-1' }, { yAxisID: 'y-axis-2' }];
    vm.options = {
      scales: {
        yAxes: [
          {
            id: 'y-axis-1',
            type: 'linear',
            display: true,
            position: 'left'
          },
          {
            id: 'y-axis-2',
            type: 'linear',
            display: true,
            position: 'right'
          }
        ]
      }
    }

  }


})();
