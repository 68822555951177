(function(){
  'use strict';
  angular
  .module('app')
  .component('periods', {
    templateUrl: 'app/components/periods/periods.html',
    controller: PeriodsController
  });

  PeriodsController.$inject = ['$uibModal','toastr', 'url', 'user', '_','globalService','$rootScope'];

  function PeriodsController($uibModal, toastr, $url, $user, _, $globalService, $rootScope) {
    var vm = this;
    vm.loadingResumen = true;
    vm.showPeriods = showPeriods;
    vm.currentUser = $user.getCurrentUser();
    vm.createPeriod = createPeriod;
    
    vm.$onInit = function(){
      $rootScope.$emit('uploadBreadCrum', {current: 'Periods', before: 'Home'});
      $globalService.getPeriods()
      .then(function(res){
        vm.periods = res;
      })
    }

    function showPeriods(data){
      var modal = $uibModal.open({
        animation: true,
        component: 'periodsModal',
        windowClass: 'show',
        size: 'lg',
        resolve: {
          period: function(){
            return data;
          }
        }
      });
    }

    function createPeriod(data){
      var modal = $uibModal.open({
        animation: true,
        component: 'periodsModalCreate',
        windowClass: 'show',
        size: 'xs',
        resolve: {
          period: function(){
            return data;
          }
        }
      });
      modal.result.then(function (res) {
        vm.$onInit();
      });
    }

    $rootScope.$on('loadDatabases',function(evt, data){
      vm.$onInit();
    })
  }


})();
