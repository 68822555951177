(function(){
  'use strict';
  angular
  .module('app')
  .component('databases', {
    templateUrl: 'app/components/payroll/payroll.html',
    controller: PaidrollController
  });

  PaidrollController.$inject = ['$uibModal','user', 'url', '$interval', '_','globalService','$rootScope'];

  function PaidrollController($uibModal, $user, $url, $interval, _, $globalService, $rootScope) {
    var vm = this;
    vm.loadingResumen = true;
    vm.user = $user.getCurrentUser();
    vm.dzOptions = {
      url : $url.getApiUrl('invoices'),
      paramName : 'file',
      maxFilesize : '1',
      // acceptedFiles : 'application/csv',
      addRemoveLinks : true,
      headers: {
        'Authorization': vm.user.token
      }
    };

    vm.dzCallbacks = {
      'addedfile' : function(file){
        vm.newFile = file;
      },
      'success' : function(file, response){
        vm.loadingResumen = false;
        swal('info', response.message, 'info');
      },
    };

    vm.$onInit = function(){
      $rootScope.$emit('uploadBreadCrum', {current: 'Database Admin', before: 'Home'});
      $globalService.getPeriods()
      .then(function(res){
        vm.periods = res;
      })

    }

    function showModalResumen(resumen){
      var modal = $uibModal.open({
        animation: true,
        component: 'payrollModalResumen',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          resumen: function () {
            return resumen;
          }
        }
      });

      modal.result.then(function (res) {
        vm.$onInit();
      }, function (err) {
        swal('Error', err.data.message, 'error');
      });
    }
    
    vm.downloadModel = function(){
      var new_data = [{
          'Member ID': '',
          'Periodo': '',
          'Cant. Clientes': '',
          'Monto': '',
          'New/ReNewal': '',
          'Company ID': ''
        }]
      var name = 'Modelo de pago';
      var a = document.createElement("a");
      var json_pre = angular.toJson(new_data);
      var fileName = name+".csv";
      
      var csv = Papa.unparse(json_pre);
 
      if (window.navigator.msSaveOrOpenBlob) {
        var blob = new Blob([decodeURIComponent(encodeURI(csv))], {
          type: "text/csv;charset=utf-8;"
        });
        navigator.msSaveBlob(blob, fileName);
      } else {
 
        a.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(csv);
        a.target = '_blank';
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      }
    }

    $rootScope.$on('loadDatabases',function(evt, data){
      vm.$onInit();
    })
    
  }


})();
