(function () {
  'use strict';
  angular
  .module('app')
  .component('insuranceProduct', {
    templateUrl: 'app/components/customerDirectory/activeProduct.html',
    controller: activeProductController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  activeProductController.$inject = ['$rootScope','globalService'];

  function activeProductController($rootScope, $globalService) {
    var vm = this;
    vm.save = save;

    vm.$onInit = function() {
      vm.product = vm.resolve.product;
      vm.directory = vm.resolve.directory;
      vm.agents = vm.resolve.agents;
      switch (vm.product) {
        case 'health':
          vm.titles = {
            name: 'Health',
            code: 'FFID',
            active_id: false,
          }
          break;
        case 'vision':
          vm.titles = {
            name: 'Vision',
            code: 'Confirmation Number',
            active_id: true, 
          }
          break;
        case 'accident':
          vm.titles = {
            name: 'Vision',
            code: 'App Number',
            active_id: true,
          }
          break;
        case 'dental':
          vm.titles = {
            name: 'Dental',
            code: 'Confirmation Number',
            active_id: true,
          }
          break;
        case 'life':
          vm.titles = {
            name: 'Life',
            code: 'Application ID',
            active_id: true,
          }
          break;
        default:
          break;
      }
    }

    function save(){
      switch (vm.product) {
        case 'health':
          vm.directory.health = true
          vm.directory.health_id = vm.id
          vm.directory.health_code = vm.code
          vm.directory.health_agent_id = vm.agent_id
          break;
        case 'vision':
          vm.directory.vision = true
          vm.directory.vision_id = vm.id
          vm.directory.vision_code = vm.code
          vm.directory.vision_agent_id = vm.agent_id
          break;
        case 'accident':
          vm.directory.accident = true
          vm.directory.accident_id = vm.id
          vm.directory.accident_code = vm.code
          vm.directory.accident_agent_id = vm.agent_id
          break;
        case 'dental':
          vm.directory.dental = true
          vm.directory.dental_id = vm.id
          vm.directory.dental_code = vm.code
          vm.directory.dental_agent_id = vm.agent_id
          break;
        case 'life':
          vm.directory.life = true
          vm.directory.life_id = vm.id
          vm.directory.life_code = vm.code
          vm.directory.life_agent_id = vm.agent_id
          break;
        default:
          break;
      }

      swal({
        title: 'Are you Sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.customerDirectories.update({id: vm.directory.id}, vm.directory).$promise
            .then(function(res){
              vm.loading = false;
              resolve(res.message);
            }, function (err) {
              vm.send = false;
              reject(err.data.message);
            });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) {
        swal('Success', data.value, 'success');
        vm.close({$value: ''});
      });
      
    }

    vm.findApplicationId = function (id) {
      vm.loading = true;
      if(id && vm.titles.name == 'Health'){
        $globalService.resApplications.get({id: id}).$promise
        .then(function(res){
          vm.loading = false;
          vm.code = res.application_id;
          vm.agent_id = res.user.id;
          vm.agent_name = res.user.full_name;
        }, function(err){
          vm.loading = false;
          swal('Error',err.data.message,'error');
        })
      }else {
        vm.loading = false;
        vm.id = undefined;
        vm.code = undefined;
        vm.agent_id = undefined;
        vm.agent_name = undefined;
      }
    }


    vm.cancel = function(){
      vm.dismiss({$value: ''})
    }
  }

})();
