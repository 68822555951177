(function () {
  'use strict';
  angular
    .module('app')
    .component('mainApplicantForm', {
      templateUrl: 'app/components/manage-applications/main_applicant_form.html',
      controller: ProcessApplicationController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

  ProcessApplicationController.$inject = ['$rootScope', 'globalService', 'user'];

  function ProcessApplicationController($rootScope, $globalService, $user) {
    var vm = this;
    vm.save = save;

    vm.$onInit = function () {
      $globalService.get_states()
      .then(function(res) {
        vm.states = res;
      })
      vm.currentUser = $user.getCurrentUser();
    }


    function save() {
      
      vm.currentApplication.main_applicant.birthday = moment(vm.currentApplication.main_applicant.birthday).format('MM/DD/YYYY')
      swal({
        title: 'Are you sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Acept',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.updateMainApplicant(vm.currentApplication)
              .then(function (res) {
                $rootScope.$emit('initAllAplications');
                swal('Exito', res.message, 'success');
              }, function () {
                reject('Ha ocurrido un error');
              });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) {
        vm.$onInit();
      });
    }

    $rootScope.$on('setCurrentApplicant', function (event, data) {
      console.log(data)
      vm.currentApplication = data.current;
      vm.currentApplication.main_applicant.age = moment(vm.currentApplication.main_applicant.birthday, 'MM/DD/YYYY').month(0).from(moment().month(0)).split(' ')[0];
      
    })

    $rootScope.$on('updateMainApplicant', function (event, data) {
      save();
    })

  }

})();
