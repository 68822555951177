(function(){
  'use strict';
  angular
    .module('app')
    .component('companies', {
      templateUrl: 'app/components/companies/companies.html',
      controller: CompanyController
    });

    CompanyController.$inject = ['$uibModal','user','_','globalService','$rootScope','$state'];

  function CompanyController($uibModal, $user, _, $globalService, $rootScope, $state) {
    var vm = this;
    vm.addCompany = addCompany;
    vm.showCompanies = true;
    vm.showPlans = showPlans;
    vm.addPlan = addPlan;
    vm.edit = edit;
    vm.editPlan = editPlan;
    vm.currentUser = $user.getCurrentUser();



    vm.$onInit = function(){
      $rootScope.$emit('uploadBreadCrum', {current: 'Empresas Aseguradoras', before: ''});
      $globalService.companies
      .query(function(res){
        vm.companies = res;
      })
      $globalService.typeInsuranceCompanies()
      .then(function(res){
        vm.types_insurances_companies = res;
      })
    }
    
    vm.goToRates = function(company){
      //console.log(company)
      var url = company.name.split(' ').join('-') + "-"+company.id;
      $state.go('paymentCompany',{company_name: url});
    }


    function addCompany(data){
      var modal = $uibModal.open({
        animation: true,
        component: 'companiesModal',
        windowClass: 'show',
        size: 'lg',
        resolve: {
          types_insurances_companies: function(){
            return vm.types_insurances_companies;
          }
        }
      });

      modal.result.then(function(res){
        vm.$onInit();
      });
    }

    function addPlan(data){
      var modal = $uibModal.open({
        animation: true,
        component: 'companiesPlanModal',
        windowClass: 'show',
        size: 'lg',
        resolve: {
          productTypeSelected: function(){
            return vm.productTypeSelected;
          }
        }
      });

      modal.result.then(function(res){
        vm.productTypeSelected.plans.push(res);
      });
    }

    function edit(data){
      var modal = $uibModal.open({
        animation: true,
        component: 'companyEdit',
        windowClass: 'show',
        size: 'lg',
        resolve: {
          company: function(){
            return angular.copy(data);
          },
          types_insurances_companies: function(){
            return vm.types_insurances_companies;
          }
        }
      });

      modal.result.then(function(res){
        vm.$onInit()
      });

    }

    function editPlan(data){
      var modal = $uibModal.open({
        animation: true,
        component: 'companiesPlanModal',
        windowClass: 'show',
        size: 'lg',
        resolve: {
          plan: function(){
            return data;
          }
        }
      });

      modal.result.then(function(res){
        vm.$onInit()
      });

    }





    function showPlans(data){
      vm.showCompanies = !vm.showCompanies;
      vm.currentCompany = data;

    }
    //
    // function edit(data){
    //   var modal = $uibModal.open({
    //     animation: true,
    //     component: 'branchOfficeModal',
    //     windowClass: 'show',
    //     size: 'lg',
    //     resolve: {
    //       branch_office: function(){
    //         return angular.copy(data);
    //       }
    //     }
    //   });
    //   modal.result.then(function(res){
    //     vm.$onInit();
    //   });
    // }


  }


})();
