(function(){
  'use strict';
  angular
  .module('app')
  .component('reportApplicationsPostalCode', {
    templateUrl: 'app/components/report-postal-code/report-postal-code.html',
    controller: ReportsApplicationPostalCode
  });

  ReportsApplicationPostalCode.$inject = ['user', '$rootScope', '$interval', '_', 'globalService','$uibModal','url'];

  function ReportsApplicationPostalCode($user, $rootScope, $interval, _,$globalService, $uibModal, $url) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.showCurrentApplication = false;
    vm.loadingData = false;

    vm.$onInit = function(){
      vm.loadingData = true;
      $rootScope.$emit('uploadBreadCrum', {current: 'Reports Applications Postal Code', before: 'Home'});
      var params = {
        postal_code: vm.postal_code
      }
      $globalService.reportsApplicationsPostalCode(params)
      .then(function(res){
        vm.applications = res;
        vm.loadingData = false;
        vm.urlReport = $globalService.reportPdf('report_customer_applications_postal_code', params)
      });
      
    }


    vm.consultApplications = function(){
      vm.loadingData = true;
      var params = {
        postal_code: vm.postal_code
      }
      $globalService.reportsApplicationsPostalCode(params)
      .then(function(res){
        vm.loadingData = false;
        vm.applications = res;
        vm.urlReport = $globalService.reportPdf('report_customer_applications_postal_code', params)
      });
    }
  }


})();
