angular
  .module('app')
  .config(routesConfig)
  .config(['$sceDelegateProvider', function($sceDelegateProvider) {
    $sceDelegateProvider.resourceUrlWhitelist([
        'self',
        'http://localhost:3003/**',
        'http://lvh.me/**'
    ]);
  }]);

/** @ngInject */
function routesConfig($stateProvider, $urlRouterProvider, $locationProvider,$httpProvider, $sceProvider) {
  $httpProvider.interceptors.push(['$q', 'user', '$rootScope','$state','$window', function ($q, user, $rootScope,$state, $window) {
      return {
        request: function(config) {
          // config.headers['content-type'] = 'application/json';
          if(user.getCurrentUser()){
            config.headers['Authorization'] = user.getCurrentUser().token;
          }

          return config;
        },
        responseError: function(rejection) {
          if (rejection.status == 401) {
            $rootScope.$broadcast('UNAUTHORIZED');
          }

          return $q.reject(rejection);
        }
      };
    }]); 
  $locationProvider.html5Mode(true).hashPrefix('!');
  $urlRouterProvider.otherwise('/');

  $stateProvider
  .state('webLayout', {
    abstract: true,
    component: 'webLayout'
  })
  .state('loginLayout', {
    abstract: true,
    component: 'loginLayout'
  })
  .state('login', {
    parent: 'loginLayout',
    url: '/login',
    component: 'auth',
  })
  .state('newPassword', {
    parent: 'loginLayout',
    url: '/login/reset-password',
    component: 'newPassword',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('forgotPassword', {
    parent: 'loginLayout',
    url: '/i-forgot-my-password',
    component: 'forgotPassword'
  })
  .state('signup', {
    parent: 'loginLayout',
    url: '/registrate',
    component: 'signUp',
  })
  .state('blockScreen', {
    parent: 'loginLayout',
    url: '/inactividad-detectada?current_page',
    component: 'blockScreen',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('home', {
    parent: 'webLayout',
    url: '/',
    component: 'home',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('dashboardOpen', {
    parent: 'webLayout',
    url: '/dashboard/open-enrollment',
    component: 'dashboardOpen',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.dashboard_open.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })

  
  .state('dashboardAgent', {
    parent: 'webLayout',
    url: '/agents/dashboard',
    component: 'dashboardAgent',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('dashboardManager', {
    parent: 'webLayout',
    url: '/managers/dashboard',
    component: 'dashboardManager',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('procesarApplication', {
    parent: 'webLayout',
    url: '/managers/:crmid/process-applications/:user_npn?',
    component: 'procesarApplication',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  .state('customerSms', {
    parent: 'webLayout',
    url: '/application/:crmid/customer-sms',
    component: 'customerSms',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.view_customers_sms.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  .state('users', {
    parent: 'webLayout',
    url: '/user-administration',
    component: 'users',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.users.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('userInformation', {
    parent: 'webLayout',
    url: '/user/:id/:full_name/user-information',
    component: 'userInformation',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.user_information.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('company', {
    parent: 'webLayout',
    url: '/empresas-aseguradoras',
    component: 'companies',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.company.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('paymentCompany', {
    parent: 'webLayout',
    url: '/empresa-aseguradora/:company_name/metodos-de-pago',
    component: 'paymentCompany',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.company.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('userRates', {
    parent: 'webLayout',
    url: '/user/:id/rates',
    component: 'agentRates',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.company.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('documents', {
    parent: 'webLayout',
    url: '/user/:app_id/documents',
    component: 'documents',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.documents.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('audits', {
    parent: 'webLayout',
    url: '/customer-application/:app_id/audits',
    component: 'audits',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.audits.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('customPayments', {
    parent: 'webLayout',
    url: '/custom-payments',
    component: 'customPayments',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
            // if(!user.getCurrentUser().permits_granted.user.active){
            //   $state.go('home');
            // }
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('exports', {
    parent: 'webLayout',
    url: '/export-databases',
    component: 'exports',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
            // if(!user.getCurrentUser().permits_granted.user.active){
            //   $state.go('home');
            // }
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('applications', {
    parent: 'webLayout',
    url: '/crear-nuevas-aplicaciones/:id',
    component: 'applications',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.create_new_applications.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('listApplications', {
    parent: 'webLayout',
    url: '/todas-las-aplicaciones',
    component: 'manageApplications',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.list_applications.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('myApplications', {
    parent: 'webLayout',
    url: '/mis-aplicaciones',
    component: 'dashboardManager',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.my_applications.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('reportApplications', {
    parent: 'webLayout',
    url: '/applications-report',
    component: 'reportApplications',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.report_applications.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('reportApplicationsDeleteds', {
    parent: 'webLayout',
    url: '/deleteds-applications-report',
    component: 'reportApplicationsDeleteds',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.report_applications_deleted.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('reportConflicts', {
    parent: 'webLayout',
    url: '/customer-application-with-conflicts',
    component: 'reportConflicts',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.report_applications.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('reportTaxSaving', {
    parent: 'webLayout',
    url: '/tax-saving-report',
    component: 'reportTaxSaving',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.tax_saving_report.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('reportUnpaidApplications', {
    parent: 'webLayout',
    url: '/report-unpaid-applications',
    component: 'reportUnpaidApplications',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.report_unpaid_applications.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('reportApplicationsAgents', {
    parent: 'webLayout',
    url: '/reporte-de-applicaciones-por-agentes',
    component: 'reportApplicationsAgents',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.report_customer_applications_agents.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('reportAgentsDates', {
    parent: 'webLayout',
    url: '/reporte-de-applicaciones-por-agentes-members-id',
    component: 'reportAgentsDates',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.report_agents_dates.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('reportAgency', {
    parent: 'webLayout',
    url: '/reports-agencies-and-sub-agencies',
    component: 'reportAgency',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.report_agency.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('reportOpen', {
    parent: 'webLayout',
    url: '/report-open-enrollment',
    component: 'reportOpen',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.report_open.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('reportRegisterType', {
    parent: 'webLayout',
    url: '/report-register-type',
    component: 'reportRegisterType',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.report_register_type.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })

  
  .state('reportCotizacion', {
    parent: 'webLayout',
    url: '/reporte-para-cotizaciones',
    component: 'reportCotizacion',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.report_cotizacion.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('reportDocumentsRequireds', {
    parent: 'webLayout',
    url: '/reporte-de-documentos requeridos',
    component: 'reportDocumentsRequireds',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.report_documents_requireds.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('reportPostalCode', {
    parent: 'webLayout',
    url: '/reporte-de-applicaciones-por-codigo-postal',
    component: 'reportApplicationsPostalCode',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.report_applications_postal_code.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('usedNpns', {
    parent: 'webLayout',
    url: '/report/most-used-npns',
    component: 'usedNpns',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.used_npns.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })

  .state('birthdays', {
    parent: 'webLayout',
    url: '/customer-birthdays',
    component: 'reportsCustomerBirthday',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.report_customer_birthday.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('smsNotifyLog', {
    parent: 'webLayout',
    url: '/reports-sms-notify-log',
    component: 'smsNotifyLog',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.sms_notify_log.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('databases', {
    parent: 'webLayout',
    url: '/payroll',
    component: 'databases',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.load_members_period.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('payrollSummaries', {
    parent: 'webLayout',
    url: '/payroll-summaries',
    component: 'payrollSummaries',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.load_members_period.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })

  .state('payrollSummary', {
    parent: 'webLayout',
    url: '/payroll-summary/:id',
    component: 'payrollSummary',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.load_members_period.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })

  
  .state('profits', {
    parent: 'webLayout',
    url: '/reporte-de-ganancias',
    component: 'profits',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.profits.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('negativeInvoice', {
    parent: 'webLayout',
    url: '/negative-invoices',
    component: 'negativeInvoice',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.profits.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })

  
  .state('profile', {
    parent: 'webLayout',
    url: '/user-profile',
    component: 'profile',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('profitsAgent', {
    parent: 'webLayout',
    url: '/reporte-de-ganancias-por-agentes',
    component: 'profitsAgents',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.profits.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('agentLicense', {
    parent: 'webLayout',
    url: '/agent/:id/:full_name/licenses',
    component: 'agentLicense',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            // if(!user.getCurrentUser().permits_granted.agencies.active){
              // $state.go('home');
            // }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('agentsSmsNotifications', {
    parent: 'webLayout',
    url: '/sms-notifications/:id/:full_name',
    component: 'agentsSmsNotifications',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            // if(!user.getCurrentUser().permits_granted.agencies.active){
              // $state.go('home');
            // }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('agency', {
    parent: 'webLayout',
    url: '/user/:id/:full_name',
    component: 'agency',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.agencies.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  
  .state('profitsAgentDetail', {
    parent: 'webLayout',
    url: '/reporte-de-ganancias-por-agente/:agent_id?/:date_from?/:date_to?/:close?/:paid?',
    component: 'profitsAgentDetail',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.profits.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('allInvoices', {
    parent: 'webLayout',
    url: '/all-invoices',
    component: 'allInvoices',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.profits.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })

  
  .state('profitsCompanyDetail', {
    parent: 'webLayout',
    url: '/reporte-de-ganancias-por-company/:company_id?/:date_from?/:date_to?/:close?/:paid?',
    component: 'profitsAgentDetail',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.profits.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('periods', {
    parent: 'webLayout',
    url: '/periods',
    component: 'periods',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.profits.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('generalInvopice', {
    parent: 'webLayout',
    url: '/employee-invoices',
    component: 'generalInvopice',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.general_invoice.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('closure', {
    parent: 'webLayout',
    url: '/closure',
    component: 'closure',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.general_closure.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  }).state('generalInvoiceClosure', {
    parent: 'webLayout',
    url: '/invoice-employee-closure',
    component: 'generalInvoiceClosure',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.general_closure.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('systemVersions', {
    parent: 'webLayout',
    url: '/update-crm',
    component: 'systemVersions',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.crm_version.active){
              return $q.resolve(user);
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  .state('notifications', {
    parent: 'webLayout',
    url: '/notifications',
    component: 'notifications',
    resolve: {
      currentUser: [
        '$q',
        'user',
        function ($q, user) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.crm_version.active){
              return $q.resolve(user);
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ]
    }
  })
  .state('overide', {
    parent: 'webLayout',
    url: '/overide',
    component: 'overide',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.overide.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('customerDirectory', {
    parent: 'webLayout',
    url: '/customers-directories',
    component: 'customerDirectory',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.customer_directory.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  })
  .state('healthApplicationV2', {
    parent: 'webLayout',
    url: '/crm-forms/health-appications/:id?',
    component: 'healthApplicationV2',
    resolve: {
      currentUser: [
        '$q',
        'user',
        '$state',
        function ($q, user, $state) {
          if (user.getCurrentUser()) {
            if(!user.getCurrentUser().permits_granted.health_form_v2.active){
              $state.go('home');
            }
            return $q.resolve(user);
          }
          return $q.reject('UNAUTHORIZED');
        }
      ],
    }
  });
}
