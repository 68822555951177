(function(){
  'use strict';
  angular
  .module('app')
  .component('auth', {
    templateUrl: 'app/components/auth/auth.html',
    controller: AuthController
  });

  AuthController.$inject = ['$window', '$state','user', 'authService'];

  function AuthController($window, $state, $user, $authService) {
    var vm = this;
    vm.send = false;
    vm.doLogin = doLogin;
    vm.model = {key: '6LfOWzwpAAAAAMReUh0N6OD23VvISp2PFCzTBrs5'};
    vm.setResponse = setResponse;
    vm.setWidgetId = setWidgetId;
    vm.response = null;

    vm.$onInit = function(){
      var classPage = angular.element(document.querySelector('#classPage'));
      if(classPage.hasClass('register-page')){
        classPage.removeClass('register-page');
        classPage.addClass('login-page');
      }
    }


    function doLogin() {
      vm.send = true;
      // if(vm.response == null){
      //   vm.send = false;
      //   Swal('Error','Please verify that you are not a robot','error');
      //   return;
      // }
      $authService.doLogin(vm.user)
      .then(function(res){
        vm.send = false;
        var user  = res.user;
        $user.setCurrentUser(user);
        if(user.force_password_reset){
          $state.go('newPassword');
        }else {
          switch (user.pretty_type) {
            case "Agente":
              $state.go('dashboardAgent')
              // $window.location = '/agents/dashboard';
              break;
            case "Administrador":
              // $window.location = '/home';
              $state.go('home');
              break;
            case "Manager":
              $state.go('dashboardManager')
              // $window.location = '/managers/dashboard';
              break
            default:
              $state.go('home');
              // $window.location = '/home';
          }
        }
      }, function(err){
        vm.send = false;
        vm.user = {};
        Swal('Error',err.data.message,'error');
      })
    }

    function setResponse(response) {
      vm.response = response;
      if (vm.valid) {
        vm.noCapchat = false;
      }
    }
  
    function setWidgetId(widgetId) {
      vm.widgetId = widgetId;
    }
  }
})();
