(function () {
  'use strict';
  angular
    .module('app')
    .component('companiesModal', {
      templateUrl: 'app/components/companies/companies_modal.html',
      controller: CompaniesModalController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

  CompaniesModalController.$inject = ['$sce', 'globalService'];

  function CompaniesModalController($sce, $globalService) {
    var vm = this;
    vm.save = save;
    vm.addProduct = addProduct;
    vm.company;
    vm.addType = addType;


    vm.$onInit = function () {
      vm.types_insurances_companies = vm.resolve.types_insurances_companies;
      if (vm.resolve.company) {
        vm.company = vm.resolve.company;
        vm.type_insurance_selected = _.find(vm.types_insurances_companies, { id: vm.company.type_insurance_company_id })
      } else {
        vm.company = { products: [] };
      }

    }


    function save() {
      vm.send = true;
      //console.log(vm.company)

      $globalService.companies.save(vm.company, function (res) {
        vm.send = false;
        Swal('Exito', res.message, 'success');
        vm.close({ $value: res.data })
      }, function (err) {
        Swal('Error', err.data.message, 'warning');
        vm.cancel({ $value: '' });
      });
    }


    function addProduct() {

      vm.company.products.push({
        name: vm.productName,
        selected: true,
        active: true
      })
      vm.productName = '';
    }

    function addType(data) {
      if (data.hasOwnProperty('types')) {
        data.types.push({
          name: data.newType
        })
      } else {
        data.types = [{ name: data.newType }];
      }
      data.newType = undefined;
    }

  }

})();
