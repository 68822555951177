(function(){
  'use strict';
  angular
  .module('app')
  .component('exports', {
    templateUrl: 'app/components/exports/exports.html',
    controller: ExportsController
  });

  ExportsController.$inject = ['$uibModal','user', 'url', 'BASE_URL', '_','globalService','$rootScope'];

  function ExportsController($uibModal, user, $url, BASE_URL, _, $globalService, $rootScope) {
    var vm = this;
    vm.loadingResumen = true;
    
    vm.$onInit = function(){
      vm.loading = true;
      $rootScope.$emit('uploadBreadCrum', {current: 'Periods', before: 'Home'});
      $globalService.export_database.query().$promise
      .then(function(res) {
        vm.loading = false;
        vm.exports = _.map(res, function(data) {
          data.download_url = BASE_URL + '/api/v1/export/' + data.id + '?token='+user.getCurrentUser().token;
          return data;
        })
      })
    }


    vm.exportDatabase = function() {
      $globalService.export_database.save(vm.user,
        function(res){
          vm.send = false;
          Swal('Success',res.message,'success');
        }, function(err){
          Swal('Error',err.data.errors,'warning');
        });
    }

    vm.destroy = function(data){
      vm.loading = true;
      $globalService.export_database.delete({id: data.id}, data, function(res){
        vm.loading = false;
        vm.$onInit();
        Swal('Success',res.message,'success');
      }, function(err){
        Swal('Error',err.data.errors,'error');
      })
    }

    $rootScope.$on('loadDatabases',function(evt, data){
      vm.$onInit();
    })
    
  }


})();
