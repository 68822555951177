(function () {
  'use strict';
  angular
    .module('app')
    .component('users', {
      templateUrl: 'app/components/users/users.html',
      controller: UserController
    });

  UserController.$inject = ['$uibModal', 'toastr', '$window', 'user', '_', 'globalService', '$rootScope'];

  function UserController($uibModal, toastr, $window, $user, _, $globalService, $rootScope) {
    var vm = this;
    vm.addUser = addUser;
    vm.edit = edit;
    vm.blockUser = blockUser;
    vm.toggleTaxSaving = toggleTaxSaving;
    vm.loading = false;
    vm.currentUser = $user.getCurrentUser();


    vm.$onInit = function () {
      vm.loading = true;
      $rootScope.$emit('uploadBreadCrum', { current: 'Users Management', before: 'Home' });
      vm.sections = $globalService.sections.query();
      $globalService.users.query().$promise
      .then(function(res){
        vm.users = _.map(res, function(data){
          data.slug_name = data.full_name.split(' ').join('-');
          return data;
        });
        vm.loading = false;
      })

      vm.downloadUsersLink = $globalService.getUrlExcel('users',{token: vm.currentUser.token});
      
      $globalService.getUserTypes()
      .then(function (res) {
        vm.user_types = res;
      })
    }

    vm.loginAs = function (user) {
      vm.loading = true;
      $globalService.loginAs({user_id: user.id})
      .then(function(res){
        $window.localStorage.user_back = JSON.stringify(vm.currentUser);
        $window.localStorage.removeItem('user')
        $user.setCurrentUser(res);
        $window.location.reload();
        vm.loading = false;
      })
    }

    vm.getReportAppointes = function(){
      $window.location = $globalService.getAllApointeds(vm.currentUser.token)
    }

    vm.goToRates = function (user) {
      $state.go('userRates', { id: user.id });
    }


    function addUser() {
      var modal = $uibModal.open({
        animation: true,
        component: 'userModal',
        windowClass: 'show',
        size: 'lg',
        resolve: {
          sections: function () {
            return vm.sections;
          },
          user_types: function () {
            return vm.user_types;
          },
          users: function () {
            return _.where(vm.users, { parent_id: null});
          },
          agents: function(){
            return _.where(vm.users, { role: 3 });
          }
        }
      });

      modal.result.then(function (res) {
        vm.$onInit();
      });
    }

    function edit(data) {
      $globalService.users.get({ id: data.id }).$promise
      .then(function (res) {
        var modal = $uibModal.open({
          animation: true,
          component: 'userModal',
          windowClass: 'show',
          size: 'lg',
          resolve: {
            sections: function () {
              return vm.sections;
            },
            user_types: function () {
              return vm.user_types;
            },
            user: function () {
              return angular.copy(res);
            },
            users: function () {
              return _.where(vm.users, { parent_id: null});
            },
            agents: function(){
              return _.where(vm.users, { role: 3 });
            }
          }
        });
  
        modal.result.then(function (res) {
          vm.$onInit();
        });
      })
    }

    function blockUser(user, index) {
      swal({
        title: '¿Are you Sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.blockUser({ id: user.id })
              .then(function (res) {
                swal('Exito', res.message, 'success');
                vm.$onInit();
              }, function () {
                reject('Ha ocurrido un error');
              });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    }

    vm.resetPassword = function(user){
      swal({
        title: '¿Are you Sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.resetPassword({ id: user.id })
              .then(function (res) {
                swal('Exito', res.message, 'success');
                vm.$onInit();
              }, function () {
                reject('Ha ocurrido un error');
              });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    }

    function toggleTaxSaving(user, index) {
      swal({
        title: '¿Are you Sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.toggleTaxSaving(user.id)
              .then(function (res) {
                swal('Exito', res.message, 'success');
                vm.$onInit();
              }, function () {
                reject('Ha ocurrido un error');
              });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    }
  }
})();
