(function () {
  'use strict';
  angular
    .module('app')
    .component('generalInvoiceModal', {
      templateUrl: 'app/components/general-invoice/general-invoice-modal.html',
      controller: GeneralInvoiceModalController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

  GeneralInvoiceModalController.$inject = ['$sce', 'globalService', 'user'];

  function GeneralInvoiceModalController($sce, $globalService, $user) {
    var vm = this;

    vm.$onInit = function () {
      vm.users = vm.resolve.users;
      vm.currentUser = $user.getCurrentUser();
      vm.customPayments = vm.resolve.customPayments;
      $globalService.getOnlyTypeInsuranceCompanies()
      .then(function (res) {
        vm.typeInsuranceCompanies = res;
        if (vm.resolve.invoice) {
          vm.invoice = angular.copy(vm.resolve.invoice);
          _.map(vm.invoice.applications, function(data){
            data.typeSelected = _.find(vm.typeInsuranceCompanies, {name: data.application_type});
           return data; 
          })
          vm.userSelected = _.find(vm.users, { id: vm.invoice.user_id });
          console.log(vm.invoice, vm.userSelected)
          vm.userChanged();
          vm.amount = vm.invoice.total_amount;
          // setTotal();
        } else {
          vm.invoice = {
            hours: 40,
            price_hours: 0,
            applications: [],
            bonos: [],
            details: {
              discounts: [],
              custom_payments: []
            }
          }
        }
      })
    }

    function getTypeApp(data) {
    
      switch (data.type_app) {
        case "Recovered":
          return 0
          break;
        case "Change Life":
          return 2
          break;
      }
    }

    vm.addApplication = function (type) {
      if (vm.invoice.user_id) {
        vm.invoice.applications.push({
          type_app: type,
          total_amount: 0,
          application_type: null,
          customer_application_id: null,
          loading: null,
          amount_type: 0,
          status: 0
        })
      } else {
        Swal('Wait', 'Select User first', 'warning');
      }
    }

    vm.addBonus = function () {
      if (vm.invoice.user_id) {
        vm.invoice.bonos.push({
          total_amount: 0,
          description: null,
        })
      } else {
        Swal('Wait', 'Select User first', 'warning');
      }
    }

    vm.calculateAmount = function () {
      console.log(vm.invoice)
      vm.invoice.salary = (vm.invoice.hours * vm.invoice.price_hours);
      vm.invoice.total_amount = angular.copy(vm.invoice.salary);
    }
    vm.userChanged = function (params) {
      vm.loading = true;
      $globalService.users.get({ id: vm.userSelected.id }).$promise
      .then(function(res){
        vm.loading = false;
        vm.userSelected = res;
        if(vm.invoice.id){
          vm.invoice.user_id = vm.userSelected.id;
          vm.invoice.price_hours = vm.userSelected.user_rate.amount_hours;
          vm.invoice.salary = (vm.invoice.hours * vm.invoice.price_hours);
          setTotal();
        }else{
          vm.invoice.user_id = vm.userSelected.id;
          vm.invoice.price_hours = vm.userSelected.user_rate.amount_hours;
          vm.calculateAmount();
        }
      })
      
    }


    vm.searchCustomerApp = function (app, section) {
      if (!_.isNull(app.customer_application_id)) {
        app.loading = 'loading';
        $globalService.resApplications.get({ id: app.customer_application_id }).$promise
          .then(function (res) {
            app.loading = 'loaded';
            app.typeSelected = _.findWhere(vm.typeInsuranceCompanies, { id: parseInt(res.insurance_data.type_insurance_company) })
            app.application_type = app.typeSelected.id;
            if (section == 1) { //Recovered
              if (app.typeSelected.id == 1 || app.typeSelected.id == 2) {
                app.amount = vm.userSelected.user_rate.amount_apps;
              } else if (app.typeSelected.id == 3) {
                app.amount = (parseInt(app.cost) * parseInt(vm.userSelected.user_rate.amount_percent)) / 100
                app.amount_type = 1
              }
            } else { //Change life
              app.amount = vm.userSelected.user_rate.amount_change_life;
            }

          }, function (err) {
            app.loading = 'error';
          })
      }
    }

    vm.save = function () {
      vm.invoice.date_from = moment(vm.date_from).format("YYYY-MM-DD");
      vm.invoice.date_to = moment(vm.date_to).format("YYYY-MM-DD");
      setTotal()

      if(vm.invoice.id){
        swal({
          title: 'Are you sure to update the invoice with: ' + accounting.formatMoney(vm.invoice.total_amount,'','2') + ' ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          showLoaderOnConfirm: true,
          preConfirm: function () {
            return new Promise(function (resolve, reject) {
              $globalService.generalInovices.update({id: vm.invoice.id}, vm.invoice).$promise
                .then(function (res) {
                  Swal('Success', res.message, 'success');
                  vm.close();
                }, function (err) {
                  Swal('Stop', err.data.message, 'warning');
                })
            });
          },
          allowOutsideClick: false,
        });
      }else{
        swal({
          title: 'Are you sure to generate an invoice for: ' + accounting.formatMoney(vm.invoice.total_amount) + ' ?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          showLoaderOnConfirm: true,
          preConfirm: function () {
            return new Promise(function (resolve, reject) {
              $globalService.generalInovices.save(vm.invoice).$promise
                .then(function (res) {
                  Swal('Success', res.message, 'success');
                  vm.close();
                }, function (err) {
                  Swal('Stop', err.data.message, 'warning');
                })
            });
          },
          allowOutsideClick: false,
        });
      }


    }

    function setTotal() {
      var total = angular.copy(vm.invoice.salary);
      vm.invoice.total_amount = 0;
      vm.invoice.total_amount += total;

      if (vm.invoice.applications.length > 0) {
        var total_apps = _.reduce(vm.invoice.applications, function (memo, data) {
          memo += parseFloat(data.amount);
          return memo;
        }, 0)
        vm.invoice.total_amount += total_apps;

      }
      if (vm.invoice.bonos.length > 0) {
        var total_bonos = _.reduce(vm.invoice.bonos, function (memo, data) {
          memo += parseFloat(data.amount);
          return memo;
        }, 0)
        vm.invoice.total_amount += total_bonos;
      }

      if (vm.invoice.details.discounts.length > 0) {
        var totalDiscounts = _.reduce(vm.invoice.details.discounts, function (memo, data) {
          memo += data.amount;
          return memo;
        }, 0)
        vm.invoice.total_amount -= totalDiscounts;
      }

      if(vm.invoice.details.custom_payments.length > 0) {
        var totalCustomPayments = _.reduce(vm.invoice.details.custom_payments, function (memo, data) {
            memo += data.amount;
          return memo;
        }, 0)
        vm.invoice.total_amount += totalCustomPayments;
      }
    }

    vm.deleteApp = function (index) {
      vm.invoice.applications.splice(index, 1)
    }

    vm.deleteBonus = function (index) {
      vm.invoice.bonos.splice(index, 1)
    }

    vm.deleteDiscount = function (index) {
      vm.invoice.details.discounts.splice(index, 1)
    }
    vm.deleteCustom = function(index) {
      vm.invoice.details.custom_payments.splice(index, 1)
    }

    vm.addDiscount = function () {
      vm.invoice.details.discounts.push({
        amount: null,
        description: ''
      })
    }

    vm.addCustomPayment = function () {
      vm.invoice.details.custom_payments.push({
        custom_payment_info: '',
        description:'',
        amount: null,
      })
    }

    vm.setCustomPaymentsData = function(app) {
      app.amount = angular.copy(app.customPaymentSelected.amount);
      app.custom_payment_info = angular.copy(app.customPaymentSelected.name);
    }
  }

})();
