(function () {
  'use strict';
  angular
    .module('app')
    .component('invoiceModal', {
      templateUrl: 'app/components/profits/modal-invoice.html',
      controller: InvoiceModalController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

  InvoiceModalController.$inject = ['$sce', 'globalService','user'];

  function InvoiceModalController($sce, $globalService, $user) {
    var vm = this;

    vm.$onInit = function () {
      vm.report = vm.resolve.report;
      vm.agent = vm.resolve.agent;
      vm.currentUser = $user.getCurrentUser();
      vm.companies = $globalService.companies.query();
      vm.companySelected = $globalService.companies.get({id: vm.report.company_id});
    }


    vm.updateFrofits = function (params) {
      vm.code = _.random(9999);
      swal({
        title: 'Enter this code '+vm.code+' to confirm the update.',
        input: 'number',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function(code) {
          if(code == vm.code){
            return $globalService.updateInvoice(vm.report)
            .then(function(res){
              vm.send = false;
              vm.loadingApp = false;
              Swal('Exito',res.message,'success');
              vm.close();
            }, function(err){
              vm.loadingApp = false;
              swal.showValidationError(err.data.message);
            });
          }
        }
      })
    }

    vm.deleteInvoice = function (params) {
      vm.code = _.random(9999);
      swal({
        title: 'Enter this code '+vm.code+' to confirm deleteion.',
        input: 'number',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function(code) {
          if(code == vm.code){
            return $globalService.deleteInvoice(vm.report)
            .then(function(res){
              vm.send = false;
              vm.loadingApp = false;
              vm.close();
              Swal('Exito',res.message,'success');
            }, function(err){
              vm.loadingApp = false;
              swal.showValidationError(err.data.message);
            });
          }
        }
      })
    }



  }

})();
