(function () {
  'use strict';
  angular
    .module('app')
    .component('customPayments', {
      templateUrl: 'app/components/custom_payments/custom_payments.html',
      controller: CustomPaymentsController
    });

  CustomPaymentsController.$inject = ['$uibModal', 'toastr', '$timeout', '$interval', '_', 'globalService', '$rootScope'];

  function CustomPaymentsController($uibModal, toastr, $timeout, $interval, _, $globalService, $rootScope) {
    var vm = this;
    vm.types = [{name: 'Amount', value: 0},{name: 'Percent', value: 1}]

    vm.$onInit = function () {
      vm.loading = true;
      vm.customPayment = { amount_type: 'amount' }
      $rootScope.$emit('uploadBreadCrum', { current: 'Custom Payments', before: 'Home' });
      $globalService.customPayments.query().$promise
        .then(function (res) {
          vm.customPayments = res;
          vm.loading = false;
        })
    }


    vm.save = function () {
      vm.loading = true;
      if(vm.customPayment.id){
        $globalService.customPayments.update({id: vm.customPayment.id}, vm.customPayment, function(res){
          vm.loading = false;
          clear()
          vm.$onInit();
          Swal('Exito',res.message,'success');
        }, function(err){
          Swal('Error',err.data.errors,'error');
        })
      }else{
        $globalService.customPayments.save(vm.customPayment,
          function(res){
            vm.loading = false;
            clear()
            vm.$onInit();
            Swal('Exito',res.message,'success');
          }, function(err){
            Swal('Error',err.data.errors,'warning');
          });
      }
    }

    vm.edit = function(data) {
      vm.customPayment = data;
      // vm.typeSelected = _.findWhere(vm.types, {name: capitalize(data.amount_type)})
    }

    vm.destroy = function(data){
      
      $globalService.customPayments.delete({amount_type: data.id}, data, function(res){
        vm.loading = false;
        vm.$onInit();
        Swal('Exito',res.message,'success');
      }, function(err){
        Swal('Error',err.data.errors,'error');
      })
    }

    function clear(){
      vm.customPayment = undefined;
      vm.typeSelected = undefined;
    }

    function capitalize(word) {
      return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }
  }
})();
