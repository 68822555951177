(function(){
  'use strict';
  angular
  .module('app')
  .component('newPassword', {
    templateUrl: 'app/components/new-password/new-password.html',
    controller: NewPasswordController
  });

  NewPasswordController.$inject = ['$window', '$state','$stateParams','user', 'globalService','toastr'];

  function NewPasswordController($window, $state, $stateParams, $user, $globalService, toastr) {
    var vm = this;
    vm.send = false;

    vm.$onInit = function(){
      vm.user = $user.getCurrentUser();
      if(!vm.user){
        $state.go('login');
      }
    }

    vm.save = function(){
      $globalService.users.update({id: vm.user.id}, vm.user, function(res){
        vm.send = false;
        Swal('Exito',res.message,'success');
        $state.go('login');
      }, function(err){
        Swal('Error',err.data.message,'error');
      })
    }


  }
})();
