(function(){
  'use strict';
  angular
  .module('app')
  .component('smsNotifyLog', {
    templateUrl: 'app/components/report-sms-notify-log/sms-notify-log.html',
    controller: SmsNotifyLogController
  });

  SmsNotifyLogController.$inject = ['user', '$rootScope', '$interval', '_', 'globalService','$uibModal','url'];

  function SmsNotifyLogController($user, $rootScope, $interval, _,$globalService, $uibModal, $url) {
    var vm = this;
    vm.loading = false;
    vm.currentUser = $user.getCurrentUser();
    vm.loadingData = false;
    vm.day = undefined;
    vm.month = undefined;

    vm.$onInit = function(){
      vm.loading = true;
      $rootScope.$emit('uploadBreadCrum', {current: 'Log SMS', before: 'Home'});
      $globalService.reportsSmsNotifyLog({date_from: moment().format('YYYY-MM-DD'), date_to: moment().format('YYYY-MM-DD')})
      .then(function(res){
        vm.smsNotifyLog = res;
        vm.loading = false;
        vm.urlReport = $globalService.reportExcel('sms_notify_logs_reports', {token: vm.currentUser.token, date_from: moment(vm.date_from).format('YYYY-MM-DD'), date_to: moment(vm.date_to).format('YYYY-MM-DD')});
      })
    }


    vm.consultCustomers = function(){
      vm.loading = true;
      var data = {
        date_from: moment(vm.date_from).format('YYYY-MM-DD'),
        date_to: moment(vm.date_to).format('YYYY-MM-DD')
      }
      $globalService.reportsSmsNotifyLog(data)
      .then(function(res){
        vm.loading = false;
        vm.smsNotifyLog = res;
        vm.urlReport = $globalService.reportExcel('sms_notify_logs_reports', {token: vm.currentUser.token, date_from: moment(vm.date_from).format('YYYY-MM-DD'), date_to: moment(vm.date_to).format('YYYY-MM-DD')});
      });
    }
  }


})();
