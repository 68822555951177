(function () {
  'use strict';
  angular
    .module('app')
    .component('generalInvoiceClosure', {
      templateUrl: 'app/components/general_invoice_closure/general_invoice_closure.html',
      controller: GeneralInvoiceClosureController
    });

  GeneralInvoiceClosureController.$inject = ['user', '_', '$rootScope', 'globalService', '$uibModal']

  function GeneralInvoiceClosureController($user, _, $rootScope, $globalService, $uibModal) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.send = false;

    vm.$onInit = function () {
      vm.send = true;
      $rootScope.$emit('uploadBreadCrum', { current: 'Cierre de mes', before: 'Home' });
      $globalService.generalInvoiceClosure.query().$promise.then(function name(res) {
        vm.send = false;
        vm.closures = res;  
      }, function(err) {
        vm.send = false;
      })
    }

    vm.open = function (data) {
      var modal = $uibModal.open({
        animation: true,
        component: 'invoiceClosureModal',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
      });

      modal.result.then(function (res) {
        vm.$onInit();
      });
    }
    vm.showDetail = function (data) {
      vm.send = true;
      $globalService.generalInvoiceClosure.get({id: data.id}).$promise.then(function(data){
        vm.send = false;
        vm.current = data;
      })
    }

    var clearAlerts = function() {
      vm.error = {}, vm.warning = null
    };
       
    vm.download = function(data,period,name){
      var a = document.createElement("a");
      var json_pre = angular.toJson(data);
      var fileName = period+"_"+name+".csv";
      
      var csv = Papa.unparse(json_pre);
 
      if (window.navigator.msSaveOrOpenBlob) {
        var blob = new Blob([decodeURIComponent(encodeURI(csv))], {
          type: "text/csv;charset=utf-8;"
        });
        navigator.msSaveBlob(blob, fileName);
      } else {
 
        a.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(csv);
        a.target = '_blank';
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      }
    }




  }


})();
