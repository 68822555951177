
(function(){
  'use strict';
  angular
    .module('app')
    .component('payrollSummary', {
      templateUrl: 'app/components/payroll-summaries/payroll-summary.html',
      controller: PayrollSummaryController
    });

    PayrollSummaryController.$inject = ['user', '$stateParams', '_','$rootScope','globalService']

  function PayrollSummaryController($user, $stateParams, _, $rootScope,$globalService) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();

    vm.$onInit = function(){
      vm.loading = true;
      vm.id = $stateParams.id

      $rootScope.$emit('uploadBreadCrum', {current: 'Payroll Summary', before: 'Payroll Summaries'});

      $globalService.payrollSummaries.get({id: vm.id}).$promise
      .then(function(res){
        vm.summary = res;
        vm.loading = false;
        console.log(res)
      });
    }


  }


})();
