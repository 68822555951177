(function(){
  'use strict';
  angular
  .module('app')
  .component('overide', {
    templateUrl: 'app/components/overide/overide.html',
    controller: OverideController
  });

  OverideController.$inject = ['user', '$rootScope', '$state', '_', 'globalService','$uibModal','url'];

  function OverideController($user, $rootScope, $state, _,$globalService, $uibModal, $url) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.showCurrentApplication = false;
    vm.loadingData = false;
    var date = new Date();
    vm.date_from = new Date(date.getFullYear(), date.getMonth(), 1);
    vm.date_to = new Date();
    vm.params = {
      close: false,
      date_from: moment().startOf('month').format('MM/DD/YYYY'),
      date_to: moment().endOf('month').format('MM/DD/YYYY'),
    }

    vm.$onInit = function(){
      vm.loadingData = true;
      vm.params.date_from = moment(vm.params.date_from).format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.params.date_to).format('YYYY-MM-DD');
      $rootScope.$emit('uploadBreadCrum', {current: 'Overide', before: 'Home'});
      
      vm.companies = $globalService.companies.query();
      $globalService.users.query().$promise
      .then(function(res){
        vm.agents = res;
        vm.loadingData = false;
      })
      
    }


    vm.consult = function(){
      vm.loadingData = true;
      vm.params.report_complete = false;
      vm.params.date_from = moment(vm.date_from).format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.date_to).format('YYYY-MM-DD');
      $globalService.overideReport(vm.params)
      .then(function(res){
        vm.loadingData = false;
        vm.reports = res;
        vm.calculate_total(res);
        var params = vm.params;
        params.token = vm.currentUser.token;
        vm.urlReportExcel = $globalService.getUrlExcel('overide', params);
      });
    }

    vm.calculate_total = function(res){
      vm.total = _.reduce(res, function(memo, data){
        memo += data.amount;
        return memo;
      },0);
      //console.log(vm.total)
    }


    // column to sort
    vm.column = 'sno';

    // sort ordering (Ascending or Descending). Set true for desending
    vm.reverse = false;

    // called on header click
    vm.sortColumn = function (col) {
      vm.column = col;
      if (vm.reverse) {
        vm.reverse = false;
        vm.reverseclass = 'arrow-up';
      } else {
        vm.reverse = true;
        vm.reverseclass = 'arrow-down';
      }
    };

    // remove and change class
    vm.sortClass = function (col) {
      if (vm.column == col) {
        if (vm.reverse) {
          return 'fa-sort-amount-asc';
        } else {
          return 'fa-sort-amount-desc';
        }
      } else {
        return 'fa-sort';
      }
    }

  }


})();
