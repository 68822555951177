(function () {
  'use strict';
  angular
    .module('app')
    .component('newCustomerDirectory', {
      templateUrl: 'app/components/customerDirectory/newCustomerDirectory.html',
      controller: NewCustomerDirectoryController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

  NewCustomerDirectoryController.$inject = ['user', 'globalService'];

  function NewCustomerDirectoryController($user, $globalService) {
    var vm = this;
    vm.save = save;
    vm.send = false;
    vm.directory = {};

    vm.$onInit = function () {
      vm.currentUser = $user.getCurrentUser();
      $globalService.get_states()
      .then(function(res) {
        vm.states = res;
      })
      vm.agents = vm.resolve.agents;
    }

    function save() {
      vm.send = true;
      swal({
        title: 'Are you Sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.customerDirectories.save(vm.directory).$promise
            .then(function (res) {
              vm.send = false;
              vm.closure = res;
              vm.close();
              resolve(res.message);
            }, function (err) {
              vm.send = false;
              console.log(err.data.message)
              reject(err.data.message);
            });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) {
        swal('Success', data.value, 'success');
      }, function(err){
        console.log(err,'err')
        swal('Error', err, 'error');
      });
    }

    vm.cancel = function () {
      vm.dismiss({ $value: '' })
    }

    vm.findApplicationId = function (id) {
      vm.loading = true;
      if(id){
        $globalService.resApplications.get({id: id}).$promise
        .then(function(res){
          vm.loading = false;
          vm.directory.health_agent_id = res.user.id;
          vm.directory.health_code = res.application_id;
          vm.directory.health_agent_name = res.user.full_name;
        }, function(err){
          vm.loading = false;
          swal('Error',err.data.message,'error');
        })
      }else {
        vm.loading = false;
        vm.directory.health_agent_id = undefined;
        vm.directory.health_code = undefined;
        vm.directory.health_agent_name = undefined;
      }
    }

  }

})();
