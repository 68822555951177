(function(){
  'use strict';
  angular
  .module('app')
  .component('usedNpns', {
    templateUrl: 'app/components/used_npns/used_npns.html',
    controller: ReportUsedNPNSController
  });

  ReportUsedNPNSController.$inject = ['user', '$rootScope', 'globalService'];

  function ReportUsedNPNSController($user, $rootScope, $globalService) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.loadingData = false;

    vm.$onInit = function(){
      vm.currentUser = $user.getCurrentUser();
      vm.loadingData = true;
      $rootScope.$emit('uploadBreadCrum', {current: 'Reports Most Used NPN', before: 'Home'});
      $globalService.reports_npns_useds({limit: null})
      .then(function(res){
        vm.loadingData = false;
        vm.npnUseds = _.map(res, function(item){
          item.link_download_file = $globalService.reportExcel('report_npn_used_by_user', {user_id: item.id, token: vm.currentUser.token})
          return item;
        });
        console.log(vm.npnUseds)
      });
    }
  }


})();
