(function () {
  'use strict';
  angular
  .module('app')
  .component('proccessLink', {
    templateUrl: 'app/components/dashboard-agent/processLink.html',
    controller: ProcessLinkController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ProcessLinkController.$inject = ['$rootScope','globalService'];

  function ProcessLinkController($rootScope, $globalService) {
    var vm = this;
    vm.save = save;

    vm.$onInit = function() {
      vm.loading = true;
      vm.currentApplication = vm.resolve.currentApplication;

    }

    function save(){
      vm.send = true;
      vm.application = angular.copy(vm.currentApplication);
      vm.application.status = 2;
      vm.application.authorization_link = null
      swal({
        title: 'You are sure to process ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Accept',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.processApplication(vm.application)
            .then(function (res) {
              vm.send = false;
              vm.loading = false;
              vm.close({$value: res.data});
              Swal('Exito', res.message, 'success');
            }, function (err) {
              vm.loading = false;
              vm.close({$value: res.data});
              Swal('Error', err.data.message, 'warning');
            });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    }

    vm.cancel = function(){
      vm.dismiss({$value: ''})
    }


    vm.cancelApp = function(){
      vm.send = true;
      vm.application = angular.copy(vm.currentApplication);
      vm.application.status = 5;
      vm.application.authorization_link = null
      vm.application.unsubscribe_reason = 'Client did not authorize.'

      swal({
        title: 'You are sure to cancel ?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Accept',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.updateStatusApplication(vm.application)
            .then(function (res) {
              vm.send = false;
              vm.loading = false;
              vm.close({$value: res.data});
              Swal('Exito', res.message, 'success');
            }, function (err) {
              vm.loading = false;
              vm.close({$value: res.data});
              Swal('Error', err.data.message, 'warning');
            });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });

      
    }

    vm.cancel = function(){
      vm.dismiss({$value: ''})
    }
  }

})();
