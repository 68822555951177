(function(){
  'use strict';
  angular
  .module('app')
  .component('reportConflicts', {
    templateUrl: 'app/components/reports-conflicts/reports-conflicts.html',
    controller: ReportsApplicationConflictController
  });

  ReportsApplicationConflictController.$inject = ['user', '$rootScope', '$interval', '_', 'globalService','$uibModal','url'];

  function ReportsApplicationConflictController($user, $rootScope, $interval, _,$globalService, $uibModal, $url) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.loadingData = false;
 
    vm.$onInit = function(){
      $rootScope.$emit('uploadBreadCrum', {current: 'Reports Applications with conflicts', before: 'Home'});
      $globalService.reportAppsWithConflicts()
      .then(function(res){
        vm.applications = res;
      });
      // vm.urlReport = $globalService.reportPdf('report_managers_applications',vm.params);
    }

    vm.download = function(data){
      var new_data = _.reduce(data, function(memo, data) {
        memo.push({
          "CRM ID": data.id,
          "Main Applicant": data.main_applicant.name + " " + data.main_applicant.last_name,
          "Birthday": data.main_applicant.birthday,
          "Agent": data.agent,
          "CRM ID Conflict": data.conflict_info ? data.conflict_info.application_id : 'No data record',
          "Main Applicant Conflict": data.conflict_info ? data.conflict_info.name + " " + data.conflict_info.last_name : 'No data record',
          "Birthday Conflict": data.conflict_info ? data.conflict_info.birthday : 'No data record',
          "Agent Conflict": data.conflict_info ? data.conflict_info.agent : 'No data record'
        })
        return memo;
      },[])
      
      var name = moment().format('MMDDYYYHS_Conflicts');
      var a = document.createElement("a");
      var json_pre = angular.toJson(new_data);
      var fileName = name+".csv";
      
      var csv = Papa.unparse(json_pre);
 
      if (window.navigator.msSaveOrOpenBlob) {
        var blob = new Blob([decodeURIComponent(encodeURI(csv))], {
          type: "text/csv;charset=utf-8;"
        });
        navigator.msSaveBlob(blob, fileName);
      } else {
 
        a.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(csv);
        a.target = '_blank';
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      }
    }
  }

})();
