(function(){
  'use strict';
  angular
    .module('app')
    .component('requiredDocs', {
      templateUrl: 'app/components/modals/required-docs.html',
      controller: RequiredDocsController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

    RequiredDocsController.$inject = ['user', '$timeout', 'globalService', '_']

  function RequiredDocsController($user, $timeout, $globalService, _) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.listRequireDocuments = ['Income','Social Security Number', 'Status Migratory', 'Relocation','Lost Cover', 'Others'];

    vm.$onInit = function(){
      vm.currentApplication = vm.resolve.curreApplication;
    }

    vm.updateRequiredDoc = function(doc){
      swal({
        title: '¿Esta seguro?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $globalService.updateDocument(doc)
            .then(function(res) {
              doc.status = 1;
              doc.pretty_status = 'Enviado';
              swal('Exito',res.message,'success');
            }, function() {
              reject('Ha ocurrido un error');
            });
          });
        },
        allowOutsideClick: false,
      }).then(function(data) {});
    }

    vm.addRequireDocuments = function(){
      if(moment(vm.dateTo) < moment()){
        Swal('Stop','The date must be greater than the current date or it must not be empty','info');
        return
      }else{
        vm.currentApplication.required_documents = vm.currentApplication.required_documents ||[];
        vm.currentApplication.required_documents.push({
          required_document: vm.requiredDocument == 'Others' ? vm.otherRequiredDocument : vm.requiredDocument,
          who_require_it: vm.whoRequires,
          date_to: moment(vm.dateTo).format('MM/DD/YYYY'),
          status: 0,
          pretty_status: 'Pendiente',
        })
        vm.requiredDocument = undefined;
        vm.whoRequires = undefined;
        vm.dateTo = undefined;
      }
    }

    vm.sendDocuments = function(){
      if(vm.currentApplication.required_documents.length == 0){
        Swal('Stop','You must add at least one document','info');
        return
      }else{
        vm.loadingData = true;
        var data = {
          customer_application_id: vm.currentApplication.id,
          documents: _.reduce(vm.currentApplication.required_documents, function(memo, data){
            if(data.id == undefined){
              memo.push(data);
            }
            return memo;
          },[])
        }
  
        swal({
          title: '¿Esta seguro?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          showLoaderOnConfirm: true,
          preConfirm: function() {
            return new Promise(function(resolve, reject) {
              $globalService.requiredDocuments(data)
              .then(function(res){
                swal('Exito',res.message, 'success');
                vm.loadingData = false;
                vm.process(vm.currentApplication);
              }, function(err){
                swal('Error',res.data.message, 'danger');
                vm.loadingData = false;
              })
            });
          },
          allowOutsideClick: false,
        }).then(function(data) {});
      }
    }


  }


})();
