(function () {
  'use strict';
  angular
  .module('app')
  .component('modalProcess', {
    templateUrl: 'app/components/modals/modal_process.html',
    controller: ModalProcessController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalProcessController.$inject = ['$rootScope','globalService'];

  function ModalProcessController($rootScope, $globalService) {
    var vm = this;
  vm.save = save;
    vm.loading = false;
    vm.typesForms = [{code: 'client_consent_form', name: 'Client Consent Form'},{code: 'client_consent_form_broker_change', name: 'Client Consent Form Broker Change'}]

    vm.$onInit = function(){
      vm.crmid = vm.resolve.crmid;
      vm.currentApplication = vm.resolve.currentApplication;
      vm.params = {
        crmid: vm.crmid,
      }
      vm.findSignature();
    }

    vm.findSignature = function()  {
      vm.loading = true;
      $globalService.seachSign(vm.params)
      .then(function(res){
        vm.sign = res;
        vm.signed_forms = _.map(res.forms_signeds, function(data){
          data.pretty_signed_form = capitalize(data.signed_form.split('_').join(' '));
          return data;
        });
        vm.loading = false;
      }, function(err){
        vm.loading = false;
        vm.sign = err.data;
        swal('Warning',err.data.message,'warning')
      })
      
    }

    function save(){
      vm.close({$value: {user_npn: null, form_type: vm.params.form_type}})
    }

    vm.cancel = function(){
      vm.dismiss({$value: ''})
    }

    function capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

  }

})();
