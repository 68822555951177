(function () {
  'use strict';
  angular
  .module('app')
  .component('bajaReason', {
    templateUrl: 'app/components/modals/baja_reason.html',
    controller: BajaReasonController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  BajaReasonController.$inject = ['$rootScope','globalService'];

  function BajaReasonController($rootScope, $globalService) {
    var vm = this;
    vm.save = save;
    vm.loading = false;

    vm.$onInit = function(){
      vm.loading = true;
      $globalService.getReasons()
      .then(function(res){
        vm.reasonType = res;
        vm.loading = false;
      })
    }

    function save(){
      vm.send = true;
      if(vm.reasonSelected.key == 'otros' || vm.reasonSelected.key == 'otro_npn'){
        vm.close({$value: vm.reason + ': ' + vm.reasonJustify})
      }else{
        vm.close({$value: vm.reason})
      }
    }

    vm.cancel = function(){
      vm.dismiss({$value: ''})
    }

  }

})();
