(function(){
  'use strict';
  angular
    .module('app')
    .component('agentsSmsNotifications', {
      templateUrl: 'app/components/sms_notifications/sms_notifications.html',
      controller: SmsNotificationsController
    });

    SmsNotificationsController.$inject = ['user', '$stateParams', '_', '$rootScope', 'globalService', '$uibModal']

  function SmsNotificationsController($user, $stateParams, _, $rootScope, $globalService, $uibModal) {
    var vm = this;
    vm.loadingRequests = false;
    vm.disableAdd = false;
    vm.currentUser = $user.getCurrentUser();
    vm.agenciesTypes = [{id: 0, text: 'Main Agency'},{id: 1, text: 'Sub Agency'}]
    vm.per = 20;
    vm.currentPage = 1;
    vm.filter = undefined;

    vm.$onInit = function(){
      vm.loading = true;
      vm.user = { id: $stateParams.id, name: $stateParams.full_name.split('-').join(' ') }
      $rootScope.$emit('uploadBreadCrum', {current: 'SMS Notifications ', before: 'Users'});

      $globalService.smsNotifications.query({filter: vm.filter}).$promise
      .then(function(res){
        console.log(res)
        vm.data = res;
        vm.currentPage = vm.data.current_page;
        vm.loading = false;
        vm.loadingRequests = false;
      })
    }
    

    vm.showSms = function(sms){
      vm.loading = true;
      vm.currentConversation = sms;
      console.log(vm.currentConversation)
      $globalService.smsNotifications.get({id: sms.id}).$promise
      .then(function(res){
        vm.messages = res.data.messages;
        sms.read = true;
        vm.loading = false;
        document.getElementById(sms.crmid).classList.remove('new-sms');
        setTimeout(function(){
          var conversationBody = document.getElementsByClassName('conversation__body')[0]; 
          conversationBody.scrollTop = conversationBody.scrollHeight;
        },1)
      })
    }

    vm.pageChanged = function () {
      vm.send = true;
      vm.loadingData = true;
      vm.currentPage = vm.data.current_page;
      $globalService.smsNotifications.query({
        page: vm.currentPage,
        per: vm.per,
        read: vm.read,
        order: vm.order,
        phone_number: vm.phone_number,
        date: vm.date
      }).$promise
      .then(function (res) {
        vm.send = false;
        vm.data = res;
        vm.loadingData = false;
      })
    };

    vm.sendMessage = function(){
      vm.sendingSms = true;
      if(!vm.currentUser.permits_granted.send_sms.active){
        swal('Error','You do not have permission to send SMS','info');
        return
      }

      $globalService.sendSms({ phone_number: vm.currentConversation.phone_number,
                               message: vm.message,
                               crmid: vm.currentConversation.crmid,
                               conversation_id: vm.currentConversation.id,
                               user_id: vm.currentConversation.user_id })
      .then(function(res){
        vm.sendingSms = false;
        vm.message = '';
        vm.messages.push(res);
        setTimeout(function(){
          var conversationBody = document.getElementsByClassName('conversation__body')[0]; 
          conversationBody.scrollTop = conversationBody.scrollHeight;
        },1)
      }, function(err){
        swal('Error',err.data.message,'error');
      })
    }

    vm.showSmsPanel = function(){
      var modal = $uibModal.open({
        animation: true,
        component: 'smsPanel',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
      });

      modal.result.then(function (res) {
        vm.$onInit();
      });
    }

    $rootScope.$on('newSms', function(ev, message){
      if(message.conversation_id == vm.currentConversation.id){
        vm.messages.push({
          body: message.sms,
          conversation_id: message.conversation_id,
          id: message.id,
          origin: message.origin,
          read: true,
          order: message.order
        });
        setTimeout(function(){
          var conversationBody = document.getElementsByClassName('conversation__body')[0]; 
          conversationBody.behavior = 'smooth';
          conversationBody.scrollTop = conversationBody.scrollHeight;
        },1)
      }
    })
  }

})();
