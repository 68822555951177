(function(){
  'use strict';
  angular
  .module('app')
  .component('payrollSummaries', {
    templateUrl: 'app/components/payroll-summaries/payroll-summaries.html',
    controller: PayrollSummaries
  });

  PayrollSummaries.$inject = ['$uibModal','toastr', 'url', '$interval', '_','globalService','$rootScope'];

  function PayrollSummaries($uibModal, toastr, $url, $interval, _, $globalService, $rootScope) {
    var vm = this;
    vm.loadingData = true;

    vm.date_from = new Date(moment(vm.date_from).startOf('month').toDate());
    vm.date_to = new Date(moment(vm.date_from).endOf('month').toDate());

    vm.$onInit = function(){
      $rootScope.$emit('uploadBreadCrum', {current: 'Payroll Summaries', before: 'Home'});
      $globalService.payrollSummaries.query().$promise
      .then(function(res){
        vm.summaries = res;
        vm.loadingData = false;
      })
    }

    vm.consultSummaries = function(){     
      vm.loadingData = true;
      $globalService.payrollSummaries.query({date_from: moment(vm.date_from).format('YYYY-MM-DD'), date_to: moment(vm.date_to).format('YYYY-MM-DD')}).$promise
      .then(function(res){
        vm.summaries = res;
        vm.loadingData = false;
      })
    }

    vm.retry = function(id){
      vm.loadingData = true;
      swal({
        title: 'Are you sure to retry this payroll summary?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.retryPayrollSummary(id)
              .then(function (res) {
                resolve(res);
                vm.$onInit();
              }, function () {
                reject('Ha ocurrido un error');
              });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) {
        if(data.value){
          swal('Exito', data.value.message, 'success');
        }
        vm.loadingData = false;
      });
    }

    
  }


})();
