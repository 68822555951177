(function () {
  'use strict';
  angular
    .module('app')
    .component('spouseApplicantForm', {
      templateUrl: 'app/components/manage-applications/spouse_applicant_form.html',
      controller: SpouseApplicantFormController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

  SpouseApplicantFormController.$inject = ['$rootScope', 'globalService', 'user'];

  function SpouseApplicantFormController($rootScope, $globalService, $user) {
    var vm = this;
    vm.save = save;

    vm.$onInit = function () {
      vm.currentUser = $user.getCurrentUser();
    }


    function save() {
      vm.currentApplication.spouse.birthday = moment(vm.currentApplication.spouse.birthday).format('MM/DD/YYYY')
      swal({
        title: 'Are you sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Acept',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.updateSpouseApplicant(vm.currentApplication)
              .then(function (res) {
                $rootScope.$emit('initAllAplications');
                swal('Exito', res.message, 'success');
              }, function () {
                reject('Ha ocurrido un error');
              });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) {
        vm.$onInit();
      });
    }

    $rootScope.$on('setCurrentApplicant', function (event, data) {
      vm.currentApplication = data.current;
      if(vm.currentApplication.spouse){
        vm.currentApplication.spouse.age = moment(vm.currentApplication.spouse.birthday, 'MM/DD/YYYY').month(0).from(moment().month(0)).split(' ')[0];
      }
    })

    $rootScope.$on('updateSpouseApplicant', function (event, data) {
      save();
    })

  }

})();
