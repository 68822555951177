
(function(){
  'use strict';
  angular
    .module('app')
    .component('agentRates', {
      templateUrl: 'app/components/agent-rates/agent-rates.html',
      controller: AgentRatesController
    });

    AgentRatesController.$inject = ['user', '$stateParams', '_','$rootScope','globalService']

  function AgentRatesController($user, $stateParams, _, $rootScope,$globalService) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();

    vm.$onInit = function(){
      vm.loading = true;
      $rootScope.$emit('uploadBreadCrum', {current: 'Agent Rates', before: 'Company'});
      $globalService.get_states()
      .then(function(res){
        vm.states = res;
      })
      $globalService.allCompanyRates()
      .then(function(companyRates){
        $globalService.users.get({id: $stateParams.id}, function(res){
          vm.loading = false;
          vm.user = res;
          if(_.isEmpty(vm.user.agent_rates)){
            vm.user.agent_rates = _.reduce(companyRates, function(memo, data){
              memo.push({
                amount: data.amount,
                amount_new: data.amount_new,
                amount_renewed: data.amount_renewed,
                company_id: data.company_id,
                company_name: data.company_name,
                diff_amounts: data.diff_amounts,
                is_percent: data.is_percent,
                diff_amount_states: data.diff_amount_states,
                comission_states: data.comission_states,
                overide: data.overide
              })
              return memo;
            },[])

            
          }else{
            $globalService.get_states()
            .then(function(res){
              vm.states = res;
              //console.log(vm.states)
              _.forEach(vm.user.agent_rates, function(rate){
                if(rate.hasOwnProperty('user_id')){
                  if(rate.diff_amount_states){
                    
                    _.map(rate.comission_states, function(comission){
                      comission.statesSelected = [];
                      //console.log(comission)
                      _.forEach(comission.states_id, function(id){
                        comission.statesSelected.push(_.find(vm.states, {id: id}))
                      })
                      return comission
                    })
                    //console.log(rate.comission_states)
                  }
                }
              })
            })
            _.map(vm.user.agent_rates, function(data){
              data.company_name = _.find(companyRates, {company_id: data.company_id}).company_name;
              return data;
            })
            var ratesDiff = _.reduce(companyRates, function(memo,data){
              var exist = _.find(vm.user.agent_rates, {company_id: data.company_id});
              if(!exist){
                memo.push(data);
              }
              return memo;
            },[])
            vm.user.agent_rates = vm.user.agent_rates.concat(ratesDiff);
            
          }
        })
      })
    }


    vm.save = function(data, replicate){
      vm.send = true;
      _.map(data.comission_states, function(data){
        data.states_id = _.pluck(data.statesSelected, 'id');
        return data;
      })

      data.replicate = replicate;

      if(data.user_id){
        $globalService.agentRates.update({user_id: vm.user.id, id: data.id}, data, function (res) {
          vm.$onInit();
          vm.send = false;
          Swal('Exito', res.message, 'success');
          data = res.data;
        }, function (err) {
          vm.send = false;
          Swal('Error', err.data.message, 'warning');
        });
      }else{
        $globalService.agentRates.save({user_id: vm.user.id}, data, function (response) {
          vm.$onInit();
          vm.send = false;
          _.map(vm.user.agent_rates, function(data){
            if(data.company_id == response.data.company_id){
              data.user_id = response.data.user_id;
            }
          })
          Swal('Exito', response.message, 'success');
        }, function (err) {
          vm.send = false;
          Swal('Error', err.data.message, 'warning');
        });
      }
    }

    vm.close = function(rate){
      var old_rate = _.findWhere(vm.user.agent_rates, {show: true})
      if (old_rate){
        old_rate.show = false;
      }
      rate.show = !rate.show;
    }
  }


})();
