angular
  .module('app')
  .filter('imageUrl', imageUrl);

function imageUrl(url,DOMAIN_URL) {
  console.log(DOMAIN_URL)
  return function (relativePath, defaultPath) {
    // transform the item and return it
    if (relativePath === null || relativePath == undefined) {// cargando o image-null
      return defaultPath || '/images/default-avatar.png';
    }
    if (relativePath[0] === 'h') {// si comienza por http es absoluta
      return relativePath || (url.getFrontendUrl() + relativePath);
    }
    return DOMAIN_URL + relativePath;
  };
}
