(function () {
  'use strict';
  angular
  .module('app')
  .component('showDetails', {
    templateUrl: 'app/components/applications/showDetails.html',
    controller: ShowDetailsModalController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ShowDetailsModalController.$inject = ['$sce','globalService','user'];

  function ShowDetailsModalController($sce, $globalService, $user) {
    var vm = this;
    vm.application = {};
    vm.showOthers = false;
    vm.confirm = confirm;
    vm.currentUser = $user.getCurrentUser();


    vm.$onInit = function() {
      vm.isEnrollmentPeriod = moment().format('MM') == 11 ? true : false;
      vm.type_insurance_companies = vm.resolve.type_insurance_companies;
      vm.applicationsType = vm.resolve.applicationsType;
      vm.allCompanies = vm.resolve.allCompanies;
      vm.agents = vm.resolve.agents;
      vm.companyRegisterType = [{id: 'new_for_company', name: 'New'}, {id: 'renewal_for_company', name: 'Renewal'}];
      vm.cmsRegisterType = [{id: 'new_for_cms', name: 'New'}, {id: 'renewal_for_cms', name: 'Renewal'}];
      vm.agentRegisterType = [{id: 'new_for_agent', name: 'New'}, {id: 'renewal_for_agent', name: 'Renewal'}];
      vm.insurancePeriods = [moment().format('YYYY'), moment().add(1, 'years').format('YYYY')];

      if(vm.resolve.application.id){
        vm.application = vm.resolve.application;
        vm.companySelected = _.find(vm.allCompanies, {id: vm.application.insurance_data.company.id});
        vm.productSelected = _.find(vm.companySelected.products, {id: vm.application.insurance_data.company_product.id});
        vm.typePlanSelected = _.find(vm.productSelected.company_product_type, {id: vm.application.insurance_data.company_product_type.id});
        vm.planSelected = _.find(vm.typePlanSelected.company_product_plans, {id: vm.application.insurance_data.company_product_plan.id});
        // vm.applicationTypeSelected = _.find(vm.applicationsType, {id: 'Renewal'});
        vm.agentSelected = _.find(vm.resolve.agents, { id: vm.resolve.application.user_id });
        vm.cmsRegisterTypeSelected = _.find(vm.cmsRegisterType, { id: vm.resolve.application.cms_register_type})
        vm.agentRegisterTypeSelected = _.find(vm.agentRegisterType, { id: vm.resolve.application.agent_register_type})
        vm.companyRegisterTypeSelected = _.find(vm.companyRegisterType, { id: vm.resolve.application.company_register_type})
        vm.insurancePeriodSelected = _.find(vm.insurancePeriod, { id: vm.resolve.application.insurance_period})
      }
      if(vm.allCompanies){
        vm.allCompanies.push({
          name: 'Others',
          id: 0
        })
      }
      console.log(vm.application)
      setEfectyDate();
    }


    function confirm(){
      vm.application.company_id = vm.companySelected.id;
      if(vm.application.cms_register_type == undefined){
        swal('Stop!','You must select a CMS register type', 'warning')
        return;
      }
      if(vm.application.company_register_type == undefined){
        swal('Stop!','You must select a Company register type', 'warning')
        return;
      }
      if(vm.application.agent_register_type == undefined){
        swal('Stop!','You must select an Agent register type', 'warning')
        return;
      }
      if(vm.application.insurance_period == undefined){
        swal('Stop!','You must select the insurance period', 'warning')
        return;
      }
      vm.close({$value: vm.application});
    }

    vm.calculatePrima = function(){
      if(parseFloat(vm.application.subsidy) > parseFloat(vm.application.cost)){
        vm.application.beforehand = 0;
      }else{
        vm.application.beforehand = parseFloat(vm.application.cost) - parseFloat(vm.application.subsidy);
      }
    }

    vm.setAllNew = function(){
      if(vm.cmsRegisterTypeSelected.id == 'new_for_cms'){
        vm.agentRegisterTypeSelected = _.find(vm.agentRegisterType, { id: 'new_for_agent'})
        vm.companyRegisterTypeSelected = _.find(vm.companyRegisterType, { id: 'new_for_company'})
        vm.application.cms_register_type = vm.cmsRegisterTypeSelected.id;
        vm.application.company_register_type = vm.companyRegisterTypeSelected.id;
        vm.application.agent_register_type = vm.agentRegisterTypeSelected.id;
      }

    }

    vm.selectCurrentPlan = function(){
      vm.application.current_plan = vm.currentPlanSelected.name;
      if(vm.currentPlanSelected.id == 0){
        vm.showOthers = true;
      }

    }

    function setEfectyDate(){
      var date = moment();

      if(parseInt(date.format('DD')) < 25){
        if(parseInt(date.format('MM')) == 11){
          vm.application.efecty_date = date.add(2, 'M').startOf('month').format('MM-DD-YYYY');
        }else{
          vm.application.efecty_date = date.add(1, 'M').startOf('month').format('MM-DD-YYYY');
        }
      }else{
        vm.application.efecty_date = date.add(2, 'M').startOf('month').format('MM-DD-YYYY');
      }
      
    }

    vm.hide = function(){
      vm.dismiss({$value: 'Dismiss'});
    }

  }

})();
