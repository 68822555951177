(function(){
  'use strict';
  angular
  .module('app')
  .component('reportDocumentsRequireds', {
    templateUrl: 'app/components/reports/reports-documents_requireds.html',
    controller: ReportsDocumentsRequiredsController
  });

  ReportsDocumentsRequiredsController.$inject = ['user', '$rootScope', '$interval', '_', 'globalService','$uibModal','url'];

  function ReportsDocumentsRequiredsController($user, $rootScope, $interval, _,$globalService, $uibModal, $url) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.showCurrentApplication = false;
    vm.loadingData = false;
    vm.showCustomers = true;

    vm.$onInit = function(){
      vm.loadingData = true;
      $rootScope.$emit('uploadBreadCrum', {current: 'Reports de Documentos Requeridos', before: 'Home'});
      $globalService.getAllAgents.query().$promise.then(function(res){
        vm.agents = res;
        vm.loadingData = false;
      })
      vm.urlReport = $globalService.reportPdf('report_customer_apps_with_documents_requireds');
    }


    vm.consultDocuments = function() {
      vm.loadingData = true;
      var data;
      if(vm.customer_application_id){
        data = {customer_application_id: vm.customer_application_id}
      }
      if(vm.agent){
        data = {user_id: vm.agent.id}
      }
      
      $globalService.reportDocumentsRequireds(data)
      .then(function(res){
        vm.loadingData = false;
        vm.applications = res;
        get_customer()
      }, function(err){
        vm.loadingData = false;
        vm.applications = [];
        swal('Error', err.data.message, 'error');
      });
    }


    function get_customer(){
      vm.customers = _.pluck(vm.applications, 'customer')
    }

    vm.showDocuments = function (customer) {
      vm.currentCustomer = customer;
      vm.showCustomers = false;
    }


    vm.download = function(){
      var new_data = _.reduce(vm.applications, function(memo, data) {
        _.forEach(data.customer.documents, function(document) {
          memo.push({
            "CRMID": data.customer.customer_application_id,
            "Customer": data.customer.customer.name + ' ' + data.customer.customer.last_name,
            "Phone": data.customer.customer.phone,
            "Document Required": document.required_document,
            "Who Require It": document.who_require_it,
            "Due Date": document.date_to,
            "status": document.status
          });  
        })
        return memo
      },[])

      var name = 'documents_required_'+moment().format('llll');
      var a = document.createElement("a");
      var json_pre = angular.toJson(new_data);
      var fileName = name+".csv";
      
      var csv = Papa.unparse(json_pre);
 
      if (window.navigator.msSaveOrOpenBlob) {
        var blob = new Blob([decodeURIComponent(encodeURI(csv))], {
          type: "text/csv;charset=utf-8;"
        });
        navigator.msSaveBlob(blob, fileName);
      } else {
 
        a.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(csv);
        a.target = '_blank';
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      }
    }
  }


})();
