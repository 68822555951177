(function(){
  'use strict';
  angular
    .module('app')
    .component('overlay', {
      templateUrl: 'app/components/overlay/overlay.html',
      controller: OverlayController
    });

    OverlayController.$inject = []

  function OverlayController() {
    var vm = this;
  }


})();
