(function(){
  'use strict';
  angular
  .module('app')
  .component('reportTaxSaving', {
    templateUrl: 'app/components/report-tax-savings/reports-tax-savings.html',
    controller: ReportTaxSavingsController
  });

  ReportTaxSavingsController.$inject = ['user', '$rootScope', '$window', '_', 'globalService','$uibModal','url'];

  function ReportTaxSavingsController($user, $rootScope, $window, _,$globalService, $uibModal, $url) {
    var vm = this;
    vm.loadingRequests = false;
    
    vm.showCurrentApplication = false;
    vm.loadingData = false;
    
    vm.$onInit = function(){
      vm.currentUser = $user.getCurrentUser();
      vm.date_from = moment().startOf('year').format('YYYY-MM-DD')
      vm.date_to = moment().endOf('year').format('YYYY-MM-DD')
      vm.params = {token: vm.currentUser.token};
      vm.params.date_from = moment(vm.date_from).startOf('year').format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.date_to).endOf('year').format('YYYY-MM-DD');      
      $rootScope.$emit('uploadBreadCrum', {current: 'Reports Applications', before: 'Home'});
      
      vm.consultReport();
    }

    vm.consultReport = function(){
      vm.loadingData = true;
      vm.params.date_from = moment(vm.date_from).format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.date_to).format('YYYY-MM-DD');
      $globalService.reportTaxSaving(vm.params)
      .then(function(res){
        vm.loadingData = false;
        vm.reports = _.map(res,function(data){
          data.total = Math.round(data.total);
          return data;
        });
        vm.calculate_total(res);
        vm.print_pdf_url = $globalService.reportPdf('tax_savings',vm.params);
      });
    }

    vm.calculate_total = function(res){
      vm.total_tax_saving = _.reduce(res, function(memo, data){
        memo += data.total;
        return memo;
      },0);
    }

    vm.printPdf = function(user_id) {
      vm.newParams = angular.copy(vm.params)
      vm.newParams.user_id = user_id;
      var url = $globalService.reportPdf('tax_savings',vm.newParams);
      $window.open(url, '_blank');
    }

    // column to sort
    vm.column = 'sno';

    // sort ordering (Ascending or Descending). Set true for desending
    vm.reverse = false;

    // called on header click
    vm.sortColumn = function (col) {
      vm.column = col;
      if (vm.reverse) {
        vm.reverse = false;
        vm.reverseclass = 'arrow-up';
      } else {
        vm.reverse = true;
        vm.reverseclass = 'arrow-down';
      }
    };

    // remove and change class
    vm.sortClass = function (col) {
      if (vm.column == col) {
        if (vm.reverse) {
          return 'fa-sort-amount-asc';
        } else {
          return 'fa-sort-amount-desc';
        }
      } else {
        return 'fa-sort';
      }
    }
  }


})();
