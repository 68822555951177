(function () {
  'use strict';
  angular
    .module('app')
    .directive('loadTree', loadTree);

  loadTree.$inject = ['$state','$timeout'];

  function loadTree($state, $timeout) {


    return {
      link: link
    };

    function link(scope, el, attrs, ctrl) {
      $(el).click(function(e){
        //console.log($(el).find('div.collapse'))


        var a = $(e).find('div.collapse');
        if(a.hasClass('show')){
          var div = $(el).find('div.collapse');
          div.toggleClass('show');
          // div.slideDown("slow");
        }else{
          var div = $(el).find('div.collapse');
          div.toggleClass('show');
          // div.slideUp("slow");
        }
      });
    }
  }
})();
