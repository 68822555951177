(function () {
  'use strict';
  angular
  .module('app')
  .component('changeNpn', {
    templateUrl: 'app/components/modals/change_npn.html',
    controller: ChangeNpnController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ChangeNpnController.$inject = ['$rootScope','globalService','user'];

  function ChangeNpnController($rootScope, $globalService, $user) {
    var vm = this;
    vm.save = save;
    vm.currentUser = $user.getCurrentUser();
    vm.loading = true;

    vm.$onInit = function() {
      vm.currentApplication = vm.resolve.currentApplication;
      $globalService.users.query().$promise
      .then(function(res){
        vm.loading = false;
        vm.agents  = res;  
        console.log(vm.currentApplication.user_npn)
        console.log(_.findWhere(vm.agents, {id: vm.currentApplication.user_npn}))
        vm.currentNpn = _.findWhere(vm.agents, {id: vm.currentApplication.user_npn});
      })
    }


    function save(){
      vm.send = true;
      var data = {
        id: vm.currentApplication.id,
        user_npn: vm.agentSelected.id,
      }
      $globalService.changeNpn(data)
      .then(function(res){
        vm.send = false;
        Swal('Exito',res.message,'success');
        vm.close({$value: res.data})
      }, function(err){
        Swal('Error',err.data.message,'warning');
      })
    }

    vm.cancel = function(){
      vm.dismiss({$value: ''})
    }

    $rootScope.$on('setCurrentApplicant', function(event, data){
      vm.currentApplication = data.current;
      // //console.log(vm.currentApplication.dependents)
    })

  }

})();
