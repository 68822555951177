(function () {
  'use strict';
  angular
    .module('app')
    .directive('toggleMenu', toggleMenu);

  toggleMenu.$inject = ['$state','$timeout'];

  function toggleMenu($state, $timeout) {


    return {
      link: link
    };

    function link(scope, el, attrs, ctrl) {
      $(el).click(function(){
        $('body').toggleClass('sidebar-mini');
        $('html').toggleClass('nav-open')
        // //console.log($(el).find('a.nav-lick'))
        // $(el).toggleClass('collapsed');
        // if(el.hasClass('collapsed')){
        //   var div = $(el).find('div.collapse')
        //   div.slideDown("slow")
        // }else{
        //   var div = $(el).find('div.collapse')
        //   div.slideUp("slow")
        // }
      });
    }
  }
})();
