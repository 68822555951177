(function () {
  'use strict';
  angular
  .module('app')
  .component('forgotPassword', {
    templateUrl: 'app/components/forgot-password/forgot-password.html',
    controller: ForgotPasswordController
  });

  ForgotPasswordController.$inject = ['authService', 'user', '$window','$rootScope','$state'];

  function ForgotPasswordController($authService, $user, $window, $rootScope, $state) {
    var vm = this;
    vm.send = false;
    vm.step1 = true;
    vm.step2 = false;
    vm.step3 = false;

    vm.$onInit = function(){

    }

    vm.nexStep = function(){
      vm.send = true;
      $authService.getCodeToResetPassword({email: vm.email})
      .then(function(res){
        vm.send = false;
        vm.step1 = false;
        vm.step2 = true;
        $window.swal('Code Sent',res.message, 'success')
      }, function(err){
        vm.send = false;
        vm.step1 = true;
        $window.swal('Error',err.data.message, 'error');
      })

    }

    vm.sendToverifyCode = function(){
      vm.send = true;
      $authService.verifyCode({code: vm.code, email: vm.email})
      .then(function(res){
        vm.step2 = false;
        vm.step3 = true;
        vm.send = false;
        $window.swal('Exito',res.message, 'success')
      }, function(err){
        vm.step2 = true;
        vm.send = false;
        $window.swal('Error',err.data.message, 'error');
      })
    }

    vm.updatePassword = function(){
      vm.send = true;
      $authService.updatePassword({password: vm.password, email: vm.email})
      .then(function(res){
        vm.send = false;
        $state.go('login')
        $window.swal('Exito',res.message, 'success');
      }, function(err){
        vm.send = false;
        vm.step1 = true;
        $window.swal('Error',err.data.message, 'error');
      })
    }

  }

})();
