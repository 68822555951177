(function () {
  'use strict';
  angular
  .module('app')
  .component('userModal', {
    templateUrl: 'app/components/users/user_modal.html',
    controller: UserModalController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  UserModalController.$inject = ['$sce','globalService'];

  function UserModalController($sce, $globalService) {
    var vm = this;
    vm.save = save;
    vm.showFirsTab = true;
    vm.showSecondTab = false;
    vm.setUserPermissions = setUserPermissions;
    vm.confirmPermissions = confirmPermissions;
    vm.childsSelected = [];
    vm.assignedAgentsSelected = [];
    vm.user;
    
    vm.$onInit = function() {
      vm.users = vm.resolve.users;
      vm.sections = _.map(vm.resolve.sections, function(data){
        data.selected = false;
        return data;
      });
      vm.user_types = vm.resolve.user_types;
      vm.user = vm.resolve.user;
      vm.agents = vm.resolve.agents;

      if(vm.user){
        vm.typeSelected = _.find(vm.user_types, {id: vm.user.user_type_id});
        _.forEach(vm.sections, function(data){
          if(vm.user.permits_granted[data.name] && vm.user.permits_granted[data.name].active){
            data.selected = true;
          }
        })
        if(vm.user.child_ids.length > 0){
          _.each(vm.user.child_ids, function(id){
            vm.childsSelected.push(_.findWhere(vm.users, {id: id}))
          })
        }
        if(vm.user.assigned_agents.length > 0){
          _.each(vm.user.assigned_agents, function(id){
            vm.assignedAgentsSelected.push(_.findWhere(vm.users, {id: id}));
            
            // console.log(_.findWhere(vm.agents, {id: id}))
            // 
          });
        } 
      }
    }

    function confirmPermissions(){
      vm.user.user_type_id = vm.typeSelected.id;
      vm.showSecondTab = true;
      vm.showFirsTab = false;
    }


    function save(){
      vm.user.permissions = _.reduce(vm.sections, function(memo, data){
        if(data.selected){
          memo.push({
            active: true,
            section_id: data.id
          })
        }
        return memo;
      },[])
      if(vm.user.id){
        $globalService.users.update({id: vm.user.id}, vm.user, function(res){
          vm.send = false;
          Swal('Exito',res.message,'success');
          vm.close({$value: res.data});
        }, function(err){
          Swal('Error',err.data.errors,'error');
          vm.cancel({$value: ''});
        })
      }else{
        $globalService.users.save(vm.user,
          function(res){
            vm.send = false;
            Swal('Exito',res.message,'success');
            vm.close({$value: res.data})
          }, function(err){
            Swal('Error',err.data.errors,'warning');
            vm.cancel({$value: ''});
          });
      }

    }

    function setUserPermissions(){
      vm.user.user_type_id = vm.typeSelected.id;
      _.forEach(vm.sections, function(data){
        data.selected = false;
      })
      _.forEach(_.filter(vm.sections, {type_section: vm.typeSelected.permission_type}), function(data){
        //console.log(data);
        data.selected = true;
      })
    }

    vm.setAssignedAgentsSelected = function(){
      vm.user.assigned_agents = _.pluck(vm.assignedAgentsSelected, 'id');
      console.log(vm.user)
    }

    vm.setChildsIds = function(){
      vm.user.child_ids = _.pluck(vm.childsSelected, 'id');
      console.log(vm.user)
    }

    vm.selectAllPermissions = function(){
      _.forEach(vm.sections, function(data){
        data.selected = true;
      })
    }

  }

})();
