(function () {
  'use strict';
  angular
    .module('app')
    .component('closureModal', {
      templateUrl: 'app/components/closure/modalClosure.html',
      controller: GeneralClosureController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

  GeneralClosureController.$inject = ['$rootScope', 'globalService'];

  function GeneralClosureController($rootScope, $globalService) {
    var vm = this;
    vm.save = save;
    vm.consult = consult;
    vm.send = false;

    vm.$onInit = function () {
    }

    function save() {
      vm.send = true;
      swal({
        title: 'Are you Sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.closures.save({make_close: true},
              function (res) {
                vm.send = false;
                vm.closure = res;
                vm.close();
                resolve();
              }, function () {
                vm.send = false;
                reject('Ha ocurrido un error');
              });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    }

    function consult() {
      vm.send = true;
      $globalService.closures.save({},
        function (res) {
          vm.send = false;
          vm.closure = res;
        }, function(err) {
          vm.send = false;
          Swal('info',err.data.message,'info')
        });
    }

    vm.cancel = function () {
      vm.dismiss({ $value: '' })
    }

  }

})();
