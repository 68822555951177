(function(){
  'use strict';
  angular
  .module('app')
  .component('reportApplications', {
    templateUrl: 'app/components/reports/reports-application.html',
    controller: ReportsApplicationController
  });

  ReportsApplicationController.$inject = ['user', '$rootScope', '$interval', '_', 'globalService','$uibModal','url'];

  function ReportsApplicationController($user, $rootScope, $interval, _,$globalService, $uibModal, $url) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.showCurrentApplication = false;
    vm.loadingData = false;
    var date = new Date();
    vm.date_from = new Date(date.getFullYear(), date.getMonth(),1);
    vm.date_to = new Date(date.getFullYear(), date.getMonth() +1,0);
    vm.params = {
      date_from: moment().startOf('month').format('MM/DD/YYYY'),
      date_to: moment().endOf('month').format('MM/DD/YYYY'),
    }
    vm.tabPanel = {
      personalData: true,
      insuranceData: false,
      billingInformation: false,
      requiredData: false,
    }

    vm.$onInit = function(){
      vm.params.date_from = moment(vm.params.date_from).format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.params.date_to).format('YYYY-MM-DD');
      $rootScope.$emit('uploadBreadCrum', {current: 'Reports Applications', before: 'Home'});
      $globalService.reportApplications(vm.params)
      .then(function(res){
        vm.applications = res;
        vm.calculate_total(res)
      });
      $globalService.companies.query(function(res){
        vm.insurances = res;
      })
      $globalService.getAllManagers.query(function(res){
        vm.managers = res;
      })
      vm.urlReport = $globalService.reportPdf('report_managers_applications',vm.params);
    }


    vm.consultApplications = function(){
      vm.loadingData = true;
      vm.params.date_from = moment(vm.date_from).format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.date_to).format('YYYY-MM-DD');
      vm.params.report_complete = false;
      $globalService.reportApplications(vm.params)
      .then(function(res){
        vm.loadingData = false;
        vm.applications = res;
        vm.calculate_total(res);
        vm.urlReport = $globalService.reportPdf('report_managers_applications',vm.params);
      });
    }

    vm.consultApplicationsAll = function(){
      vm.loadingData = true;
      vm.params.report_complete = true;
      $globalService.reportApplications(vm.params)
      .then(function(res){
        vm.loadingData = false;
        vm.applications = res;
        vm.calculate_total(res);
        vm.urlReport = $globalService.reportPdf('report_managers_applications',vm.params);
      }); 
    }

    vm.calculate_total = function(res){
      vm.total = _.reduce(res, function(memo, data){
        memo.total_clients += data.clients_and_insureds.clients;
        memo.total_insureds += data.clients_and_insureds.insureds;
        return memo;
      },{total_clients: 0, total_insureds: 0});
    }
  


    // column to sort
    vm.column = 'sno';

    // sort ordering (Ascending or Descending). Set true for desending
    vm.reverse = false;

    // called on header click
    vm.sortColumn = function (col) {
      vm.column = col;
      if (vm.reverse) {
        vm.reverse = false;
        vm.reverseclass = 'arrow-up';
      } else {
        vm.reverse = true;
        vm.reverseclass = 'arrow-down';
      }
    };

    // remove and change class
    vm.sortClass = function (col) {
      if (vm.column == col) {
        if (vm.reverse) {
          return 'fa-sort-amount-asc';
        } else {
          return 'fa-sort-amount-desc';
        }
      } else {
        return 'fa-sort';
      }
    }
  }

})();
