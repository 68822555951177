(function(){
  'use strict';
  angular
  .module('app')
  .component('cargaMasiva', {
    templateUrl: 'app/components/customerDirectory/cargaMasiva.html',
    controller: CargaMasivaController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  CargaMasivaController.$inject = ['$uibModal','user', 'url', '$interval', '_','globalService','$rootScope'];

  function CargaMasivaController($uibModal, $user, $url, $interval, _, $globalService, $rootScope) {
    var vm = this;
    vm.loadingResumen = true;
    vm.user = $user.getCurrentUser();
    vm.dzOptions = {
      url : $url.getApiUrl('customer_directory/masive_upload'),
      paramName : 'file',
      maxFilesize : '1',
      // acceptedFiles : 'application/xlsx',
      addRemoveLinks : true,
      headers: {
        'Authorization': vm.user.token
      }
    };

    vm.dzCallbacks = {
      'addedfile' : function(file){
        vm.newFile = file;
      },
      'success' : function(file, response){
        vm.loadingResumen = false;
        swal('info', response.message, 'info');
      },
  };
 
    
  }


})();
