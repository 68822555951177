(function () {
  'use strict';
  angular
  .module('app')
  .component('memberId', {
    templateUrl: 'app/components/modals/member_id.html',
    controller: MemberIdController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  MemberIdController.$inject = ['$rootScope','globalService'];

  function MemberIdController($rootScope, $globalService) {
    var vm = this;
    vm.save = save;

    vm.$onInit = function() {
      vm.currentApplication = vm.resolve.currentApplication;
      if (vm.currentApplication.call_date){
        vm.currentApplication.call_date = moment(vm.currentApplication.call_date).toDate();
      }
    }


    function save(){
      vm.send = true;
      if(vm.currentApplication.secondary){
        vm.currentApplication.secondary = vm.currentApplication.secondary.split(',');
      }
      var title = _.isNull(vm.currentApplication.member_id) ? 'Por favor espere...' : 'Confirme los datos';
      var text = _.isNull(vm.currentApplication.member_id) ? '¿Esta seguro de enviar el member id vacio?' : 'Confirme que el member id sea correcto: '+vm.currentApplication.member_id;
      vm.currentApplication.call_date = moment(vm.currentApplication.call_date).format('YYYY-MM-DD');
      swal({
        title: title,
        text: text,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $globalService.setMemberId(vm.currentApplication)
            .then(function(res){
              vm.send = false;
              Swal('Exito',res.message,'success');
              // vm.close({$value: res.data})
            }, function(err){
              Swal('Error',err.data.message,'warning');
              vm.cancel({$value: ''});
            })
          });
        },
        allowOutsideClick: false,
      }).then(function(data) {});

      
    }

    vm.cancel = function(){
      vm.dismiss({$value: ''})
    }

    $rootScope.$on('setCurrentApplicant', function(event, data){
      vm.currentApplication = data.current;
      // //console.log(vm.currentApplication.dependents)
    })

  }

})();
