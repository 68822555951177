

(function () {
  'use strict';
  angular
    .module('app')
    .component('healthApplicationV2', {
      templateUrl: 'app/components/health_application_v2/health_application_v2.html',
      controller: HealthApplicationController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

  HealthApplicationController.$inject = ['$window', 'globalService', 'user','FORMS_URL', '$sce','$stateParams','DOMAIN_URL'];

  function HealthApplicationController($window, $globalService, $user, FORMS_URL, $sce, $stateParams, DOMAIN_URL) {
    var vm = this;

    vm.$onInit = function(){
      vm.currentUser = $user.getCurrentUser();

      if($stateParams.id){
        $window.location.href = FORMS_URL + '/insurance-application/health/'+$stateParams.id+'/edit?redirect='+DOMAIN_URL+'&token='+vm.currentUser.token_v2;
      }else{
        $window.location.href = FORMS_URL + '/insurance-application?redirect='+DOMAIN_URL+'&token='+vm.currentUser.token_v2;
      }
      
    }
    
    vm.trustSrc = function(src) {
      return $sce.trustAsResourceUrl(src);
    }

    function redirectToPath(res) {
      switch (vm.currentUser.pretty_type) {
        case "Manager":
          $state.go('dashboardManager');
          break;
        case "Administrator":
          $state.go('home');
          break;
        case "Agente":
          $state.go('dashboardAgent');
          break
        default:
          $state.go('home');
      }
    }

    $window.addEventListener('message', function(message) {
      if (message.data.action === 'customer_application_created'){
        Swal('Success',message.data.res.message,'success');
        redirectToPath(message.data.res.res);
      } else if(message.data.action === 'customer_application_failed'){
        Swal('Error',message.data.res.message,'warning');
      }
    });
  }

})();
