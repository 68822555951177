(function () {
  'use strict';
  angular
    .module('app')
    .component('periodsModalCreate', {
      templateUrl: 'app/components/periods/modal-period-create.html',
      controller: PeriodsModalCreateController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

  PeriodsModalCreateController.$inject = ['user', 'globalService'];

  function PeriodsModalCreateController($user, $globalService) {
    var vm = this;

    vm.$onInit = function () {
      vm.period = vm.resolve.period;
      vm.currentUser = $user.getCurrentUser();
    }


    vm.create = function(params) {
      vm.period.description = moment(vm.period.date).format('MM-YYYY');
      vm.period.date = moment(vm.period.date).format('YYYY-MM') + '-01';
      swal({
        title: 'Are you sure to create?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $globalService.createPeriod(vm.period)
            .then(function(res){
              Swal('Success',res.message,'success');
              vm.close();
            }, function(err) {
              Swal('Stop',err.data.message,'warning');
            });
          });
        },
        allowOutsideClick: false,
      }).then(function(data) {});
    }

  }

})();
