
(function(){
  'use strict';
  angular
    .module('app')
    .component('paymentCompany', {
      templateUrl: 'app/components/payment-company/payment-company.html',
      controller: PaymentCompanyController
    });

    PaymentCompanyController.$inject = ['user', '$stateParams', '_','$rootScope','globalService']

  function PaymentCompanyController($user, $stateParams, _, $rootScope,$globalService) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();

    vm.$onInit = function(){
      vm.loading = true;
      $rootScope.$emit('uploadBreadCrum', {current: 'Payments Company', before: 'Company'});
      var nameArray = $stateParams.company_name.split('-');
      vm.idCompany = nameArray[nameArray.length -1];
      
      $globalService.companies.get({id: vm.idCompany}, function(res){
        vm.loading = false;
        vm.company = res;
        if(_.isNull(vm.company.company_rate)){
          vm.company.company_rate = {
            company_id: vm.company.id,
            is_percent: false,
            amount: 0,
            diff_amounts: false,
            amount_new: 0,
            amount_renewed: 0
          }
        }

        $globalService.get_states()
        .then(function(res){
          vm.states = res;
          if(vm.company.company_rate.diff_amount_states){
            _.map(vm.company.company_rate.comission_states, function(data){
              var statesSelected = [];
              if(data.states_id.length >= 1){
                _.forEach(data.states_id, function(id){
                  statesSelected.push(_.findWhere(vm.states, {id: id}))
                })
              }
              data.statesSelected = statesSelected;
            })
            //console.log(vm.company.company_rate.comission_states)
          }
        })
      })
    }


    vm.save = function(){
      vm.send = true;
      _.map(vm.company.company_rate.comission_states, function(data){
        data.states_id = _.pluck(data.statesSelected, 'id');
        return data;
      })

      if(vm.company.company_rate.id){
        $globalService.companiesRates.update({insurance_company_id: vm.company.id, id: vm.company.company_rate.id}, vm.company, function (res) {
          vm.send = false;
          Swal('Exito', res.message, 'success');
        }, function (err) {
          Swal('Error', err.data.message, 'warning');
        });
      }else{
        $globalService.companiesRates.save({insurance_company_id: vm.company.id}, vm.company, function (res) {
          vm.send = false;
          Swal('Exito', res.message, 'success');
        }, function (err) {
          Swal('Error', err.data.message, 'warning');
        });
      }
    }

    vm.setArray = function(data){
      //console.log(vm.company)
      if(data){
        if(vm.company.company_rate.comission_states){
          vm.company.company_rate.comission_states.push({
            states_id: [],
            amount: 0,
            new_amount: 0,
          })
        }else{
          vm.company.company_rate.comission_states = [
            {
              states_id: [],
              amount: 0,
              new_amount: 0,
            } 
          ];
        }
      }else{
        vm.company.company_rate.comission_states = []  
      }
    }

    vm.addItem = function(){
      vm.company.company_rate.comission_states.push({states_id: [],amount: 0});
    }

    vm.marditasea = function(){
      //console.log(vm.company)
    }


  }


})();
