(function(){
  'use strict';
  angular
  .module('app')
  .component('notifications', {
    templateUrl: 'app/components/notifications/notifications.html',
    controller: NotificationsController
  });

  NotificationsController.$inject = ['user', '$rootScope', 'globalService','$sce'];

  function NotificationsController($user, $rootScope, $globalService, $sce) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.loadingData = false;
    vm.statuses = [{title:'Unread', read: false},{title:'Read', read: true}]

    vm.$onInit = function(){
      vm.per = 20;
      vm.currentPage = 1;
      vm.currentUser = $user.getCurrentUser();
      vm.loadingData = true;
      $globalService.users.query().$promise
      .then(function(res){
        vm.users = res;
      })
      findNotifications()
    }

    function findNotifications(){
      $globalService.notifications.query({all: true, page: vm.currentPage, per: vm.per }).$promise
      .then(function(res){
        vm.loadingData = false;
        vm.data = res;
        vm.data.notifications = _.map(res.data, function(data){
          data.description = $sce.trustAsHtml(data.description);
          data.selected = false;
          return data;
        });
      });
    }

    vm.selectAllNotification = function(){
      _.each(vm.data.notifications, function(noty){
        noty.selected = true;
      })
    }

    vm.sendNotifications = function(){
      var params = {
        customer_apps_ids: _.reduce(vm.data.notifications, function(memo, data){
          console.log(data, data.selected && data.crmid != null)
          if(data.selected && data.crmid != null){
            memo.push(data.crmid);
          }
          return memo;
        },[]),
        user_id: vm.userSelected.id,
        notifications_id: _.reduce(vm.data.notifications, function(memo, data){
          if(data.selected && data.crmid != null){
            memo.push(data.id);
          }
          return memo;
        },[])
      }

      swal({
        title: 'Are you sure',
        text: 'to send this applications?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.sendCustomerAppsToUsers(params)
            .then(function(res){
              vm.loading = false;
              swal('Exito', res.message, 'success');
            }, function(err){
              vm.loading = false;
              _.forEach(params.notifications_id, function(id){
                var notify = _.findWhere(vm.data.notifications, {id: id})
                if (notify){
                  notify.selected = false;
                }
              })
              swal('Error', err.data.message, 'danger');
            });            
          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    }


    vm.updateNotification = function(notification){
      $globalService.notifications.update({id: notification.id}, {notification: {read: true}}).$promise
      .then(function(res){
        notification.read = true;
      })
    }

    vm.pageChanged = function () {
      vm.send = true;
      vm.loadingData = true;
      vm.currentPage = vm.data.current_page;
      $globalService.notifications.query({all: true, page: vm.currentPage, per: vm.per, read: vm.read }).$promise
      .then(function (res) {
        vm.loadingData = false;
        vm.data = res;
        vm.data.notifications = _.map(res.data, function(data){
          data.description = $sce.trustAsHtml(data.description);
          return data;
        });
      })
    };

    $rootScope.$on('newPersonalNotification', function(e,d){
      findNotifications();
    })

  }


})();
