(function () {
	'use strict';
	angular
		.module('app')
		.component('audits', {
			templateUrl: 'app/components/audits/audits.html',
			controller: CustomerApplicationAuditsController
		});

	CustomerApplicationAuditsController.$inject = ['$uibModal', 'user', 'url', '$stateParams', '_', 'globalService', '$rootScope'];

	function CustomerApplicationAuditsController($uibModal, $user, $url, $stateParams, _, $globalService, $rootScope) {
		var vm = this;
		vm.loadingResumen = true;
		vm.loadingVerions = true;
		vm.options =  {data: '', options: { mode: 'tree' }};
		
		vm.$onInit = function () {
			$rootScope.$emit('uploadBreadCrum', { current: 'Audits Customer Application', before: 'Home' });
			$globalService.users.query().$promise
			.then(function(res){
				vm.users = res;
				$globalService.resApplications.get({id: $stateParams.app_id}).$promise
				.then(function(res){
					vm.currentApplication = res;
					vm.loadingResumen = false;
					$globalService.auditsCustomerApp($stateParams.app_id)
					.then(function(res){
						vm.versions = _.map(res, function(data){
							if(data.audited_changes.hasOwnProperty('main_applicant')){
								// data.audited_changes.pretty_main_applicant = JSON.parse(data.audited_changes.main_applicant)
							}
							// console.log(data.audited_changes.main_applicant)
							// if(data.audited_changes.hasOwnProperty('main_applicant')){
							// 	console.log(data.audired_changes.main_applicant)
							// }
							data.user_send = _.findWhere(vm.users, {id: data.user_id})
							if (data.audited_changes.hasOwnProperty('user_id')){
								data.audited_changes.first_agent = _.findWhere(vm.users, {id: data.user_id});
							}
							return data;
						});
						console.log(vm.versions)
						vm.loadingVerions = false;
					})
				})
			})
			
		}

		$rootScope.$on('loadDatabases', function (evt, data) {
			vm.$onInit();
		})

	}


})();
