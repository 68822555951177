(function () {
  'use strict';
  angular
    .module('app')
    .component('customerDirectory', {
      templateUrl: 'app/components/customerDirectory/customerDirectory.html',
      controller: CustomerDirectoryController
    });

  CustomerDirectoryController.$inject = ['$uibModal', 'toastr', '$window', 'user', '_', 'globalService', '$rootScope'];

  function CustomerDirectoryController($uibModal, toastr, $window, $user, _, $globalService, $rootScope) {
    var vm = this;
    vm.loading = false;
    vm.per = 20;
    vm.currentPage = 1;
    vm.params = {
      phone: undefined,
      state: undefined
    }

    vm.$onInit = function () {
      vm.currentUser = $user.getCurrentUser();
      vm.loading = true;
      $rootScope.$emit('uploadBreadCrum', { current: 'Users Management', before: 'Home' });
      vm.downloadModel = $globalService.getUrlExcel('customer_directory/modelo','')
      vm.sections = $globalService.sections.query();
      $globalService.customerDirectories.query().$promise
      .then(function(res){
        vm.data = res;
        vm.loading = false;
        vm.downloadLink = $globalService.getUrlExcel('customer_directory', {
          token: vm.currentUser.token
        })
      })

      $globalService.getAllAgents.query().$promise
      .then(function(res){
        vm.agents = res;
      })

      $globalService.customerDirectoriesStates()
      .then(function(res){
        vm.states = res;
      });
    }


    vm.newDirectory = function(){
      var modalInstance = $uibModal.open({
        animation: true,
        component: 'newCustomerDirectory',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          agents: function(){
            return vm.agents;
          }
        }
      });

      modalInstance.result.then(function (data) {
        vm.$onInit();
      });
    }

    vm.loadFile = function(){
      var modalInstance = $uibModal.open({
        animation: true,
        component: 'cargaMasiva',
        windowClass: 'show',
        backdrop: 'static',
        size: 'xs',
      });

      modalInstance.result.then(function (data) {
        vm.$onInit();
      });
    }


    vm.pageChanged = function () {
      vm.loading = true;
      vm.currentPage = vm.data.current_page;
      var filter = {
        page: vm.currentPage,
        per: vm.per
      }
      if(vm.stateSelected){
        filter.state = vm.stateSelected.id;
      }
      if(vm.params.phone){
        filter.phone = vm.params.phone.replace(/[^0-9]/g,'');
      }
      if(vm.agentsSelected && vm.agentsSelected.length > 0){
        filter.agent = _.pluck(vm.agentsSelected, 'id').join(',');
      }
      $globalService.customerDirectories.query(filter).$promise
      .then(function(res){
        vm.data = res;
        vm.loading = false;
        filter.token = vm.currentUser.token;
        vm.downloadLink = $globalService.getUrlExcel('customer_directory', filter)
      })
    }

    vm.setProduct = function (insurance, product) {
      var directory = angular.copy(insurance);
      if(directory[product]){
        switch (product) {
          case 'health':
            directory.health = false
            directory.health_id = null
            directory.health_code = null
            directory.health_agent_id = null
            break;
          case 'vision':
            directory.vision = false
            directory.vision_id = null
            directory.vision_code = null
            directory.vision_agent_id = null
            break;
          case 'accident':
            directory.accident = false
            directory.accident_id = null
            directory.accident_code = null
            directory.accident_agent_id = null
            break;
          case 'dental':
            directory.dental = false
            directory.dental_id = null
            directory.dental_code = null
            directory.dental_agent_id = null
            break;
          case 'life':
            directory.life = false
            directory.life_id = null
            directory.life_code = null
            directory.life_agent_id = null
            break;
          default:
            break;
        }
        swal({
          title: 'Are you sure to remove '+product+' insurance?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          showLoaderOnConfirm: true,
          preConfirm: function () {
            return new Promise(function (resolve, reject) {
              $globalService.customerDirectories.update({id: directory.id}, directory).$promise
              .then(function(res){
                vm.loading = false;
                console.log(res)
                insurance = res.customer_directory;
                resolve(res.message);
                vm.pageChanged();
              }, function (err) {
                reject(err.data.message);
                vm.send = false;
              });
            });
          },
          allowOutsideClick: false,
        }).then(function (data) {
          swal('Success', data.value, 'success');
        });
      }else{
        var modalInstance = $uibModal.open({
          animation: true,
          component: 'insuranceProduct',
          windowClass: 'show',
          backdrop: 'static',
          size: 'lg',
          resolve: {
            product: function(){
              return product;
            },
            directory: function(){
              return directory;
            },
            agents: function(){
              return vm.agents;
            }
          }
        });
  
        modalInstance.result.then(function (data) {
          vm.$onInit();
        });
      }
    }

    vm.downloadModel = function(){
      
    }
  }


})();
