(function () {
  'use strict';
  angular
    .module('app')
    .component('invoiceClosureModal', {
      templateUrl: 'app/components/general_invoice_closure/modalClosure.html',
      controller: GeneralInvoiceClosureController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

  GeneralInvoiceClosureController.$inject = ['$rootScope', 'globalService'];

  function GeneralInvoiceClosureController($rootScope, $globalService) {
    var vm = this;
    vm.save = save;
    vm.consult = consult;
    vm.send = false;

    vm.$onInit = function () {
      var from = moment().startOf('week');
      var to = moment().endOf('week');
      var date = new Date();
      vm.from = date.setDate(from.format('DD'));
      vm.to = date.setDate(to.format('DD'));
    }
    function save() {
      vm.send = true;
      swal({
        title: 'Are you Sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.generalInvoiceClosure.save({close: true, from: moment(vm.from).format('YYYY-MM-DD'), to: moment(vm.to).format('YYYY-MM-DD')},
              function (res) {
                vm.send = false;
                vm.closure = res;
                vm.close();
                resolve();
              }, function () {
                vm.send = false;
                reject('Ha ocurrido un error');
              });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    }

    function consult() {
      vm.send = true;
      $globalService.generalInvoiceClosure.save({},
        function (res) {
          vm.send = false;
          vm.closure = res;
        }, function(err) {
          vm.send = false;
          Swal('info',err.data.message,'info')
        });
    }

    vm.cancel = function () {
      vm.dismiss({ $value: '' })
    }

  }

})();
