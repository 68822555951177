(function () {
    'use strict';
    angular
    .module('app')
    .component('processRenewdApplication', {
      templateUrl: 'app/components/modals/modal-finish-renewed-appliation.html',
      controller: ProcessRenewedApplicationController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });
  
    ProcessRenewedApplicationController.$inject = ['$rootScope','globalService','user'];
  
    function ProcessRenewedApplicationController($rootScope, $globalService, $user) {
      var vm = this;
      vm.save = save;
      vm.currentUser = $user.getCurrentUser();
      vm.reasonType = [
        {key: 'reactiva', value: 'Reactivar'},
        {key: 'cambio_datos_personales', value: 'Cambio Datos Personales'},
        {key: 'cambio_npn', value: 'Cambio NPN'},
        {key: 'otros', value: 'Otros (Justificar)'}
      ];
  
      vm.$onInit = function() {
        vm.application = vm.resolve.application;
        vm.settings = vm.resolve.settings;
        vm.application_id = '';
        vm.note = '';
      }
  
  
      function save(){
        vm.send = true;
        if(vm.currentUser.pretty_type == 'Asistente'){
          if(vm.reasonSelected.key == 'otros' || vm.reasonSelected.key == 'cambio_npn'){
            vm.reason = vm.reason + ': ' +vm.reasonJustify;
          }else{
            vm.reason = vm.reason;
          }
        }
        vm.close({$value: { application_id: vm.application_id, note: vm.note, reason: vm.reason }})
      }
  
      vm.cancel = function(){
        vm.dismiss({$value: ''}) 
      }
  
      $rootScope.$on('setCurrentApplicant', function(event, data){
        vm.currentApplication = data.current;
        // //console.log(vm.currentApplication.dependents)
      })
  
    }
  
  })();
  