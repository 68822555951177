(function () {
  'use strict';
  angular
  .module('app')
  .component('companyEdit', {
    templateUrl: 'app/components/companies/companyEditModal.html',
    controller: CompanyEditController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  CompanyEditController.$inject = ['$sce','globalService'];

  function CompanyEditController($sce, $globalService) {
    var vm = this;
    vm.save = save;
    vm.addProduct = addProduct;
    vm.addType = addType;

    vm.$onInit = function() {
      vm.company = vm.resolve.company;
      vm.types_insurances_companies = vm.resolve.types_insurances_companies;
      vm.type_insurance_selected = _.find(vm.types_insurances_companies, {id: vm.resolve.company.type_insurance_company_id})
    }


    function save(){
      vm.send = true;
      $globalService.updateCompanyProducts(vm.company)
      .then(function(res){
          vm.send = false;
          Swal('Exito',res.message,'success');
          vm.close({$value: res.data})
        }, function(err){
          Swal('Error',err.data.message,'warning');
          vm.cancel({$value: ''});
        })
      }


      function addProduct(){

        vm.company.products.push({
          name: vm.productName,
          selected: true,
          active: true
        })
        vm.productName = '';
      }

      function addType(data){
        if(data.hasOwnProperty('company_product_types')){
          data.company_product_types.push({
            name: data.newType
          })
        }else{
          data.company_product_types = [{name: data.newType}];
        }
        data.newType = undefined;
      }

    }

  })();
