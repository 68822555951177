(function(){
  'use strict';
  angular
    .module('app')
    .component('userInformation', {
      templateUrl: 'app/components/user_information/user_information.html',
      controller: userInformationController
    });

    userInformationController.$inject = ['user', '$stateParams', '_','$rootScope','globalService']

  function userInformationController($user, $stateParams, _, $rootScope,$globalService) {
    var vm = this;
    vm.loading = false;
    vm.disableAdd = false;
    vm.currentUser = $user.getCurrentUser();
    vm.agenciesTypes = [{id: 0, text: 'Main Agency'},{id: 1, text: 'Sub Agency'}]

    vm.$onInit = function(){
      vm.loading = true;
      vm.user = { id: $stateParams.id, name: $stateParams.full_name.split('-').join(' ') }
      $rootScope.$emit('uploadBreadCrum', {current: 'User Information for: ' + vm.user.name, before: 'Users'});

      $globalService.userInformation(true).query({user_id: vm.user.id}).$promise
      .then(function(res){
        vm.userInformation = res;
        vm.loading = false;
      })
      vm.urlReport = $globalService.reportPdfWithAction('user_information',undefined,{user_id: vm.user.id, token: $user.getCurrentUser().token});
    }


    vm.addInformation = function(){
      vm.loading = true;
      vm.disableAdd = true;
      $globalService.userInformation(true).save({user_id: vm.user.id, key: vm.key, value: vm.value}).$promise
      .then(function(res){
        vm.userInformation.push(res.user_information);
        vm.loading = false;
        vm.disableAdd = false;
        vm.value = undefined;
        vm.key = undefined;
      })
    }

    vm.deleteInformation = function(index, information){
      vm.loading = true;
      $globalService.userInformation(false).delete({id: information.id}).$promise
      .then(function(res){
        vm.userInformation.splice(index,1)
        vm.loading = false;
      })
    }
    

  }

})();
