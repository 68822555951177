(function () {
  'use strict';
  angular
    .module('app')
    .component('periodsModal', {
      templateUrl: 'app/components/periods/modal-period.html',
      controller: PeriodsModalController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

  PeriodsModalController.$inject = ['user', 'globalService'];

  function PeriodsModalController($user, $globalService) {
    var vm = this;

    vm.$onInit = function () {
      vm.period = vm.resolve.period;
      vm.currentUser = $user.getCurrentUser();
    }


    vm.clear = function(to_clear,period) {
      if(to_clear == 'members_not_found'){
        swal({
          title: 'Are you sure to clear?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          showLoaderOnConfirm: true,
          preConfirm: function() {
            return new Promise(function(resolve, reject) {
              $globalService.updateNotFound({id: period.id})
              .then(function(res){
                vm.period.members_not_found = undefined;
                vm.period.show_not_found = !vm.period.show_not_found;
                Swal('Success',res.message,'success');
              }, function(err) {
                Swal('Stop',err.data.message,'warning');
              });
            });
          },
          allowOutsideClick: false,
        }).then(function(data) {});
      }else if(to_clear == 'bajas'){
        swal({
          title: 'Are you sure to clear?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          showLoaderOnConfirm: true,
          preConfirm: function() {
            return new Promise(function(resolve, reject) {
              $globalService.updateClosed({id: period.id})
              .then(function(res){
                vm.period.closed = undefined;
                vm.period.show_closed = !vm.period.show_closed;
                Swal('Success',res.message,'success');
              }, function(err) {
                Swal('Stop',err.data.message,'warning');
              });
            });
          },
          allowOutsideClick: false,
        }).then(function(data) {});
      }else{
        swal({
          title: 'Are you sure to clear?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          showLoaderOnConfirm: true,
          preConfirm: function() {
            return new Promise(function(resolve, reject) {
              $globalService.updateUnpaid({id: period.id})
              .then(function(res){
                vm.period.unpaid_members = undefined;
                vm.period.show_unpaid = !vm.period.show_unpaid;
                Swal('Success',res.message,'success');
              }, function(err) {
                Swal('Stop',err.data.message,'warning');
              });
            });
          },
          allowOutsideClick: false,
        }).then(function(data) {});
      }
    }

    vm.removeMember = function(member) {
      swal({
        title: 'Are you sure to clear?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $globalService.removePaidMember({member_id: vm.member_id, id: vm.period.id})
            .then(function name(res) {
              vm.member_id = undefined;
              vm.period.members_paid = undefined;
              vm.period.members_paid = res.members_paid;
              Swal('Success',res.message,'success');
            }, function(err) {
              Swal('Stop',err.data.message,'warning');
            });
              
              
            
          });
        },
        allowOutsideClick: false,
      }).then(function(data) {});
      
    }

  }

})();
