
(function () {
  'use strict';
  angular
    .module('app')
    .component('applications', {
      templateUrl: 'app/components/applications/applications.html',
      controller: ApplicationController
    });

  ApplicationController.$inject = ['user', '$uibModal', 'globalService', '_', '$state', '$rootScope', '$interval'];

  function ApplicationController($user, $uibModal, $globalService, _, $state, $rootScope, $interval) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.step1 = true;
    vm.selectType = selectType;
    vm.message = "Select the type of service";
    vm.mainApplicant = mainApplicant;
    vm.sexs = ["Masculino", "Femenino"];
    vm.status_migratoriess = ["CITIZEN", "PERMANENT RESIDENT", "WORK PERMIT", "VISA", "FAROLEE", "OTHERS"];
    vm.work_types = ["employee", "self employee"];
    vm.civil_statuses = ['Casado', 'Soltero'];
    vm.readyToApply = false;
    vm.showMain = true;
    vm.showSpose = false;
    vm.showDependents = false;
    vm.showPayment = false;
    vm.loadingApp = false;
    vm.addDependents = addDependents;
    vm.spouseApplicant = spouseApplicant;
    vm.dependentApplicant = dependentApplicant;
    vm.finishApplication = finishApplication;
    vm.setTypeOfCard = setTypeOfCard;
    vm.card_type_icon = 'fa-credit-card-alt';
    vm.validateDate = validateDate;
    vm.validateNumberPhone = validateNumberPhone;
    vm.rejectPhoneNumber = /^\+?\d{3}[- ]?\d{3}[- ]?\d{4}$/;
    vm.ageCalculator = ageCalculator;
    vm.applicationsType = [{id: 'New', name: 'New'}, {id: 'Renewal', name: 'Renewal'}];
   
    vm.$onInit = function () {
      vm.application = { main_applicant: { immigration_number: { 0: 'A' }, phone: null }, spouse_applicant: { immigration_number: { 0: 'A' } } };
      var load = 0;
      $globalService.type_insurance_companies()
        .then(function (res) {
          vm.type_insurance_companies = res;
          load = load + 1;
        })
      $globalService.get_states()
        .then(function (res) {
          vm.states = res;
          load = load + 1;
        })
      if ($state.params.id) {
        var customer_application = $globalService.getLocalCA($state.params.id);
        customer_application.main_applicant.birthday = new Date(moment(customer_application.main_applicant.birthday).format('MM/DD/YYYY'));

        if (customer_application.hasOwnProperty('spouse') && customer_application.spouse != null) {
          customer_application.spouse.birthday = new Date(moment(customer_application.spouse.birthday, 'MM-DD-YYYY').format('MM-DD-YYYY'));
        }
        if (customer_application.hasOwnProperty('dependents')) {
          _.map(customer_application.dependents, function (dependent) {
            if(dependent.birthday){
              if(isDate(dependent.birthday)){
                dependent.birthday = new Date(dependent.birthday);
              }else if (dependent.birthday.split('/').length == 3){
                dependent.birthday = new Date(moment(dependent.birthday, 'MMM/DD/YYYY').format('MM/DD/YYYY'));
              }
            }
            return dependent;
          })
        }
        if (customer_application)
          vm.application = angular.copy(customer_application);

        var stop;

        stop = $interval(function () {
          if (load == 3) {
            setTimeout(function () {
              selectType(_.find(vm.type_insurance_companies, { id: vm.application.insurance_data.company.type_insurance_company_id }))
            }, 0)
            $interval.cancel(stop);
            stop = undefined;
          }
        }, 100)
      }
      getAllAgent();
    }

    function getAllAgent() {
      $globalService.getAllAgents.query(function (res) {
        vm.agents = res;
      })
    }

    function selectType(data) {
      vm.step1 = false;
      var modal = $uibModal.open({
        animation: true,
        component: 'showDetails',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          type_insurance_companies: function () {
            return data;
          },
          allCompanies: function () {
            return getAllCompanies();
          },
          applicationsType: function () {
            return vm.applicationsType;
          },
          application: function () {
            return vm.application;
          },
          agents: function(){
            return vm.agents;
          }
        }
      });

      modal.result.then(function (res) {
        vm.readyToApply = true;
        vm.application = res;
        if (res.id) {
          if (vm.application.main_applicant.hasOwnProperty('card_expiration_date')) {
            var date = new Date(moment(angular.copy(vm.application.main_applicant.card_expiration_date), 'MM-DD-YYYY').format('MM/DD/YYYY'));
            vm.application.main_applicant.card_expiration_date = date;
          }
        } else {
          vm.application.dependents = [];
        }
      }, function (err) {
        vm.readyToApply = false;
        vm.step1 = true;
      });

    }

    function getAllCompanies() {
      vm.allCompanies = _.reduce(vm.type_insurance_companies, function (memo, data) {
        _.forEach(data.companies, function (company) {
          memo.push(company)
        })
        return memo;
      }, [])

      return vm.allCompanies;
    }

    function mainApplicant(form) {
      if (!form.main_applicant_birthday.$valid) {
        swal('Stop', 'there is an error in the date of birth', 'warning');
        return;
      }
      if(vm.application.main_applicant.phone == null || vm.application.main_applicant.phone == '' || vm.application.main_applicant.phone == undefined){
        swal('Stop', 'You must enter a phone number', 'warning');
        return;
      }
      vm.showMain = false;
      vm.showSpose = true

      if (vm.application.id) {
        if (vm.application.hasOwnProperty('spouse') && vm.application.spouse != null) {
          var spouse_applicant = vm.application.spouse;
          if (spouse_applicant.hasOwnProperty('card_expiration_date')) {
            spouse_applicant.card_expiration_date = new Date(moment(spouse_applicant.card_expiration_date, 'MM/DD/YYYY').format('MM-DD-YYYY'));
          }
          if(!isDate(spouse_applicant.birthday)){
            spouse_applicant.birthday = new Date();
          }
          vm.application.spouse_applicant = angular.copy(spouse_applicant);
        }
      }
      if (vm.application.main_applicant.birthday) {
        vm.application.main_applicant.birthday = moment(vm.application.main_applicant.birthday).format('MM/DD/YYYY');
      }
      if (vm.application.main_applicant.card_expiration_date) {
        vm.application.main_applicant.card_expiration_date = moment(vm.application.main_applicant.card_expiration_date).format('MM-DD-YYYY');
      }

    }

    function addDependents() {
      if (!vm.application.hasOwnProperty('dependents') || vm.application.dependents == null) {
        vm.application['dependents'] = [];
      }
      vm.application.dependents.push({
        name: '',
        last_name: '',
        birthday: null,
        social_security_number: '',
        status_migratory: '',
        immigration_number: '',
        card_number: '',
        card_expiration_date: '',
        category: '',
      })
    }

    function spouseApplicant(formspouse) {
      if (!formspouse.spouse_applicant_birthday.$valid) {
        swal('Warning', 'there is an error in the date of birth', 'warning');
        return;
      }
      
      if (vm.application.spouse_applicant.birthday != undefined) {
        vm.application.spouse_applicant.birthday = moment(vm.application.spouse_applicant.birthday).format('MM/DD/YYYY');
      }else{
        swal('info','You must enter the date of birth','info');
        return;
      }
      if(vm.application.main_applicant.birthday == moment().format('MM/DD/YYYY')){
        swal('Warning','You mus enter the date of birth of the spouse applicant','warning');
        return
      }
      if (vm.application.spouse_applicant.status_migratory == 'CITIZEN') {
        delete vm.application.spouse_applicant.immigration_number;
      } else {
      }
      if (vm.application.spouse_applicant.card_expiration_date) {
        vm.application.spouse_applicant.card_expiration_date = moment(vm.application.spouse_applicant.card_expiration_date).format('MM/DD/YYYY');
      }

      prepareDependentInfo()

      vm.showSpose = false;
      vm.showDependents = true;
    }

    function dependentApplicant() {
      var dependentError = 0;
      if (vm.application.dependents) {

        _.forEach(vm.application.dependents, function (dependent, index) {
          if(dependent.birthday != null){
            
            dependent.birthday = moment(dependent.birthday).format('MM/DD/YYYY');
            if(isDate(dependent.birthday)){
              dependent.birthday = moment(dependent.birthday).format('MMM/DD/YYYY');
            }

            if (dependent.card_expiration_date) {
              dependent.card_expiration_date = moment(dependent.card_expiration_date).format('MM/DD/YYYY');
            }

          }else{
            dependentError = dependentError + 1;
          }
        })

        if(dependentError > 0){
          swal('info','You must enter the date of birth of all dependents','info');
          return;
        }
      }

      if (vm.application.hasOwnProperty('billing_information') && vm.application.billing_information != null) {
        var billing = vm.application.billing_information;
        if (billing.hasOwnProperty('expiration_card')) {
          if(isDate(billing.expiration_card)){
            billing.expiration_card = new Date(billing.expiration_card)
          }else{
            billing.expiration_card = new Date(moment(billing.expiration_card, 'MM-YYYY').format('MM-DD-YYYY'));
          }
          setTypeOfCard(billing.card_number)
        }
      }
      vm.application.billing = angular.copy(billing);

      vm.showDependents = false;
      vm.showPayment = true;

    }

    function finishApplication() {
      vm.loadingApp = true;
      if (vm.application.billing.expiration_card) {
        vm.application.billing.expiration_card = moment(vm.application.billing.expiration_card).format('MM-YYYY')
      }
      vm.application.main_applicant.phone = vm.application.main_applicant.phone.replace(/\D/g, '');
      vm.application.state_id = vm.application.main_applicant.stateSelected.id;
      if (vm.application.id) {
        showStrictModal('changeLife');
      } else {
        if(vm.application.application_type == "Renewal"){ //if application is renew
          showStrictModal('Renewal');
        }else{
          showStrictModal('New');
        }

      }

    }

    function showStrictModal(type) {
      var modal = $uibModal.open({
        animation: true,
        component: 'processRenewdApplication',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          application: function(){
            return vm.application;
          },
          settings: function() {
            return {
              show_application_id: type == 'New' ? false : true,
            }
          }
        }
      });

      modal.result.then(function (res) {
        if(type == 'changeLife'){
          vm.application.note = res.note;
          vm.application.reason = res.reason;
          vm.application.change_life_app_id = res.application_id;
          $globalService.sendApplicationChangeLife(vm.application)
          .then(function (res) {
            vm.send = false;
            vm.loadingApp = false;
            Swal('Success',res.message,'success');
            redirectToPath(res);
          }, function (err) {
            vm.loadingApp = false;
            Swal('Error',err.data.message,'warning');
          });
        }else{
          vm.application.note = res.note;
          if(type == 'renewal') { vm.application.note += ' ApplicationID: ' + res.application_id }
          $globalService.sendApplication(vm.application)
          .then(function (res) {
            vm.send = false;
            vm.loadingApp = false;
            Swal('Success',res.message,'success');
            redirectToPath(res);
          }, function (err) {
            vm.loadingApp = false;
            Swal('Error',err.data.message,'warning');
          });
        }
      }, function (err) {
        swal('Error','You must indicate and application id','warning');
      }); 
    }

    function redirectToPath(res) {
      switch (vm.currentUser.pretty_type) {
        case "Manager":
          $state.go('dashboardManager');
          break;
        case "Administrator":
          $state.go('home');
          break;
        case "Agente":
          $state.go('dashboardAgent');
          break
        default:
          $state.go('home');
      }
    }

    function setTypeOfCard(data) {
      var card_icon = validatioRejec(data);
      if (card_icon) {
        vm.card_type_icon = card_icon;
      }
    }

    function validatioRejec(number) {
      var re = {
        'electron': /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
        'maestro': /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
        'dankort': /^(5019)\d+$/,
        'interpayment': /^(636)\d+$/,
        'unionpay': /^(62|88)\d+$/,
        'fa-cc-visa': /^4[0-9]{12}(?:[0-9]{3})?$/,
        'fa-cc-mastercard': /^5[1-5][0-9]{14}$/,
        'fa-cc-amex': /^3[47][0-9]{13}$/,
        'fa-cc-diners-club': /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        'fa-cc-discover': /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        'fa-cc-jcb': /^(?:2131|1800|35\d{3})\d{11}$/
      }

      for (var key in re) {
        if (re[key].test(number)) {
          return key
        }
      }
    }

    function validateNumberPhone(number) {
      var reject = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
      return reject.test(number)
    }

    function validateDate() {
      if (moment(vm.application.billing.expiration_card) < moment()) {
        Swal('Warning', 'Invalid Date: Expired Card', 'warning');
      }
    }

    vm.checkCardExpirationDate = function (data) {
      if (moment(data) < moment()) {
        Swal('Warning', 'Invalid Date: Expired Card', 'warning');
      }
    }

    vm.skipSpouse = function () {
      if (vm.application.main_applicant.civil_status == 'Casado') {
        Swal('Stop', 'Complete a spouse data', 'info');
        return
      }

      prepareDependentInfo()
      if (vm.application.spouse_applicant && vm.application.spouse_applicant.id == undefined) {
        vm.application.spouse_applicant = null;
        vm.application.spouse = null;
      }
      vm.showSpose = false;
      vm.showDependents = true;
    }
    vm.skipDependents = function () {
      vm.showDependents = false;
      vm.showPayment = true;
      if (!vm.application.id) {
        delete vm.application.dependents;
      }
      if(Array.isArray(vm.application.dependents)){
        vm.application.dependents.each(function(dependent){
          if(dependent.birthday){
            if(isDate(dependent.birthday)){
              dependent.birthday = moment(dependent.birthday).format('MMM/DD/YYYY');
            }
          }
        })
      }
      

      if (vm.application.hasOwnProperty('billing_information') && vm.application.billing_information != null) {
        var billing = vm.application.billing_information;
        if (billing.hasOwnProperty('expiration_card')) {
          billing.expiration_card = new Date(moment(billing.expiration_card, 'MM-YYYY').format('MM-DD-YYYY'));
        }
      }
      vm.application.billing = angular.copy(billing);
    }

    vm.setSpouseStatus = function () {
      if(vm.application.spouse_applicant == null){
        vm.application.spouse_applicant = {};
      }
      if(vm.application.spouse_applicant.hasOwnProperty('civil_status')){
        vm.application.spouse_applicant.civil_status = 'Casado';
      }else{
        vm.application.spouse_applicant = {
          civil_status: 'Casado'
        }
      }
      if (vm.application.main_applicant.civil_status == 'Casado') {
        
      }
    }
    vm.removeDependent = function (data, index) {
      vm.application.dependents.splice(index, 1);
    }

    vm.setAccountHolder = function () {
      vm.application.billing = {
        account_holder: vm.application.main_applicant.name + ' ' + vm.application.main_applicant.last_name,
      }
    }

    vm.backToMain = function () {
      if (vm.application.main_applicant.hasOwnProperty('card_expiration_date')) {
        var date = new Date(moment(angular.copy(vm.application.main_applicant.card_expiration_date), 'MM-DD-YYYY').format('MM/DD/YYYY'));

        vm.application.main_applicant.card_expiration_date = date;

      }
      vm.showSpose = false;
      vm.showMain = true;
    }

    vm.backToSpouse = function () {
      vm.showDependents = false;
      vm.showSpose = true;
    }

    vm.backToDependents = function () {
      if(!vm.application.dependents){
        vm.application.dependents = [];
      }
      vm.showPayment = false;
      vm.showDependents = true;
    }

    function prepareDependentInfo() {
      if (vm.application.hasOwnProperty('dependents') && vm.application.dependents != null) {
        _.forEach(vm.application.dependents, function (dependent) {
          if(dependent.hasOwnProperty('card_expiration_date')){
            var date = new Date(moment(angular.copy(dependent.card_expiration_date), 'MM-DD-YYYY').format('MM/DD/YYYY'));
            dependent.card_expiration_date = date;
          }

          if(dependent.birthday){
            if(isDate(dependent.birthday)){
              dependent.birthday = new Date(dependent.birthday);
            }else{
              dependent.birthday = new Date(moment(dependent.birthday, 'MMM/DD/YYYY').format('MM/DD/YYYY'));
            }
          }
        })
      }
    }

    function isDate(date){
      return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    }

    function ageCalculator(value, form, input) {
      var date = moment(value)
      var majority = 18
      var diff = moment().diff(date, 'years');
      if (diff >= majority && diff < 100) { } else {
        switch (input) {
          case 'main_applicant_birthday':
            form.main_applicant_birthday.$invalid = true;
            form.main_applicant_birthday.$valid = false;
            form.$invalid = true;
            form.$valid = false;
            break;
          case 'spouse_applicant_birthday':
            form.spouse_applicant_birthday.$invalid = true;
            form.spouse_applicant_birthday.$valid = false;
            form.$invalid = true;
            form.$valid = false;
            break;
          default:
            break;
        }
      }
    }
  }
})();
