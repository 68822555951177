(function () {
  'use strict';
  angular
  .module('app')
  .component('availability', {
    templateUrl: 'app/components/availability/availability.html',
    controller: AvailabilityController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  AvailabilityController.$inject = ['$rootScope','globalService'];

  function AvailabilityController($rootScope, $globalService) {
    var vm = this;
    vm.loading = false;

    vm.$onInit = function(){
      
    }

    function getAvailabilityInfo(currentApplication, form_type){
      if(currentApplication){
        $globalService.seachSign({crmid: currentApplication.id, form_type: currentApplication.availability_form_type})
        .then(function(res){
          vm.sign = res;
          vm.signed_forms = _.map(res.forms_signeds, function(data){
            data.pretty_signed_form = capitalize(data.signed_form.split('_').join(' '));
            return data;
          });
          vm.loading = false;
        }, function(err){
          vm.sign = err.data;
        })
      }
    }

    vm.getAvailabilityLink = function(id){
      vm.loading = true;
      console.log(vm.currentApplication.id)
      $globalService.seachSignFile({crmid: vm.currentApplication.id, signed_form_id: id})
      .then(function(res){
        console.log(res)
        console.log(res.headers())
        const url = URL.createObjectURL(res.data);
        const a = document.createElement('a');
        a.download = vm.currentApplication.main_applicant.phone + '_'+ vm.currentApplication.id + '.pdf';
        a.href = url;
        a.target = '_self';
        a.click();
        vm.loading = false;
      }, function(err){
        vm.signFile = err.data;
        vm.loading = true;
      })
    }

    $rootScope.$on('setCurrentApplicant', function (event, data) {
      vm.currentApplication = data.current;
      getAvailabilityInfo(data.current)
    })

    function capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

  }

})();
