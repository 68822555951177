(function () {
  'use strict';
  angular
    .module('app')
    .component('insuranceCredentials', {
      templateUrl: 'app/components/modals/insurance-credentials.html',
      controller: InsuranceCredentialsAppController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

  InsuranceCredentialsAppController.$inject = ['$rootScope', 'globalService'];

  function InsuranceCredentialsAppController($rootScope, $globalService) {
    var vm = this;
    vm.save = save;

    vm.$onInit = function () {
      vm.currentApplication = vm.resolve.currentApplication;
      $globalService.companies.query().$promise.then(function name(res) {
        vm.companies = res;  
      })
      getCredentials()
    }

    function getCredentials() {
      $globalService.getInsuranceCredential(vm.currentApplication.id)
      .then(function (res) {
        vm.credentials = res;
      })
    }

//     getInsuranceCredential
// saveInsuranceCredential

    function save() {
      vm.new_credentials.customer_application_id = vm.currentApplication.id;
      vm.send = true;
      swal({
        title: 'Are you sure',
        text: 'to add this insurance credentials?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.saveInsuranceCredential(vm.new_credentials)
              .then(function (res) {
                vm.send = false;
                vm.new_credentials = undefined;
                vm.close({ $value: '' });
                Swal('Exito', res.message, 'success');
              }, function (err) {
                Swal('Error', err.data.message, 'warning');
                vm.cancel({ $value: '' });
              })
          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    }

    vm.editCredential = function (credential) {
      vm.new_credentials = credential;
      vm.CompanySelected = _.findWhere(vm.companies, {id: credential.company_id});
    }

    vm.cancel = function () {
      vm.dismiss({ $value: '' })
    }
  }

})();
