(function () {
  'use strict';
  angular
  .module('app')
  .component('memberIdsInfo', {
    templateUrl: 'app/components/member-ids-info/member-ids-info.html',
    controller: MemberIdsInfoController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  MemberIdsInfoController.$inject = ['$rootScope','globalService','user'];

  function MemberIdsInfoController($rootScope, $globalService, $user) {
    var vm = this;
    vm.loading = false;

    vm.$onInit = function(){
      vm.currentUser = $user.getCurrentUser();
    }

    $rootScope.$on('setCurrentApplicant', function (event, data) {
      vm.currentApplication = data.current;
      if(vm.currentApplication.memberid_histories){
        vm.currentApplication.memberid_histories = vm.currentApplication.memberid_histories.split(',')
      }
      if(vm.currentApplication.secondary){
        vm.currentApplication.secondary = vm.currentApplication.secondary.split(',')
      }
    })

    vm.deleteMemberids = function(memberType, member_id){
      vm.loading = true;
      swal({
        title: 'Are you sure to delete this' + capitalize(memberType) + ' '+member_id,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Accept',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.deleteMemberIds({member_type: memberType, id: vm.currentApplication.id, member_id: member_id})
              .then(function (res) {
                resolve(res.message)
              }, function (err) {
                swal('Error', err.data.message, 'danger');
                vm.loading = false;
                reject()
              })
          });
        },
        allowOutsideClick: false,
      }).then(function (data) {
        console.log(data,'data')
        if(data.dismiss){
          return;
        }
        swal('Success', data.value, 'success');
        switch (memberType) {
          case 'main':
            vm.currentApplication.member_id = null;
            break;
          case 'secondary':
            var index = _.indexOf(vm.currentApplication.secondary, member_id);
            vm.currentApplication.secondary.splice(index, 1)
            // vm.currentApplication.secondary = null;
            break;
          case 'history':
            var index = _.indexOf(vm.currentApplication.memberid_histories, member_id);
            vm.currentApplication.memberid_histories.splice(index, 1)
            break;
          default:
            break;
        }
        vm.loading = false;
      });
    }

    function capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

  }

})();
