(function(){
  'use strict';
  angular
  .module('app')
  .component('allInvoices', {
    templateUrl: 'app/components/all-invoices/all-invoices.html',
    controller: AllInvoicesController
  });

  AllInvoicesController.$inject = ['user', '$rootScope', '$state', '_', 'globalService','$uibModal','$stateParams'];

  function AllInvoicesController($user, $rootScope, $state, _,$globalService, $uibModal, $stateParams) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.showCurrentApplication = false;
    vm.loadingData = false;
    var date = new Date();
    vm.date_from = moment().startOf('month').format('MM/DD/YYYY');
    vm.date_to = moment().endOf('month').format('MM/DD/YYYY');
    vm.params = {
      date_from: moment().startOf('month').format('MM/DD/YYYY'),
      date_to: moment().endOf('month').format('MM/DD/YYYY'),
    }
    vm.$onInit = function(){
      $rootScope.$emit('uploadBreadCrum', {current: 'Invoices Report', before: 'Home'});
      vm.loadingData = false;
    }


    vm.consultInvoices = function(){
      vm.loadingData = true;
      vm.params.date_from = moment(vm.date_from).format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.date_to).format('YYYY-MM-DD');
      $globalService.reportAllInvoices(vm.params)
      .then(function(res){
        swal('Success', 'Report generated and send to your email', 'success');
        vm.loadingData = false;
      }, function(err){
        swal('Error', 'Error generating report', 'error');
        vm.loadingData = false;
      });
    }
  }


})();
