(function () {
  'use strict';
  angular
    .module('app')
    .component('payrollModalResumen', {
      templateUrl: 'app/components/payroll/modal-resumen.html',
      controller: ResumenModalController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

  ResumenModalController.$inject = ['$sce', 'globalService'];

  function ResumenModalController($sce, $globalService) {
    var vm = this;

    vm.$onInit = function () {
      vm.resumen = vm.resolve.resumen;
    }

  }

})();
