angular.module('app')
  .directive('passwordValidator', function () {
    return {
      require: 'ngModel',
      link: function (scope, element, attrs, ngModel) {
        scope.validations = {
          minLength: false,
          specialChar: false
        };

        ngModel.$parsers.push(function (password) {
          console.log("Password entered:", password);  // Depuración

          if (!password) {
            // Reseteo de validaciones
            scope.validations.minLength = false;
            scope.validations.specialChar = false;
            ngModel.$setValidity('passwordValidator', false);
            return password;
          }

          // Validación de al menos 8 caracteres
          scope.validations.minLength = password.length >= 8;
          console.log("Min Length Valid:", scope.validations.minLength);  // Depuración

          // Validación de caracteres especiales
          const specialChars = /[!@#$%^&*(),.?":{}|<>]/;
          scope.validations.specialChar = specialChars.test(password);
          console.log("Special Character Valid:", scope.validations.specialChar);  // Depuración

          // Definir la validez general
          const isValid = scope.validations.minLength && scope.validations.specialChar;
          console.log("Is Password Valid:", isValid);  // Depuración
          ngModel.$setValidity('passwordValidator', isValid);

          return password;
        });
      }
    };
  });
