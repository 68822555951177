(function(){
  'use strict';
  angular
  .module('app')
  .component('profitsAgentDetail', {
    templateUrl: 'app/components/profits/profit-detail.html',
    controller: ProfitsAgentsDetailController
  });

  ProfitsAgentsDetailController.$inject = ['user', '$rootScope', '$state', '_', 'globalService','$uibModal','$stateParams','$location'];

  function ProfitsAgentsDetailController($user, $rootScope, $state, _,$globalService, $uibModal, $stateParams, $location) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.showCurrentApplication = false;
    vm.loadingData = false;
    var date = new Date();
    vm.date_from = new Date(date.getFullYear(), date.getMonth(), 1);
    vm.paidsOptions = [{value: 'true', name: 'Paid'}, {value: 'false', name: 'Unpaid'}, {value: 'true,false', name: 'Todas'}]
    vm.date_to = new Date();
    vm.params = {
      date_from: moment().startOf('month').format('MM/DD/YYYY'),
      date_to: moment().endOf('month').format('MM/DD/YYYY'),
    }

    vm.$onInit = function(){
      vm.loadingData = true;
      $rootScope.$emit('uploadBreadCrum', {current: 'Reporte detallado por Agente', before: 'Home'});
      vm.params.date_from = moment($stateParams.date_from, 'YYYY-MM-DD').format('YYYY-MM-DD');
      vm.params.date_to = moment($stateParams.date_to, 'YYYY-MM-DD').format('YYYY-MM-DD');
      vm.params.close = $stateParams.close;
      if($stateParams.paid){
        vm.paidOptionSelected = _.findWhere(vm.paidsOptions, {value: $stateParams.paid});
        vm.params.paid = _.findWhere(vm.paidsOptions, {value: $stateParams.paid}).value;
      }else{
        vm.params.paid = false;
      }
      if($stateParams.agent_id){
        vm.params.user_id = $stateParams.agent_id;
      }else{
        vm.params.company_id = $stateParams.company_id;
        vm.companies = $globalService.companies.query();
        vm.companySelected = $globalService.companies.get({id: $stateParams.company_id});
      }

      $globalService.getAllAgents.query(function(res){
        vm.agents = res;
        vm.agentSelected = _.reduce(vm.agents, function(memo, data){
          if(data.id == vm.params.user_id){
            memo = data;
          }
          return memo;
        },{});
        
      })
      $globalService.reportProfitsAgentsDetail(vm.params)
      .then(function(res){
        vm.reports = _.map(res, function(data){
          data.lost = false;
          if(parseInt(data.total_amount) > parseInt(data.invoice_detail.amount_send_company)){
            data.lost = true;
          }
          return data;
        });
        vm.calculate_total(res);
        vm.loadingData = false;
      });
      vm.params.token = vm.currentUser.token;
      vm.urlReport = $globalService.reportPdfWithAction('profits','profits_agent_detail',vm.params);
      vm.params.report_global = true;
      vm.urlReportGlobal = $globalService.reportPdfWithAction('profits','profits_agent_detail',vm.params);
      vm.params.report_global = false;
      vm.urlReportDetailed = $globalService.reportPdfWithAction('profits','profits_agent_detail',vm.params);
      vm.urlReportBiweekly = $globalService.reportPdfWithAction('profits','report_biweekly',vm.params);
      vm.urlReportBiweeklyExcel = $globalService.getUrlExcel('profits/report_biweekly', vm.params);
    }


    vm.consultApplications = function(){
      vm.loadingData = true;
      vm.params.report_complete = false;
      vm.params.date_from = moment(vm.date_from).format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.date_to).format('YYYY-MM-DD');
      var url = $location.url();
      $location.url(url.replace($stateParams.agent_id, vm.params.user_id));
      $globalService.reportProfitsAgentsDetail(vm.params)
      .then(function(res){
        vm.reports = _.map(res, function(data){
          data.lost = false;
          if(parseInt(data.total_amount) > parseInt(data.invoice_detail.amount_send_company)){
            data.lost = true;
          }
          return data;
        });
        vm.loadingData = false;
        vm.calculate_total(res);
        vm.urlReport = $globalService.reportPdfWithAction('profits','profits_agent_detail',vm.params);
        vm.params.report_global = true;
        vm.urlReportGlobal = $globalService.reportPdfWithAction('profits','profits_agent_detail',vm.params);
        vm.params.report_global = false;
        vm.urlReportDetailed = $globalService.reportPdfWithAction('profits','profits_agent_detail',vm.params);
        vm.urlReportBiweeklyExcel = $globalService.getUrlExcel('profits/report_biweekly', vm.params);
      });
    }

    vm.calculate_total = function(res){
      vm.total = _.reduce(res, function(memo, data){
        memo.total_clients_app += parseInt(data.customers);
        memo.total_clients_company += parseInt(data.invoice_detail.clients);
        memo.total += parseFloat(data.total_amount);
        if(!_.isNull(data.overide)){
          memo.overide += parseFloat(data.overide);
        }
        return memo;
      },{total_clients_app: 0, total_clients_company: 0, total: 0, overide: 0});
      //console.log(vm.total)
    }

    vm.showDetail = function(report){
      $state.go('profitsAgentDetail',{
        agent_id: report.user_id,
        date_from: moment(vm.date_from).format('YYYY-MM-DD'),
        date_to: moment(vm.date_to).format('YYYY-MM-DD'),
        close: vm.params.close,
        paid: vm.params.paid
      })
    }

    vm.editInvoice = function(report){
      var modal = $uibModal.open({
        animation: true,
        component: 'invoiceModal',
        windowClass: 'show',
        size: 'lg',
        resolve: {
          report: function(){
            return report;
          },
          agent: function(){
            return vm.agentSelected
          }
        }
      })

      modal.result.then(function(res){
        vm.calculate_total(vm.reports)
        vm.reports = _.map(vm.reports, function(data){
          data.lost = false;
          if(parseInt(data.total_amount) > parseInt(data.invoice_detail.amount_send_company)){
            data.lost = true;
          }
          return data;
        });
      });
    
    }

    vm.paidPayroll = function(){
      vm.loadingData = true;
      swal({
        title: 'You are sure to paid this payroll?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Accept',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.paidProfitsAgents(vm.params)
            .then(function (res) {
              vm.send = false;
              vm.loadingData = false;
              vm.$onInit();
              Swal('Exito', res.message, 'success');
            }, function (err) {
              vm.loadingData = false;
              Swal('Error', err.data.message, 'warning');
            });

          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    }

    vm.selectPaidOption = function(){
      vm.params.paid = vm.paidOptionSelected.value;
      $stateParams.paid = vm.paidOptionSelected.value;
      $location.search('paid', vm.paidOptionSelected.value.toString());
    }

    vm.download = function(data){
      console.log(data)
      var new_data = _.reduce(data, function(memo, data) {
        memo.push({
          "CRM ID": data.customer_application_id,
          "Agent": data.agent,
          "Status": data.invoice_detail.baja ? 'Baja' : 'Activa',
          "Main Customer": data.invoice_detail.main_applicant,
          "Member ID": data.invoice_detail.member_id,
          "Company": data.company_name,
          "State": data.invoice_detail.state,
          "Company Clients": data.invoice_detail.clients,
          "Amount": data.total_amount,
          "Overide": data.overide,
          "Tax Saving": data.tax_saving ? data.tax_saving.amount : '0',
          "Total": data.tax_saving ? data.total_amount + data.tax_saving.amount : data.total_amount,
          "Application Clients": data.customers,
          "Period": data.period,
        })
        return memo;
      },[])
      new_data.push({
        "CRM ID": "",
        "Agent": "",
        "Status": "",
        "Main Customer": "",
        "Member ID": "",
        "Company": "",
        "State": "",
        "Company Clients": vm.total.total_clients_company,
        "Amount": vm.total.total,
        "Overide": vm.total.overide,
        "Tax Saving": data.tax_saving ? data.tax_saving.amount : '0',
        "Total": data.tax_saving ? data.total_amount + data.tax_saving.amount : data.total_amount,
        "Application Clients": vm.total.total_clients_app,
        "Period": "",
      });
      var value = vm.companySelected ? vm.companySelected.name : vm.agentSelected.full_name;
      var close = vm.params.close ? 'Facturadas' : 'no_facturadas'
      var name = value+'_'+moment(vm.params.date_from).format('MM-DD')+'_'+moment(vm.params.date_to).format('MM-DD')+'_'+close;
      var a = document.createElement("a");
      var json_pre = angular.toJson(new_data);
      var fileName = name+".csv";
      
      var csv = Papa.unparse(json_pre);
 
      if (window.navigator.msSaveOrOpenBlob) {
        var blob = new Blob([decodeURIComponent(encodeURI(csv))], {
          type: "text/csv;charset=utf-8;"
        });
        navigator.msSaveBlob(blob, fileName);
      } else {
 
        a.href = 'data:attachment/csv;charset=utf-8,' + encodeURI(csv);
        a.target = '_blank';
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      }
    }

    vm.deleteAllInvoices = function(){
      console.log(vm.params.company_id)
      if(vm.params.company_id == undefined){
        swal('Info','You can only delete invoices per company','info')
        return
      }
      swal({
        title: 'Are you sure',
        text: 'you want to delete all payments?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.destroyAllInvoices({company_id: vm.params.company_id})
              .then(function (res) {
                vm.send = false;
                vm.payment = undefined;
                Swal('Exito', res.message, 'success');
              }, function (err) {
                Swal('Error', err.data.message, 'warning');
                vm.cancel({ $value: '' });
              })
          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    }


   
  }


})();
