(function () {
  'use strict';
  angular
  .module('app')
  .component('changeAgent', {
    templateUrl: 'app/components/modals/change_agent.html',
    controller: ChangeAgentsController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ChangeAgentsController.$inject = ['$rootScope','globalService','user'];

  function ChangeAgentsController($rootScope, $globalService, $user) {
    var vm = this;
    vm.save = save;
    vm.currentUser = $user.getCurrentUser();
    vm.loading = true;

    vm.$onInit = function() {
      vm.currentApplication = vm.resolve.currentApplication;
      $globalService.getAllAgents.query().$promise
      .then(function(res){
        vm.loading = false;
        vm.agents  = res;  
      })
    }


    function save(){
      vm.send = true;
      var data = {
        id: vm.currentApplication.id,
        user_id: vm.agentSelected.id,
      }
      $globalService.changeAgent(data)
      .then(function(res){
        vm.send = false;
        Swal('Exito',res.message,'success');
        vm.close({$value: res.data})
      }, function(err){
        Swal('Error',err.data.message,'warning');
        vm.dismiss({$value: ''});
      })
    }

    vm.cancel = function(){
      vm.dismiss({$value: ''})
    }

    $rootScope.$on('setCurrentApplicant', function(event, data){
      vm.currentApplication = data.current;
      // //console.log(vm.currentApplication.dependents)
    })

  }

})();
