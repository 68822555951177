(function () {
  'use strict';
  angular
    .module('app')
    .component('procesarApplication', {
      templateUrl: 'app/components/procesarApplication/procesarApplication.html',
      controller: ProcesarApplicationController,
    });

  ProcesarApplicationController.$inject = ['$stateParams', 'user', '_', 'globalService', '$rootScope','$uibModal'];

  function ProcesarApplicationController($stateParams, $user, _, $globalService, $rootScope, $uibModal) {
    var vm = this;
    vm.currentUser = $user.getCurrentUser();
    vm.requireDocuments = [];
    vm.loadingData = true;
    vm.listRequireDocuments = ['Income', 'Social Security Number', 'Status Migratory', 'Relocation', 'Lost Cover', 'Others'];

    vm.tabPanel = {
      personalData: true,
      insuranceData: false,
      billingInformation: false,
      requiredData: false,
    }
    vm.statuses = [
      { key: 0, value: "Enviada" },
      { key: 1, value: "En Proceso" },
      { key: 2, value: "Aprobada" },
      { key: 3, value: "Conflicto de Agentes" },
      { key: 4, value: "Activa" },
      { key: 5, value: "Baja" }
    ]

    vm.$onInit = function () {
      $rootScope.$emit('uploadBreadCrum', { current: 'Periods', before: 'Home' });

      $globalService.type_insurance_companies()
      .then(function(res){
        vm.typeInsuranceCompanies = res;

        $globalService.companies.query(function (res) {
          vm.insurances = res;
        })
        $globalService.get_states()
          .then(function (res) {
            vm.states = res;
          })
        $globalService.getAllAgents.query(function(res){
          vm.agents = res;
        })
        var params = {}
        params['id'] = $stateParams.crmid;
        params['user_npn'] = $stateParams.user_npn;
  
        $globalService.resApplications.get(params).$promise
          .then(function (res) {
            vm.currentApplication = res;
            vm.allCompanies = getAllCompanies();
  
            vm.companySelected = _.find(vm.allCompanies, { id: vm.currentApplication.insurance_data.company.id });
            vm.productSelected = _.find(vm.companySelected.products, { id: vm.currentApplication.insurance_data.company_product.id });
  
            vm.typePlanSelected = _.find(vm.productSelected.company_product_type, { id: vm.currentApplication.insurance_data.company_product_type.id });
            vm.planSelected = _.find(vm.typePlanSelected.company_product_plans, { id: vm.currentApplication.insurance_data.company_product_plan.id });
            vm.loadingData = false;
            $rootScope.$emit('setCurrentApplicant', {
              current: vm.currentApplication,
              insurances: vm.insurances,
              allCompanies: vm.allCompanies,
              companySelected: vm.companySelected,
              productSelected: vm.productSelected,
              typePlanSelected: vm.typePlanSelected,
              planSelected: vm.planSelected,
              applicationTypeSelected: vm.applicationTypeSelected,
            });
            if (vm.currentUser.permits_granted.change_agent.active) {
              geAllAgent();
            }
          }, function (err) {
            vm.loadingData = false;
            vm.showCurrentApplication = false;
            Swal('Error', err.data.message, 'error');
          })
      })
      
    }

    function getAllCompanies() {
      vm.allCompanies = _.reduce(vm.typeInsuranceCompanies, function (memo, data) {
        _.forEach(data.companies, function (company) {
          memo.push(company)
        })
        return memo;
      }, [])

      return vm.allCompanies;
    }
    vm.setActiveTab = function (tab) {
      _.forEach(Object.keys(vm.tabPanel), function (key) {
        if (vm.tabPanel[key]) {
          vm.tabPanel[key] = false;
        }
      })
      vm.tabPanel[tab] = true;
    }

    vm.addRequireDocuments = function () {

      if (vm.dateTo == undefined || moment(vm.dateTo) < moment() || vm.requireDocuments == undefined || vm.whoRequires == undefined) {
        Swal('Stop', 'Invalid date or incomplete data', 'info');
        return;
      } else {
        vm.currentApplication.required_documents = vm.currentApplication.required_documents || [];
        vm.currentApplication.required_documents.push({
          required_document: vm.requiredDocument == 'Others' ? vm.otherRequiredDocument : vm.requiredDocument,
          who_require_it: vm.whoRequires,
          date_to: moment(vm.dateTo).format('MM/DD/YYYY'),
          status: 0,
          pretty_status: 'Pendiente',
        })
        vm.requiredDocument = undefined;
        vm.whoRequires = undefined;
        vm.dateTo = undefined;
      }
    }

    vm.sendDocuments = function () {
      vm.loadingData = true;
      var data = {
        customer_application_id: vm.currentApplication.id,
        documents: _.reduce(vm.currentApplication.required_documents, function (memo, data) {
          if (data.id == undefined) {
            memo.push(data);
          }
          return memo;
        }, [])
      }

      swal({
        title: '¿Esta seguro?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.requiredDocuments(data)
              .then(function (res) {
                swal('Exito', res.message, 'success');
                vm.loadingData = false;
              }, function (err) {
                swal('Error', res.data.message, 'danger');
                vm.loadingData = false;
              })
          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    }

    vm.updateRequiredDoc = function (doc) {
      swal({
        title: '¿Esta seguro?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
            $globalService.updateDocument(doc)
              .then(function (res) {
                doc.status = 1;
                doc.pretty_status = 'Enviado';
                swal('Exito', res.message, 'success');
              }, function () {
                reject('Ha ocurrido un error');
              });
          });
        },
        allowOutsideClick: false,
      }).then(function (data) { });
    }

    vm.deleteRequiredDoc = function(doc, index){
      swal({
        title: '¿Esta seguro?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function() {
          return new Promise(function(resolve, reject) {
            $globalService.deleteDocument(doc)
            .then(function(res) {
              vm.currentApplication.required_documents.splice(index, 1)
              swal('Exito',res.message,'success');
            }, function() {
              reject('Ha ocurrido un error');
            });
          });
        },
        allowOutsideClick: false,
      }).then(function(data) {});
    }

    vm.processApplication = function(){
      if(vm.currentApplication.status == 2){
        Swal('Stop','Aplicacion ya procesada','info');
        return;
      }
      var modal = $uibModal.open({
        animation: true,
        component: 'processApplication',
        windowClass: 'show',
        backdrop: 'static',
        size: 'md',
        resolve: {
          currentApplication: function(){
            return vm.currentApplication;
          }
        }
      });

      modal.result.then(function(res){
        vm.$onInit();
        vm.showCurrentApplication = false;
        vm.currentApplication = undefined;
      }, function(err){
        swal('Error',err.data.message,'error');
      });
    }
  }
})();
