(function(){
  'use strict';
  angular
  .module('app')
  .component('reportAgentsDates', {
    templateUrl: 'app/components/report-agent-dates/reports-application_agents_dates.html',
    controller: ReportAgentDatesController
  });

  ReportAgentDatesController.$inject = ['user', '$rootScope', '$window', '_', 'globalService','$uibModal','url'];

  function ReportAgentDatesController($user, $rootScope, $window, _,$globalService, $uibModal, $url) {
    var vm = this;
    vm.loadingRequests = false;
    vm.insurancePeriods = [moment().format('YYYY'), moment().add(1, 'years').format('YYYY')];
    vm.showCurrentApplication = false;
    vm.loadingData = false;
    var date = new Date();
    vm.statuses = [
      {key: 0, value: "Sent"},
      {key: 1, value: "In progress"},
      {key: 2, value: "Approved"},
      {key: 3, value: "Agent Conflict"},
      {key: 4, value: "Active"},
      {key: 5, value: "Canceled"},
      {key: 6, value: "Pending Link"},
      {key: 7, value: "Pending Note"},
    ]
    
    
    vm.tabPanel = {
      personalData: true,
      insuranceData: false,
      billingInformation: false,
      requiredData: false,
    }

    vm.$onInit = function(){
      vm.currentUser = $user.getCurrentUser();
      vm.date_from = moment().startOf('month').format('YYYY-MM-DD')
      vm.date_to = moment().endOf('month').format('YYYY-MM-DD')
      vm.params = {token: vm.currentUser.token};
      vm.params.date_from = moment(vm.date_from).startOf('month').format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.date_to).endOf('month').format('YYYY-MM-DD');
      $globalService.users.query({use_npn: true}).$promise
      .then(function(res){
        vm.usersNpns = res;
      });
      if(vm.currentUser.user_type_id == 3){
        vm.agentSelected = vm.currentUser;
       vm.params.agent = vm.currentUser.id
       vm.consultApplicationsAll();
      }
      $rootScope.$emit('uploadBreadCrum', {current: 'Reports Applications', before: 'Home'});
      $globalService.getAllAgents.query().$promise.then(function(res){
        vm.agents = res;
      })
      $globalService.getAllAgents.query({only_agencies: true}).$promise.then(function(res){
        vm.agencies = res;
      })
      $globalService.get_states()
      .then(function(res) {
        vm.states = res;
      })
      $globalService.companies.query().$promise.then(function name(res) {
        vm.companies = res;  
      }, function(err) {
        vm.send = false;
      })
      vm.urlReport = $globalService.reportPdf('report_agents_applications_dates',vm.params);
      vm.urlReportExcel = $globalService.getUrlExcel('reports/report_agents_applications_dates',vm.params);
    }

    vm.consultApplicationsAll = function(){
      vm.loadingData = true;
      vm.params.date_from = moment(vm.date_from).format('YYYY-MM-DD');
      vm.params.date_to = moment(vm.date_to).format('YYYY-MM-DD');
      vm.params.agent = vm.agentsSelected ? _.pluck(vm.agentsSelected, 'id') : null
      vm.params.to_email = vm.to_email;
      $globalService.reportApplicationsAgentsDates(vm.params)
      .then(function(res){
        vm.loadingData = false;
        if(res.hasOwnProperty('to_email')){
          swal('Success', res.message, 'success');
        }else{
          vm.applications = _.map(res, function(data){
            data.pretty_efecty_date = moment(data.efecty_date,'MM-DD-YYYY').format("MMM/DD/YYYY");
            return data;
          });
          vm.calculate_total(res);
          vm.urlReport = $globalService.reportPdf('report_agents_applications_dates',vm.params);
          vm.urlReportExcel = $globalService.getUrlExcel('reports/report_agents_applications_dates',vm.params);
        }
      });
    }

    vm.calculate_total = function(res){
      vm.total = _.reduce(res, function(memo, data){
        memo.total_clients += data.clients_and_insureds.clients;
        memo.total_insureds += data.clients_and_insureds.insureds;
        return memo;
      },{total_clients: 0, total_insureds: 0});
    }

    vm.showUnbsubscribeInfo = function(application) {
      var modal = $uibModal.open({
        animation: true,
        component: 'unsubscribeInfo',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          currentApplication: function () {
            return application;
          }
        }
      });
    }

    vm.setAgents = function() {
      console.log(vm.agentsSelected)
    }

    vm.showReason = function(data){
      swal('info', data, 'info')
    }

    // column to sort
    vm.column = 'sno';

    // sort ordering (Ascending or Descending). Set true for desending
    vm.reverse = false;

    // called on header click
    vm.sortColumn = function (col) {
      vm.column = col;
      if (vm.reverse) {
        vm.reverse = false;
        vm.reverseclass = 'arrow-up';
      } else {
        vm.reverse = true;
        vm.reverseclass = 'arrow-down';
      }
    };

    // remove and change class
    vm.sortClass = function (col) {
      if (vm.column == col) {
        if (vm.reverse) {
          return 'fa-sort-amount-asc';
        } else {
          return 'fa-sort-amount-desc';
        }
      } else {
        return 'fa-sort';
      }
    }
  }


})();
