(function () {
  'use strict';
  angular
  .module('app')
  .component('conflictAgents', {
    templateUrl: 'app/components/modals/conflict_agents.html',
    controller: ConflictAgentsController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ConflictAgentsController.$inject = ['$rootScope','globalService'];

  function ConflictAgentsController($rootScope, $globalService) {
    var vm = this;
    vm.save = save;

    vm.$onInit = function() {
      vm.currentApplication = vm.resolve.currentApplication;
    }


    function save(){
      vm.send = true;
      var data = {
        id: vm.currentApplication.id,
        conflict: true,
        agent_conflict: vm.description_conflict
      }
      $globalService.setConlfictAgent(data)
      .then(function(res){
        vm.send = false;
        Swal('Exito',res.message,'success');
        vm.close({$value: res.data})
      }, function(err){
        Swal('Error',err.data.message,'warning');
        vm.dismiss({$value: ''});
      })
    }

    vm.cancel = function(){
      vm.dismiss({$value: ''})
    }

    $rootScope.$on('setCurrentApplicant', function(event, data){
      vm.currentApplication = data.current;
      // //console.log(vm.currentApplication.dependents)
    })

  }

})();
