(function(){
  'use strict';
  angular
  .module('app')
  .component('reportCotizacion', {
    templateUrl: 'app/components/report-cotizacion/report-cotizacion.html',
    controller: ReportContizacionController
  });

  ReportContizacionController.$inject = ['user', '$rootScope', '$window', '_', 'globalService','$uibModal','url'];

  function ReportContizacionController($user, $rootScope, $window, _,$globalService, $uibModal, $url) {
    var vm = this;
    vm.loadingRequests = false;
    
    vm.showCurrentApplication = false;
    vm.loadingData = false;
    var date = new Date();
    vm.statuses = [
      {key: 0, value: "Enviada"},
      {key: 1, value: "En Proceso"},
      {key: 2, value: "Aprobada"},
      {key: 3, value: "Conflicto de Agentes"},
      {key: 4, value: "Activa"},
      {key: 5, value: "Baja"}
    ]
    
    
    vm.tabPanel = {
      personalData: true,
      insuranceData: false,
      billingInformation: false,
      requiredData: false,
    }

    vm.$onInit = function(){
      vm.currentUser = $user.getCurrentUser();
      vm.params = { token: vm.currentUser.token };
      $rootScope.$emit('uploadBreadCrum', {current: 'Reports Applications', before: 'Home'});
      $globalService.getAllAgents.query(function(res){
        vm.agents = res;
      })
      $globalService.get_states()
      .then(function(res) {
        vm.states = res;
      })
      $globalService.companies.query().$promise.then(function(res) {
        vm.companies = res;  
      }, function(err) {
        vm.send = false;
      })
      vm.urlReport = $globalService.reportPdf('quote_reports',vm.params);
      vm.urlReportCsv = $globalService.reportExcel('quote_reports',vm.params);
    }

    vm.consultApplicationsAll = function(){
      vm.loadingData = true;
      $globalService.quoteReport(vm.params)
      .then(function(res){
        vm.loadingData = false;
        vm.applications = _.map(res, function(data){
          data.pretty_efecty_date = moment(data.efecty_date,'MM-DD-YYYY').format("MMM/DD/YYYY");
          return data;
        });
        vm.calculate_total(res);
        vm.urlReport = $globalService.reportPdf('quote_reports',vm.params);
        vm.urlReportCsv = $globalService.reportExcel('quote_reports',vm.params);
      });
    }

    vm.calculate_total = function(res){
      vm.total = _.reduce(res, function(memo, data){
        memo.total_clients += data.clients_and_insureds.clients;
        memo.total_insureds += data.clients_and_insureds.insureds;
        return memo;
      },{total_clients: 0, total_insureds: 0});
    }

    // column to sort
    vm.column = 'sno';

    // sort ordering (Ascending or Descending). Set true for desending
    vm.reverse = false;

    // called on header click
    vm.sortColumn = function (col) {
      vm.column = col;
      if (vm.reverse) {
        vm.reverse = false;
        vm.reverseclass = 'arrow-up';
      } else {
        vm.reverse = true;
        vm.reverseclass = 'arrow-down';
      }
    };

    // remove and change class
    vm.sortClass = function (col) {
      if (vm.column == col) {
        if (vm.reverse) {
          return 'fa-sort-amount-asc';
        } else {
          return 'fa-sort-amount-desc';
        }
      } else {
        return 'fa-sort';
      }
    }
  }


})();
