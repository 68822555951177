(function () {
  'use strict';
  angular
  .module('app')
  .component('modalBIllInformation', {
    templateUrl: 'app/components/modals/modal_bill_information.html',
    controller: ModalBillInformationController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  ModalBillInformationController.$inject = ['$rootScope','globalService','user'];

  function ModalBillInformationController($rootScope, $globalService, $user) {
    var vm = this;
    vm.save = save;
    vm.currentUser = $user.getCurrentUser();
    vm.loading = true;

    vm.$onInit = function() {
      $globalService.resApplications.get({id: vm.resolve.currentApplication.id}).$promise
      .then(function(res){
        vm.currentApplication = res;
        if(vm.currentApplication.billing_information){
          vm.setTypeOfCard(vm.currentApplication.billing_information.card_number)
        }

        vm.loading = false;
      });
    }

    $globalService.get_states()
    .then(function (res) {
      vm.states = res;
    })


    function save(){
      vm.send = true;
      $globalService.updateBillInformation(vm.currentApplication)
      .then(function(res){
        vm.send = false;
        Swal('Exito',res.message,'success');
        vm.close({$value: res.data})
      }, function(err){
        Swal('Error',err.data.message,'warning');
        vm.dismiss({$value: ''});
      })
    }

    vm.setTypeOfCard = function(data) {
      var card_icon = validatioRejec(data);
      if (card_icon) {
        vm.card_type_icon = card_icon;
      }
    }

    function validatioRejec(number) {
      var re = {
        'electron': /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
        'maestro': /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
        'dankort': /^(5019)\d+$/,
        'interpayment': /^(636)\d+$/,
        'unionpay': /^(62|88)\d+$/,
        'fa-cc-visa': /^4[0-9]{12}(?:[0-9]{3})?$/,
        'fa-cc-mastercard': /^5[1-5][0-9]{14}$/,
        'fa-cc-amex': /^3[47][0-9]{13}$/,
        'fa-cc-diners-club': /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
        'fa-cc-discover': /^6(?:011|5[0-9]{2})[0-9]{12}$/,
        'fa-cc-jcb': /^(?:2131|1800|35\d{3})\d{11}$/
      }

      for (var key in re) {
        if (re[key].test(number)) {
          return key
        }
      }
    }

    vm.validateDate = function(){
      if(moment(vm.currentApplication.billing_information.expiration_card,'MM/YYYY').diff(moment()) <= 0){
        swal('warning','The expiration date of the card is less than the current date','warning');
        vm.currentApplication.billing_information.expiration_card = '';
      }
    }

    vm.cancel = function(){
      vm.dismiss({$value: ''})
    }
  }

})();
